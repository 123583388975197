import React, { useState } from 'react'
import convrt_video_thumbnail from 'common/assets/png/convrt_video_thumbnail.png'
import { Modal } from 'antd'
import { trackEvent } from 'eventConfig'
import { useLocalStorage } from 'usehooks-ts'

interface VideoDemoModalProps {
  open: boolean
  onClose: () => void
  isMobile?: boolean
}

const VideoDemoModal: React.FC<VideoDemoModalProps> = ({ open, onClose, isMobile }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [user] = useLocalStorage<any>('user', null)
  const whereType = user?.subscription_type === 'REGISTERED' ? 'Pricing outside' : 'Pricing inside'

  const handleThumbnailClick = () => {
    setIsPlaying(true)
    trackEvent('PLAY_DEMO_CLICKED', {
      where: whereType,
      user_attributes: {
        subscription_type: user?.subscription_type,
        force_pricing: user?.force_pricing,
        plan_type: user?.plan_type,
        plan: user?.plan,
        settings_id: user?.settings_id,
      },
    })
  }

  const handleClose = () => {
    setIsPlaying(false)
    onClose()
  }

  return (
    <Modal
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      open={open}
      onCancel={handleClose}
      footer={null}
      width={isMobile ? '100%' : '75%'}
      centered
      closable={false}>
      <div className='video-container' style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
        {!isPlaying ? (
          <div
            onClick={handleThumbnailClick}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <img
              src={convrt_video_thumbnail}
              alt='Video thumbnail'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
          </div>
        ) : (
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: '8px',
            }}
            src='https://www.youtube.com/embed/TUS1c7AuP0k?autoplay=1'
            title='Product Demo'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        )}
      </div>
    </Modal>
  )
}

export default VideoDemoModal
