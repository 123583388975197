const workerFunction = function () {
  const startCase = (str: string) => {
    // Replace any non-alphanumeric character with a space
    // then capitalize the first letter of each word
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase
      .replace(/[^a-zA-Z0-9]+/g, ' ') // Replace non-alphanumeric characters with spaces
      .trim() // Remove any leading or trailing whitespace
      .split(' ') // Split the string into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      .join(' ') // Join the words back into a string
  }

  const groupDialogs = (dialogs: any[], groupBy: string, dialogs_metadata: any, all_tags_dialog_data: any) => {
    if (!groupBy || groupBy === 'none') return { _: dialogs }

    const unpinned = dialogs?.filter((dialog: any) => !dialogs_metadata?.[dialog?.id]?.pin) || []
    const pinned = dialogs?.filter((dialog: any) => dialogs_metadata?.[dialog?.id]?.pin) || []

    // Nhóm theo tags
    if (groupBy === 'tags') {
      const enrichedDialogs = dialogs.map((dialog: any) => {
        const allTagOfDialog = all_tags_dialog_data?.find((tagData: any) => tagData.dialog_id === dialog.id)?.tags

        const customTags = allTagOfDialog ? allTagOfDialog?.map((tag: any) => tag.tag_name) : []
        return {
          ...dialog,
          customTags,
        }
      })

      let groupedByTags: any = {}
      groupedByTags = enrichedDialogs?.reduce((acc: any, dialog: any) => {
        dialog?.customTags?.forEach((tag: any) => {
          if (!acc[tag]) {
            acc[tag] = []
          }
          acc[tag].push(dialog)
        })
        return acc
      }, {})

      if (pinned?.length > 0) {
        groupedByTags.pinnedArr = pinned
      }
      return groupedByTags
    }

    if (groupBy === 'categories') {
      let groupedByCategories: any = {}
      let otherGroup: any = []

      dialogs.forEach((dialog: any) => {
        const uniqueCategories = Array.from(new Set(dialog.categories?.map((category: any) => category.category_name))) // Remove duplicate category names

        if (uniqueCategories.length > 0) {
          uniqueCategories.forEach((categoryName: any) => {
            if (!groupedByCategories[categoryName]) {
              groupedByCategories[categoryName] = []
            }
            // Prevent duplicates when adding to the category array
            if (!groupedByCategories[categoryName].some((existing: any) => existing.id === dialog.id)) {
              groupedByCategories[categoryName].push(dialog)
            }
          })
        } else {
          otherGroup.push(dialog)
        }
      })

      if (otherGroup.length > 0) {
        groupedByCategories['Other'] = otherGroup
      }

      if (pinned?.length > 0) {
        groupedByCategories.pinnedArr = pinned
      }
      return groupedByCategories
    }

    const grouped = unpinned.reduce((acc: any, dialog: any) => {
      let key = dialog[groupBy] || 'Other'
      if (groupBy === 'meeting_book') key = startCase(key)

      switch (key) {
        case 'high':
          key = 'High'
          break
        case 'medium':
          key = 'Medium'
          break
        case 'low':
          key = 'Low'
          break
        default:
          break
      }

      acc[key] = acc[key] || []
      acc[key].push(dialog)
      return acc
    }, {})

    if (pinned?.length > 0) {
      grouped.pinnedArr = pinned
    }

    return grouped
  }

  onmessage = (event: MessageEvent) => {
    const { dialogs, groupBy, dialogs_metadata, all_tags_dialog_data } = event.data

    if (!dialogs || !groupBy) {
      postMessage({ error: 'Invalid input data' })
      return
    }

    try {
      const groupedDialogs = groupDialogs(dialogs, groupBy, dialogs_metadata, all_tags_dialog_data)
      console.log('Worker grouped dialogs')
      postMessage({ data: groupedDialogs })
    } catch (error) {
      postMessage({ error: 'Error grouping dialogs' })
    }
  }
}

let codeToString = workerFunction.toString()
let mainCode = codeToString.substring(codeToString.indexOf('{') + 1, codeToString.lastIndexOf('}'))
let blob = new Blob([mainCode], { type: 'application/javascript' })
let group_worker_script = URL.createObjectURL(blob)

export default group_worker_script
