import { Button, Form, Input, message } from 'antd'
import styles from './GetToKnow.module.scss'
import { useLocalStorage } from 'usehooks-ts'
import back from 'common/assets/svg/Back.svg'
import Where from './UI/Where'
import CompanyDomain from './UI/CompanyDomain'
import Goal from './UI/Goal'
import {
  useLazyUpdateOutsideLogQuery,
  useLazyUpdateUserQuery,
} from 'features/sign-up/AffiliateSignup/state/api/SignUpApi'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { useNavigate } from 'react-router-dom'
import { SET_JUST_SIGNED_UP } from 'GeneralSlice'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { trackEvent } from 'eventConfig'
import nervous from 'common/assets/svg/EmotionatedEmoji.svg'

interface GetToKnowProps {
  innerStep: number
  order: 1 | 2 | 3 | 4
  totalSteps: number
  allowSkip: boolean
  screen: 'company_domain' | 'main_goal' | 'where_did_you_find_us' | 'company_details'
}

const GetToKnow: React.FC<GetToKnowProps> = ({ innerStep, order, totalSteps, allowSkip, screen }) => {
  const [, setStep] = useLocalStorage('step', 1)
  const [updateUser] = useLazyUpdateUserQuery()
  const [user, setUser]: any = useLocalStorage('user', null)
  const outside_onboarding = user?.outside_onboarding
  const parent_link_id = outside_onboarding?.parent_link_id
  const parent_link_name = outside_onboarding?.parent_link_name
  const child_link_name = outside_onboarding?.child_link_name
  const child_link_id = outside_onboarding?.linkId
  const settings_id = user?.settings_id
  const [buttonLoading, setButtonLoading] = useState(false)
  const { affiliateData } = useAppSelector((state) => state.signup)
  const isGetToKnow = !outside_onboarding?.settings?.skip_outside_onboarding
  const [updateOutsideLog] = useLazyUpdateOutsideLogQuery()
  const [where, setWhere] = useState('')
  const [whatSearch, setWhatSearch] = useState('')
  const [companyDomain, setCompanyDomain] = useState('')
  const [goal, setGoal] = useState('')
  const { isMobile } = useAppSelector((state) => state.General)
  const [getUserDetails] = useLazyGetUserDetailsQuery()
  const [oopsWeb3, setOopsWeb3] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isGoogle, setIsGoogle] = useState(false)
  const [isBack, setIsBack] = useState(false)

  const handleOutsideLog = async () => {
    switch (screen) {
      case 'company_details':
        return await updateOutsideLog({ toUpdate: screen, value: companyDomain, userID: user.id })
      case 'main_goal':
        return await updateOutsideLog({ toUpdate: screen, value: goal, userID: user.id })
      case 'where_did_you_find_us':
        return await updateOutsideLog({ toUpdate: screen, value: where, userID: user.id })
      // case 'company_domain':
      //   return await updateOutsideLog({ toUpdate: screen, value: companyName, userID: user.id })
      default:
        break
    }
  }

  const continueStepCompanyNameAndUrl = async (values: any) => {
    setButtonLoading(true)
    await updateUser({
      updates: {
        company: values.companyName,
        company_domain: values.companyDomain,
        outside_onboarding: { ...outside_onboarding, step: order + 2 },
      },
      id: user.id,
    })

    setButtonLoading(false)

    const currentUrl = new URL(window.location.href)
    currentUrl.searchParams.set('signup_step2', 'true')
    window.history.replaceState({}, document.title, currentUrl.toString())

    handleOutsideLog()
    // GA LOG
    trackEvent(
      `SIGNUP_GET_TO_KNOW_${order}`,
      {
        affiliateData: affiliateData,
        user: user.email,
        details: user,
        company: values.companyName,
        company_domain: values.companyDomain,
        settings_id,
        parent_link_id,
        parent_link_name,
        child_link_id,
        child_link_name,
        screen,
        allowSkip,
        is_skipped: false,
      },
      true,
    )

    if (order + 1 > totalSteps) {
      return finish()
    }
    isGetToKnow ? setStep(order + 2) : finish()
  }

  const continueStepWhereDidYouFindUs = async () => {
    if (!where) {
      message.error('Please select where did you find us')
      return
    }

    handleOutsideLog()

    if (whatSearch) {
      await updateOutsideLog({ toUpdate: 'what_search', value: whatSearch, userID: user.id })
    }

    // GA LOG
    trackEvent(
      `SIGNUP_GET_TO_KNOW_${order}`,
      {
        affiliateData: affiliateData,
        user: user.email,
        details: user,
        where_did_you_find_us: where,
        whatSearch: whatSearch,
        settings_id,
        parent_link_id,
        parent_link_name,
        child_link_id,
        child_link_name,
        screen,
        allowSkip,
        is_skipped: false,
      },
      true,
    )

    if (order + 1 > totalSteps) {
      return finish()
    }
    setStep(order + 2)
    await updateUser({
      updates: {
        outside_onboarding: { ...outside_onboarding, step: order + 2 },
      },
      id: user.id,
    })
  }

  const continueStepCompanyDomain = async () => {
    if (!companyDomain) {
      message.error('Please select your company domain')
      return
    }

    handleOutsideLog()

    // GA LOG
    trackEvent(
      `SIGNUP_GET_TO_KNOW_${order}`,
      {
        affiliateData: affiliateData,
        user: user.email,
        details: user,
        company_domain: companyDomain,
        settings_id,
        parent_link_id,
        parent_link_name,
        child_link_id,
        child_link_name,
        screen,
        allowSkip,
        is_skipped: false,
      },
      true,
    )

    if (companyDomain === 'web3') {
      setOopsWeb3(true)
      return
    }

    if (order + 1 > totalSteps) {
      return finish()
    }

    setStep(order + 2)
    await updateUser({
      updates: {
        outside_onboarding: { ...outside_onboarding, step: order + 2 },
      },
      id: user.id,
    })
  }

  const continueStepMainGoal = async () => {
    if (!goal) {
      message.error('Please select your goal')
      return
    }

    handleOutsideLog()

    // GA LOG
    trackEvent(
      `SIGNUP_GET_TO_KNOW_${order}`,
      {
        affiliateData: affiliateData,
        user: user.email,
        details: user,
        goal: goal,
        settings_id,
        parent_link_id,
        parent_link_name,
        child_link_id,
        child_link_name,
        screen,
        allowSkip,
        is_skipped: false,
      },
      true,
    )

    if (order + 1 > totalSteps) {
      return finish()
    }

    setStep(order + 2)
    await updateUser({
      updates: {
        outside_onboarding: { ...outside_onboarding, step: order + 2 },
      },
      id: user.id,
    })
  }

  const skipStep = async (newStep: number) => {
    if (order === 1 || order === 2 || order === 3 || order === 4) {
      // GA LOG
      trackEvent(
        `SIGNUP_GET_TO_KNOW_${order}`,
        {
          affiliateData: affiliateData,
          user: user.email,
          details: user,
          goal: goal,
          settings_id,
          parent_link_id,
          parent_link_name,
          child_link_id,
          child_link_name,
          screen,
          allowSkip,
          is_skipped: true,
        },
        true,
      )
    }

    if (order + 1 > totalSteps) {
      return finish()
    }

    setStep(newStep)

    await updateUser({
      updates: {
        outside_onboarding: { ...outside_onboarding, step: newStep },
      },
      id: user.id,
    })
  }

  const handleBack = async (step: any) => {
    setIsBack(true)
    setStep(step)
    await updateUser({
      updates: {
        outside_onboarding: { ...outside_onboarding, step: step },
      },
      id: user.id,
    })
  }

  const getUser = async () => {
    const { data } = await getUserDetails()
    if (data.id) {
      setUser(data)
    }
  }

  const finish = async () => {
    await updateUser({
      updates: {
        outside_onboarding: null,
      },
      id: user.id,
    })

    getUser()
    // GA LOG
    trackEvent(
      'SIGNUP_COMPLETION',
      {
        affiliateData,
        user: user.email,
        details: user,
        settings_id,
        parent_link_id,
        parent_link_name,
        child_link_id,
        child_link_name,
      },
      true,
    )
    dispatch(SET_JUST_SIGNED_UP(true))
    navigate('/upgrade?pricing-first-time=true')
  }
  useEffect(() => {
    if (order > 0) {
      const delay = setTimeout(() => {
        switch (order) {
          case 1:
            // GA LOG
            trackEvent(
              'ARRIVE_SIGNUP_GET_TO_KNOW_1',
              {
                settings_id,
                parent_link_id,
                parent_link_name,
                child_link_id,
                child_link_name,
                screen,
                allowSkip,
                ...(isBack ? { is_back: true } : {}),
              },
              true,
            )
            break
          case 2:
            // GA LOG
            trackEvent(
              'ARRIVE_SIGNUP_GET_TO_KNOW_2',
              {
                settings_id,
                parent_link_id,
                parent_link_name,
                child_link_id,
                child_link_name,
                screen,
                allowSkip,
                ...(isBack ? { is_back: true } : {}),
              },
              true,
            )
            break
          case 3:
            // GA LOG
            trackEvent(
              'ARRIVE_SIGNUP_GET_TO_KNOW_3',
              {
                settings_id,
                parent_link_id,
                parent_link_name,
                child_link_id,
                child_link_name,
                screen,
                allowSkip,
                ...(isBack ? { is_back: true } : {}),
              },
              true,
            )
            break
          case 4:
            // GA LOG
            trackEvent(
              'ARRIVE_SIGNUP_GET_TO_KNOW_4',
              {
                settings_id,
                parent_link_id,
                parent_link_name,
                child_link_id,
                child_link_name,
                screen,
                allowSkip,
                ...(isBack ? { is_back: true } : {}),
              },
              true,
            )
            break
          default:
            break
        }
        setIsBack(false)
      }, 500)

      return () => clearTimeout(delay)
    }
  }, [order])

  return (
    <>
      <div
        className={
          isMobile
            ? innerStep === 1 || innerStep === 2 || innerStep === 3 || innerStep === 4
              ? styles.cardMobile
              : styles.card
            : styles.card
        }>
        <div className={styles.content}>
          {isGetToKnow && (
            <div className={styles.step}>
              Step {order} of {totalSteps}
            </div>
          )}
          {oopsWeb3 && (
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <img src={nervous} alt='nervous' style={{ width: 60, height: 60 }}></img>
              </div>
            </>
          )}
          <div className={styles.lets}>
            {oopsWeb3 ? `Oops! you've reached our Web2 product!` : `Let's get to know you`}
          </div>
          <>
            {innerStep === 1 && (
              <>
                <Form onFinish={continueStepCompanyNameAndUrl} style={{ width: '100%', height: '100%' }}>
                  <div className={styles.inputs}>
                    <div className={styles.input_frame}>
                      <span className={styles.input_text}>Company name</span>
                      <div className={styles.input}>
                        <Form.Item
                          name='companyName'
                          style={{ padding: '0px', margin: '0px', width: '100%', fontSize: 18 }}
                          rules={[{ required: true, message: 'Company Name is required', whitespace: true }]}>
                          <Input
                            placeholder='Company name'
                            style={{ fontSize: 18 }}
                            inputMode='text'
                            required
                            tabIndex={1}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={styles.input_frame}>
                      <span className={styles.input_text}>Company Domain</span>
                      <div className={styles.input}>
                        <Form.Item
                          name='companyDomain'
                          style={{ padding: '0px', margin: '0px', width: '100%', zIndex: 100, fontSize: 18 }}
                          rules={[
                            {
                              required: true,
                              message: 'Company Domain is required',
                              whitespace: true,
                            },
                            // () => ({
                            //   validator(_, value) {
                            //     const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
                            //     if (!value || domainRegex.test(value)) {
                            //       return Promise.resolve()
                            //     }
                            //     return Promise.reject(new Error('Please enter a valid domain.'))
                            //   },
                            // }),
                          ]}>
                          <Input
                            className='websiteInputSignup'
                            addonBefore={
                              <div className={styles.addon}>
                                <div className={styles.text} style={{ fontSize: 18 }}>
                                  http://
                                </div>
                              </div>
                            }
                            styles={{
                              input: {
                                fontSize: 18,
                              },
                              prefix: {
                                fontSize: 18,
                              },
                            }}
                            style={{ fontSize: 18 }}
                            placeholder='company.com'
                            required
                            tabIndex={2}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div
                    // className={styles.button}
                    // Added this style to "fix" the card height on smaller screens
                    style={{ padding: '32px 0px 100px 0px' }}>
                    <Form.Item style={{}}>
                      <div className={styles.button_back_special}>
                        {order !== 1 && (
                          <Button
                            type='ghost'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingLeft: '2px',
                              marginTop: '2px',
                            }}
                            onClick={() => handleBack(order)}>
                            <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                            <span className={styles.back_text}>Back</span>
                          </Button>
                        )}
                        <Button
                          type='primary'
                          htmlType='submit'
                          style={order === 1 ? { marginTop: 70 } : {}}
                          loading={buttonLoading}>
                          Continue
                        </Button>
                      </div>
                      {allowSkip && (
                        <div className={styles.skip_frame_3}>
                          <div className={styles.skip} onClick={() => skipStep(order + 2)}>
                            Skip
                          </div>
                        </div>
                      )}
                    </Form.Item>
                  </div>
                </Form>
              </>
            )}
            {innerStep === 2 && (
              <>
                {isMobile ? (
                  <>
                    <Where
                      where={where}
                      setWhere={setWhere}
                      whatSearch={whatSearch}
                      setWhatSearch={setWhatSearch}
                      isGoogle={isGoogle}
                      setIsGoogle={setIsGoogle}
                    />
                    <div className={styles.button_back}>
                      {order !== 1 && (
                        <Button
                          type='ghost'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingLeft: '2px',
                            marginTop: '2px',
                          }}
                          onClick={() => (isGoogle ? setIsGoogle(false) : handleBack(order))}>
                          <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                          <span className={styles.back_text}>Back</span>
                        </Button>
                      )}
                      <Button type='primary' onClick={continueStepWhereDidYouFindUs}>
                        Continue
                      </Button>
                    </div>
                    {allowSkip && (
                      <div className={styles.skip_frame_mobile}>
                        <div className={styles.skip} onClick={() => skipStep(order + 2)}>
                          Skip
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Where
                      where={where}
                      setWhere={setWhere}
                      whatSearch={whatSearch}
                      setWhatSearch={setWhatSearch}
                      isGoogle={isGoogle}
                      setIsGoogle={setIsGoogle}
                    />
                    <div className={styles.button_back}>
                      {order !== 1 && (
                        <Button
                          type='ghost'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingLeft: '2px',
                            marginTop: '2px',
                          }}
                          onClick={() => (isGoogle ? setIsGoogle(false) : handleBack(order))}>
                          <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                          <span className={styles.back_text}>Back</span>
                        </Button>
                      )}
                      <Button type='primary' onClick={continueStepWhereDidYouFindUs}>
                        Continue
                      </Button>
                    </div>
                    {allowSkip && (
                      <div className={styles.skip_frame}>
                        <div className={styles.skip} onClick={() => skipStep(order + 2)}>
                          Skip
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {innerStep === 3 && (
              <>
                {isMobile ? (
                  <>
                    <CompanyDomain domain={companyDomain} setDomain={setCompanyDomain} oopsWeb3={oopsWeb3} />
                    <div className={styles.button_back}>
                      {oopsWeb3 ? (
                        <>
                          {order !== 1 && (
                            <Button
                              type='ghost'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingLeft: '2px',
                                marginTop: '2px',
                              }}
                              onClick={() => setOopsWeb3(false)}>
                              <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                              <span className={styles.back_text}>Back</span>
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          <Button
                            type='ghost'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingLeft: '2px',
                              marginTop: '2px',
                            }}
                            onClick={() => handleBack(order)}>
                            <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                            <span className={styles.back_text}>Back</span>
                          </Button>
                          <Button type='primary' onClick={continueStepCompanyDomain}>
                            Continue
                          </Button>
                        </>
                      )}
                    </div>
                    <div className={styles.skip_frame_mobile}>
                      {!oopsWeb3 && allowSkip && (
                        <div className={styles.skip} onClick={() => skipStep(order + 2)}>
                          Skip
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <CompanyDomain domain={companyDomain} setDomain={setCompanyDomain} oopsWeb3={oopsWeb3} />

                    <div className={styles.button_back}>
                      {oopsWeb3 ? (
                        <>
                          {order !== 1 && (
                            <Button
                              type='ghost'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingLeft: '2px',
                                marginTop: '2px',
                              }}
                              onClick={() => setOopsWeb3(false)}>
                              <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                              <span className={styles.back_text}>Back</span>
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {order !== 1 && (
                            <Button
                              type='ghost'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingLeft: '2px',
                                marginTop: '2px',
                              }}
                              onClick={() => handleBack(order)}>
                              <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                              <span className={styles.back_text}>Back</span>
                            </Button>
                          )}
                          <Button type='primary' onClick={continueStepCompanyDomain}>
                            Continue
                          </Button>
                        </>
                      )}
                    </div>
                    <div className={styles.skip_frame_3}>
                      {!oopsWeb3 && allowSkip && (
                        <div className={styles.skip} onClick={() => skipStep(order + 2)}>
                          Skip
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {innerStep === 4 && (
              <>
                {isMobile ? (
                  <>
                    <Goal goal={goal} setGoal={setGoal} />
                    <div className={styles.button_back}>
                      {order !== 1 && (
                        <Button
                          type='ghost'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingLeft: '2px',
                            marginTop: '2px',
                          }}
                          onClick={() => handleBack(order)}>
                          <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                          <span className={styles.back_text}>Back</span>
                        </Button>
                      )}
                      <Button type='primary' onClick={continueStepMainGoal}>
                        Continue
                      </Button>
                    </div>
                    {allowSkip && (
                      <div className={styles.skip_frame_mobile}>
                        <div className={styles.skip} onClick={() => skipStep(order + 2)}>
                          Skip
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Goal goal={goal} setGoal={setGoal} />
                    <div className={styles.button_back}>
                      {order !== 1 && (
                        <Button
                          type='ghost'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingLeft: '2px',
                            marginTop: '2px',
                          }}
                          onClick={() => handleBack(order)}>
                          <img src={back} style={{ marginBottom: '2.1px', fontWeight: '600' }} alt='Back' />
                          <span className={styles.back_text}>Back</span>
                        </Button>
                      )}
                      <Button type='primary' onClick={continueStepMainGoal}>
                        Continue
                      </Button>
                    </div>
                    {allowSkip && (
                      <div className={styles.skip_frame_3}>
                        <div className={styles.skip} onClick={() => skipStep(order + 2)}>
                          Skip
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </>
  )
}

export default GetToKnow
