import { message } from 'antd'
import { isArray, lowerCase } from 'lodash'
import React, { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import {
  useLazyAttachTagToDialogQuery,
  useLazyCreateTagQuery,
  useLazyGetAllTagByUserIdQuery,
  useLazyGetTagMultiDialogQuery,
  useLazyRemoveTagFromDialogQuery,
  useLazyRemoveTagQuery,
  useLazyUpdateTagQuery,
} from '../../state/api/Chat-API'
import { SET_ALL_TAG_DIALOG, SET_TAG_DIALOG } from '../../state/chatSlice'

export const useTagDialog = (dialogId: any) => {
  const dispatch = useAppDispatch()
  const { current_tags_dialog, is_unify_finished, dialogsData, all_tags_dialog_data } = useAppSelector(
    (state) => state.chat,
  )

  const [loading, setLoading] = React.useState(false)
  const [deleteSuccess, setDeleteSuccess] = React.useState(false)
  const [userTag, setUserTag] = React.useState<any>()

  const filteredTagsDialog = current_tags_dialog?.find((record: any) => record.id === dialogId)

  const [getAllTagByUserId] = useLazyGetAllTagByUserIdQuery()
  const [attachTagToDialog] = useLazyAttachTagToDialogQuery()
  const [removeTagFromDialog] = useLazyRemoveTagFromDialogQuery()
  const [createTag] = useLazyCreateTagQuery()
  const [updateTag] = useLazyUpdateTagQuery()
  const [removeTag] = useLazyRemoveTagQuery()
  const [getTagMultiDialog] = useLazyGetTagMultiDialogQuery()

  const dialogIds = useMemo(() => {
    return Object.values(dialogsData)
      .flatMap((platform: any) => platform?.dialogData || [])
      .map((dialog) => dialog.id)
  }, [dialogsData])

  const fetchTagAllDialog = async (forceRefetch: boolean) => {
    if (all_tags_dialog_data?.length === 0 || forceRefetch) {
      if (dialogsData && dialogIds.length) {
        const response = await getTagMultiDialog(dialogIds)
        if (response?.data?.response) {
          const data = response.data.response
          if (Array.isArray(data)) {
            dispatch(SET_ALL_TAG_DIALOG(data))

            data.forEach((item: any) => {
              const tagList =
                Array.isArray(item.tags) && item.tags.length
                  ? item.tags.map((tag: any) => ({
                      ...tag,
                      dialog_id: item.dialog_id,
                    }))
                  : []

              dispatch(SET_TAG_DIALOG({ id: item.dialog_id, data: tagList }))
            })
          } else {
            dispatch(SET_ALL_TAG_DIALOG([]))
          }
        }
      }
    }
  }

  const fetchTags = async () => {
    setLoading(true)
    try {
      const res = await getTagMultiDialog([dialogId])
      const data = res?.data?.response

      if (Array.isArray(data) && data.length > 0) {
        const tagList =
          data[0]?.tags?.map((item: any) => ({
            ...item,
            dialog_id: dialogId,
          })) || []

        dispatch(SET_TAG_DIALOG({ id: dialogId, data: tagList }))
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchUserTag = async () => {
    try {
      const res = await getAllTagByUserId()
      if (res) {
        setUserTag(res.data.response)
      }
    } catch (error) {
      console.error(`Error in fetchUserTag: ${error}`)
    }
  }

  const attachTagToDialogAsync = async (tag: any) => {
    await attachTagToDialog({
      tagId: tag.id,
      dialogId: dialogId,
    })
    message.success({ content: `Attached tag ${tag.tag_name} successfully`, duration: 1 })
    await fetchTags()
    setLoading(false)
    await fetchTagAllDialog(true)
  }

  const handleAddTagToDialog = async (tagName: string) => {
    const body = { tagName }

    try {
      setLoading(true)

      const isAttachedTag = filteredTagsDialog?.data.find((tag: any) => lowerCase(tag.tag_name) === lowerCase(tagName))

      if (isAttachedTag) {
        message.error({ content: 'Tag is already attached', duration: 1 })
        setLoading(false)
        return
      }

      const tagIfExist = isArray(userTag) && userTag.find((tag) => lowerCase(tag.tag_name) === lowerCase(tagName))
      if (tagIfExist) {
        await attachTagToDialogAsync(tagIfExist)
      } else {
        const dataCreateTab = await createTag(body)
        if (dataCreateTab) {
          const tagId = dataCreateTab.data.response.id
          await attachTagToDialogAsync({
            id: tagId,
            tag_name: tagName,
          })
        }
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleRemoveTagFromDialog = async (tagId: string) => {
    setLoading(true)
    await removeTagFromDialog({
      tagId,
      dialogId,
    })
    await fetchTags()
    await fetchTagAllDialog(true)
    setLoading(false)
  }

  const handleUpdateTag = async (body: any) => {
    try {
      setLoading(true)
      setDeleteSuccess(false)
      await updateTag(body)
      await fetchTags()
      setLoading(false)
      setDeleteSuccess(true)
    } catch (error: any) {
      alert({ error })
      message.error({ content: error, duration: 1 })
    }
  }

  const handleRemoveTag = async (tagId: any) => {
    try {
      setLoading(true)
      setDeleteSuccess(false)
      await removeTag(tagId)
      await fetchTags()
      await fetchUserTag()
      setLoading(false)
      setDeleteSuccess(true)
    } catch (error: any) {
      message.error({ content: error, duration: 1 })
    }
  }

  React.useEffect(() => {
    if (is_unify_finished) {
      fetchUserTag()
      fetchTagAllDialog(false)
    }
  }, [is_unify_finished])

  return {
    loading,
    tagsOfDialog: filteredTagsDialog?.data,
    deleteSuccess,
    setLoading,
    handleRemoveTagFromDialog,
    handleAddTagToDialog,
    handleUpdateTag,
    handleRemoveTag,
  }
}
