import React, { useEffect, useState } from 'react'
import { Typography, Row, Col, Card, message, Button } from 'antd'
import styles from './GettingStarted.module.scss'
import { StepCard } from './StepCard'
import getting_started_card_1 from 'common/assets/svg/getting_started_card_1.svg'
import getting_started_card_2 from 'common/assets/svg/getting_started_card_2.svg'
import getting_started_card_3 from 'common/assets/svg/getting_started_card_3.svg'
import convrt_video_thumbnail from 'common/assets/png/convrt_video_thumbnail.png'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useLazyUpdateUserProgressQuery } from 'common/api/UserApi'
import { useLocalStorage } from 'usehooks-ts'
import { useLazyGetUserInfoQuery } from 'common/api/UserApi'
import { trackEvent } from 'eventConfig'

const { Title, Paragraph } = Typography

export const GettingStarted: React.FC = () => {
  const [user, setUser] = useLocalStorage<any>('user', null)
  const navigate = useNavigate()
  const { startTour } = useIntercom()
  const [updateUser] = useLazyUpdateUserProgressQuery()
  const [progress, setProgress] = useState(user?.progress || {})
  const [getUserInfo] = useLazyGetUserInfoQuery()
  const [hasCampaigns, setHasCampaigns] = useState(false)
  const [latestCampaignId, setLatestCampaignId] = useState('')
  const [isPlaying, setIsPlaying] = useState(false)
  const { boot, showNewMessage } = useIntercom()

  const isAllComplete = (updatedProgress: Record<string, boolean>) => {
    const tasks = ['create_campaign', 'collect_leads', 'setup_categorizer']
    return tasks.every((task) => updatedProgress[task])
  }

  const bootIntercomWithMessage = () => {
    boot({
      name: user?.name,
      email: user?.email,
    })
    showNewMessage('I want to start the onboarding process!')
  }

  // Helper function to update user progress
  const updateProgress = async (feature: string, isComplete: boolean) => {
    try {
      const updatedGettingStarted = {
        ...progress.getting_started,
        [feature]: isComplete,
      }

      const completed = isAllComplete(updatedGettingStarted)

      const updatedProgress = {
        ...progress,
        getting_started: {
          ...updatedGettingStarted,
          ...(completed ? { completed: true } : { completed: false }),
        },
      }

      setProgress(updatedProgress)

      // Save progress to the backend
      const response = await updateUser({ feature: 'getting_started', updates: updatedProgress.getting_started })
      if (!response?.data?.success) {
        throw new Error('Failed to update progress.')
      }

      // Update local user object
      setUser((prevUser: any) => ({
        ...prevUser,
        progress: updatedProgress,
      }))
    } catch (error) {
      console.error('Error updating progress:', error)
      message.error('Failed to update progress. Please try again.')
    }
  }

  // Handlers for each task
  const handleCreateCampaign = () => {
    updateProgress('create_campaign', true)
    if (!hasCampaigns) {
      navigate('/outreach')
    } else {
      navigate('/outreach/create-new-campaign')
    }
    startTour(581488)
  }

  const handleCollectLeads = () => {
    if (!hasCampaigns) {
      return message.error('Please create a campaign first.')
    }
    updateProgress('collect_leads', true)
    navigate(`/outreach/create-new-campaign?campaign_id=${latestCampaignId}`)
    // startTour(581449)
  }

  const handleSetupCategorizer = () => {
    updateProgress('setup_categorizer', true)
    navigate('/settings/convrt-ai/categorizer')
    startTour(581483)
  }

  const handlePlayVideo = () => {
    setIsPlaying(true)
    trackEvent('PLAY_DEMO_CLICKED', {
      where: 'Getting Started',
      user_attributes: {
        subscription_type: user?.subscription_type,
        force_pricing: user?.force_pricing,
        plan_type: user?.plan_type,
        plan: user?.plan,
        settings_id: user?.settings_id,
      },
    })
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const { data } = await getUserInfo()
        if (data?.hasCampaigns) {
          setHasCampaigns(true)
        }
        if (data?.latestCampaignId) {
          setLatestCampaignId(data.latestCampaignId)
        }
      } catch (error) {
        console.error('Error fetching user info:', error)
      }
    }

    fetchUserInfo()
  }, [])

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <div className={styles.cardContent}>
          <Title style={{ margin: 0, lineHeight: 1 }}>Start reaching your leads in minutes</Title>
          <Paragraph className={styles.subtitle}>Follow our quick guide to get started</Paragraph>

          <Row gutter={[24, 24]} style={{ maxWidth: 1200 }}>
            <Col xs={24} md={8}>
              <StepCard
                title='Create a Campaign'
                description='Create a fully automated sequence to engage, reach, and nurture your leads.'
                buttonText='Create Campaign'
                onClick={handleCreateCampaign}
                imageSrc={getting_started_card_1}
                isComplete={progress?.getting_started?.create_campaign || false}
              />
            </Col>

            <Col xs={24} md={8}>
              <StepCard
                title='Collect Leads'
                description='Use Convrt to collect leads from social networks and discover the best communities, pages, and searches to target for optimal results.'
                buttonText='Collect Leads'
                onClick={handleCollectLeads}
                imageSrc={getting_started_card_2}
                isComplete={progress?.getting_started?.collect_leads || false}
              />
            </Col>

            <Col xs={24} md={8}>
              <StepCard
                title='Setup your AI Categorizer Agent'
                description='Define your response categories to enable the AI responder to reply appropriately and with precision.'
                buttonText='Setup Categorizer'
                onClick={handleSetupCategorizer}
                imageSrc={getting_started_card_3}
                isComplete={progress?.getting_started?.setup_categorizer || false}
              />
            </Col>
          </Row>
          <div className={styles.videoContainer}>
            <Title level={3} style={{ textAlign: 'center', marginBottom: '24px' }}>
              Watch Our Product Demo
            </Title>
            <div className={styles.videoWrapper}>
              {!isPlaying ? (
                <div
                  onClick={handlePlayVideo}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                  }}>
                  <img
                    src={convrt_video_thumbnail}
                    alt='Video thumbnail'
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '12px',
                    }}
                  />
                </div>
              ) : (
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    borderRadius: '12px',
                  }}
                  src='https://www.youtube.com/embed/TUS1c7AuP0k?autoplay=1'
                  title='Product Demo'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              )}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type='primary'
              size='large'
              onClick={bootIntercomWithMessage}
              style={{
                fontWeight: 500,
              }}>
              Request Onboarding
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}
