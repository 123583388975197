import React, { useState } from 'react'
import { Button, Card, Checkbox, CheckboxProps } from 'antd'
import styles from './SmallSocialCard.module.scss'
// import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import plus_outlined from '../../../../common/assets/svg/plus_outlined.svg'
import check_outlined from '../../../../common/assets/svg/check_outlined.svg'
import SocialConnectionModal from 'common/components/SocialConnection/SocialConnectionModal'
import crown_icon from 'common/assets/svg/crown_icon.svg'
// import { useLocalStorage } from 'usehooks-ts'

const platformMapping: any = {
  linkedin: 'LinkedIn',
  facebook: 'Facebook',
  instagram: 'Instagram',
  tiktok: 'TikTok',
  reddit: 'Reddit',
  telegram: 'Telegram',
  discord: 'Discord',
  twitter: `Twitter (X)`,
}

const SmallSocialCard: React.FC<{
  imageSrc: string
  title: any
  isSelected: boolean
  isDisabled?: boolean
  connectionStep?: boolean
  isConnected?: boolean
  isPremium?: boolean
  onSelect?: (title: string) => void
}> = ({ imageSrc, title, isSelected, isDisabled, connectionStep, isConnected, isPremium, onSelect }) => {
  const [openSocialConnectionModal, setOpenSocialConnectionModal] = useState(false)
  // const [user, setUser] = useLocalStorage<any>('user', null)

  const onChange: CheckboxProps['onChange'] = (e) => {
    if (onSelect) {
      onSelect(title)
    }
  }

  const handleOnClickCard = () => {
    if (onSelect) {
      onSelect(title)
    }
  }

  const handleConnect = () => {
    setOpenSocialConnectionModal(true)
    // setUser({ ...user, [title]: [1, 2, 3] })
  }

  return (
    <>
      <Card
        onClick={handleOnClickCard}
        className={`${styles.card} ${!connectionStep ? 'smallSocialCard' : ''} ${isPremium ? styles.premium : ''}`}
        hoverable={!connectionStep}
        style={{ borderColor: '#DFE1E6', textAlign: 'center' }}>
        {isPremium && (
          <div className={styles.premiumBadge}>
            <img src={crown_icon} alt='' />
          </div>
        )}
        <img
          src={imageSrc}
          alt={title}
          className={styles.icon}
          style={isDisabled ? { filter: 'grayscale(100%)', opacity: 0.5 } : {}}
        />
        <h3 className={styles.title}>{platformMapping[title]}</h3>

        {connectionStep ? (
          <Button onClick={handleConnect} style={{ border: '1px solid var(--Nutrals-500, #97A0AF)' }}>
            {isConnected ? (
              <div className={styles.selectButtonText}>
                <img src={check_outlined} alt='' />
                Connected
              </div>
            ) : (
              <div className={styles.selectButtonText}>
                <img src={plus_outlined} alt='' /> Connect
              </div>
            )}
          </Button>
        ) : (
          <div className={styles.selectButtonContainer} onClick={(e) => e.stopPropagation()}>
            <Checkbox style={{ padding: '0 4px' }} checked={isSelected} disabled={isDisabled} onChange={onChange}>
              <span className={styles.selectButtonText}>{isSelected ? 'Selected' : 'Select'}</span>
            </Checkbox>
          </div>
        )}
      </Card>
      {openSocialConnectionModal && (
        <SocialConnectionModal
          open={openSocialConnectionModal}
          onClose={() => setOpenSocialConnectionModal(false)}
          platform={title}
        />
      )}
    </>
  )
}

export default SmallSocialCard
