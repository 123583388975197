export const industries = [
  'Construction',
  'Restaurant',
  'Healthcare',
  'Real Estate',
  'E-commerce',
  'Building Materials',
  'Education',
  'Legal Services',
  'Property Management',
  'Automotive',
  'Technology',
  'Financial Services',
  'Beauty and Wellness',
  'Travel and Hospitality',
  'Retail',
  'Entertainment and Media',
  'Fitness and Sports',
  'Non-profit and Charity',
  'Environmental and Green Energy',
  'Food and Beverage',
  'Fashion and Apparel',
  'Manufacturing and Industrial',
  'Agriculture and Farming',
  'Pet Services',
  'Arts and Crafts',
  'Home and Garden',
  'Event Planning and Services',
  'Security and Investigation',
  'Transportation and Logistics',
  'Insurance',
  'Pharmaceutical and Biotech',
  'Telecommunications',
  'Publishing and Printing',
  'Energy and Utilities',
  'Mining and Metals',
  'Aerospace and Defense',
  'Maritime and Shipping',
  'Childcare and Education',
  'Gaming and eSports',
  'Virtual Reality and Augmented Reality',
  'Robotics and Automation',
  'Personal Finance and Wealth Management',
  'Freelancing and Gig Economy',
  'Human Resources and Recruitment',
  'Cleaning and Maintenance',
  'Plumbing and HVAC',
  'Architecture and Design',
  'Photography and Videography',
  'Sound and Music Production',
  'Veterinary and Animal Care',
  'Local Government and Civic Organizations',
  // new:
  'Accounting and Bookkeeping Services',
  'Barbershops',
  'Catering Services',
  'Cleaning Services (Residential & Commercial)',
  'Copywriting and Content Creation Services',
  'Daycare Centers',
  'Event Planning Services',
  'Fitness Centers',
  'Graphic Design Studios',
  'IT Support Services',
  'Life Coaches',
  'Marketing Agencies',
  'Massage Therapists',
  'Personal Trainers',
  'Photography Studios',
  'Social Media Management Services',
  'Spas and Salons',
  'Tax Preparation Services',
  'Virtual Assistants',
  'Yoga Studios',
  'Acupuncture Clinics',
  'Audiologists',
  'Chiropractors',
  'Dental Clinics',
  'Dermatologists',
  'Home Healthcare Providers',
  'Hospice Services',
  'Medical Practices',
  'Mental Health Counselors',
  'Nutritionists',
  'Optometrists',
  'Physical Therapy Clinics',
  'Speech Therapists',
  'Veterinary Clinics',
]

export const industryMapping: any = {
  Construction: 'construction',
  'Property Management': 'property management',
  Restaurants: 'restaurant',
  Healthcare: 'health',
  'Real Estate': 'real estate',
  'E-commerce': 'e-commerce',
  Education: 'education',
  'Building Materials': 'building materials',
  'Legal Services': 'legal',
  Automotive: 'automotive',
  Technology: 'tech',
  'Financial Services': 'financial',
  'Beauty and Wellness': 'wellness',
  'Travel and Hospitality': 'travel',
  Retail: 'retail',
  'Entertainment and Media': 'entertainment',
  'Fitness and Sports': 'fitness',
  'Non-profit and Charity': 'non-profit',
  'Environmental and Green Energy': 'green energy',
  'Food and Beverage': 'food',
  'Fashion and Apparel': 'fashion',
  'Manufacturing and Industrial': 'manufacturing',
  'Agriculture and Farming': 'agriculture',
  'Pet Services': 'pet',
  'Arts and Crafts': 'arts',
  'Home and Garden': 'home',
  'Event Planning and Services': 'event',
  'Security and Investigation': 'security',
  'Transportation and Logistics': 'transportation',
  Insurance: 'insurance',
  'Pharmaceutical and Biotech': 'pharmaceutical',
  Telecommunications: 'telecom',
  'Publishing and Printing': 'publishing',
  'Energy and Utilities': 'energy',
  'Mining and Metals': 'mining',
  'Aerospace and Defense': 'aerospace',
  'Maritime and Shipping': 'maritime',
  'Childcare and Education': 'childcare',
  'Gaming and eSports': 'gaming',
  'Virtual Reality and Augmented Reality': 'virtual reality',
  'Robotics and Automation': 'robotics',
  'Personal Finance and Wealth Management': 'finance',
  'Freelancing and Gig Economy': 'freelance',
  'Human Resources and Recruitment': 'hr',
  'Cleaning and Maintenance': 'cleaning',
  'Plumbing and HVAC': 'plumbing',
  'Architecture and Design': 'architecture',
  'Photography and Videography': 'photography',
  'Sound and Music Production': 'music',
  'Veterinary and Animal Care': 'veterinary',
  'Local Government and Civic Organizations': 'government',
}

export const promptTitle = [
  {
    id: 1,
    title: 'Quick Start',
    description: 'Start engaging with prospects right away to achieve fast results.',
    addon_name: 'prompt_cold_outreach',
    type: 'general',
  },
  {
    id: 2,
    title: 'Choose a Template',
    description: 'Choose from a ready-made saved template or explore the Convrt marketplace for more options.',
    addon_name: 'prompt_templates',
    type: 'templates',
  },
  {
    id: 3,
    title: 'Build From Scratch',
    description:
      'Use the builder to create your own sequence from scratch and personalize it to meet your specific needs.',
    addon_name: 'prompt_custom',
    type: 'custom',
  },
]

export const personas = [
  'Owner',
  'Founder',
  'CEO',
  'Sales',
  'Operations',
  'Marketing',
  'President',
  'COO',
  'Partner',
  'Director',
  'Engineering',
  'Finance',
]

export const hobbies = [
  'Action figure',
  'Aerospace',
  'Air hockey',
  'Air sports',
  'Aircraft spotting',
  'Airsoft',
  'Amateur astronomy',
  'Amateur geology',
  'Amusement park visiting',
  'Animal fancy',
  'Animation',
  'Ant farming',
  'Ant-keeping',
  'Antiquing',
  'Antiquities',
  'Archaeology',
  'Archery',
  'Architecture',
  'Art collecting',
  'Association football',
  'Astronomy',
  'Audiophile',
  'Australian rules football',
  'Auto audiophilia',
  'Auto detailing',
  'Auto racing',
  'Automobilism',
  'Axe throwing',
  'BASE jumping',
  'BMX',
  'Backgammon',
  'Backpacking',
  'Badminton',
  'Baking',
  'Ballet dancing',
  'Ballroom dancing',
  'Baseball',
  'Basketball',
  'Baton twirling',
  'Beach volleyball',
  'Beachcombing',
  'Beauty pageants',
  'Beekeeping',
  'Benchmarking',
  'Billiards',
  'Biology',
  'Birdwatching',
  'Blacksmithing',
  'Board sports',
  'Bodybuilding',
  'Book collecting',
  'Book folding',
  'Botany',
  'Bowling',
  'Boxing',
  'Breakdancing',
  'Bridge',
  'Bus riding',
  'Bus spotting[22',
  'Business',
  'Butterfly watching',
  'Button collecting',
  'Camping',
  'Canoeing',
  'Canyoning',
  'Car riding',
  'Car tuning',
  'Carrier pigeons',
  'Cartophily (card collecting)',
  'Caving',
  'Checkers (draughts)',
  'Cheerleading',
  'Chemistry',
  'Chess',
  'City trip',
  'Climbing',
  'Coin collecting',
  'Color guard',
  'Comic book collecting',
  'Compact discs',
  'Composting',
  'Cooking',
  'Cornhole',
  'Cribbage',
  'Cricket',
  'Croquet',
  'Crystals',
  'Curling',
  'Cycling',
  'Dairy Farming',
  'Dancing',
  'Dandyism',
  'Darts',
  'Debate',
  'Deltiology (postcard collecting)',
  'Die-cast toy',
  'Digital hoarding',
  'Disc golf',
  'Dodgeball',
  'Dog sport',
  'Dog training',
  'Dog walking',
  'Dolls',
  'Dominoes',
  'Dowsing',
  'Driving',
  'Eating',
  'Element collecting',
  'Entrepreneurship',
  'Ephemera collecting',
  'Equestrianism',
  'Esports',
  'Exhibition drill',
  'Farming',
  'Fencing',
  'Field hockey',
  'Figure Skating',
  'Figure skating',
  'Films',
  'Finance',
  'Fingerprint collecting',
  'Fishing',
  'Fishkeeping',
  'Fitness',
  'Flag football',
  'Flower collecting and pressing',
  'Flower growing',
  'Flying',
  'Flying disc',
  'Flying model planes',
  'Footbag',
  'Foraging',
  'Fossicking',
  'Fossil hunting',
  'Freestyle football',
  'Frisbee',
  'Fruit picking',
  'Fusilately (phonecard collecting)',
  'Gardening',
  'Geocaching',
  'Geography',
  'Ghost hunting',
  'Go',
  'Gold prospecting',
  'Golfing',
  'Gongoozling',
  'Graffiti',
  'Groundhopping',
  'Guerrilla gardening',
  'Gymnastics',
  'Handball',
  'Herbalism',
  'Herping',
  'High-power rocketry',
  'Hiking',
  'Hiking/backpacking',
  'History',
  'Hobby horsing',
  'Hobby tunneling',
  'Hooping',
  'Horseback riding',
  'Horsemanship',
  'Horseshoes',
  'Hunting',
  'Ice hockey',
  'Ice skating',
  'Iceboat racing',
  'Inline skating',
  'Insect collecting',
  'Jogging',
  'Judo',
  'Jujitsu',
  'Jukskei',
  'Jumping rope',
  'Kabaddi',
  'Kart racing',
  'Karting',
  'Kayaking',
  'Kite flying',
  'Kitesurfing',
  'Knife collecting',
  'Knife throwing',
  'Knowledge/word games',
  'LARPing',
  'Lacrosse',
  'Lapel pins',
  'Laser tag',
  'Learning',
  'Leaves',
  'Letterboxing',
  'Life science',
  'Linguistics',
  'Literature',
  'Lomography',
  'Long-distance running',
  'Longboarding',
  'Lotology (lottery ticket collecting)',
  'Magic',
  'Magnet fishing',
  'Mahjong',
  'Marbles',
  'Marching band',
  'Martial arts',
  'Mathematics',
  'Medical science',
  'Meditation',
  'Metal detecting',
  'Meteorology',
  'Microbiology',
  'Microscopy',
  'Mineral collecting',
  'Mini Golf',
  'Model United Nations',
  'Model aircraft',
  'Model racing',
  'Motor sports',
  'Motorcycling',
  'Mountain biking',
  'Mountaineering',
  'Movie memorabilia collecting',
  'Museum visiting',
  'Mushroom hunting/mycology',
  'Mycology',
  'Myrmecology',
  'Netball',
  'Neuroscience',
  'Noodling',
  'Nordic skating',
  'Notaphily (banknote collecting)',
  'Orienteering',
  'Paintball',
  'Paragliding',
  'Parkour',
  'People-watching',
  'Perfume',
  'Philately',
  'Phillumeny',
  'Philosophy',
  'Photography',
  'Physics',
  'Pickleball',
  'Picnicking',
  'Pinball',
  'Podcast hosting',
  'Poker',
  'Pole dancing',
  'Polo',
  'Pool',
  'Powerboat racing',
  'Powerlifting',
  'Psychology',
  'Public transport riding',
  'Qigong',
  'Quidditch',
  'Race walking',
  'Racquetball',
  'Radio-controlled car racing',
  'Radio-controlled model collecting',
  'Radio-controlled model playing',
  'Rafting',
  'Rail transport modelling',
  'Railway journeys',
  'Railway modelling',
  'Railway studies',
  'Rappelling',
  'Reading',
  'Record collecting',
  'Religious studies',
  'Renaissance fair',
  'Renovating',
  'Research',
  'Road biking',
  'Rock balancing',
  'Rock climbing',
  'Rock painting',
  'Rock tumbling',
  'Role-playing games',
  'Roller derby',
  'Roller skating',
  'Roundnet',
  'Rugby',
  'Rugby league football',
  'Rughooking',
  'Running',
  'Safari',
  'Sailing',
  'Sand art',
  'Satellite watching',
  'Science and technology studies',
  'Scouting',
  'Scuba diving',
  'Sculling or rowing',
  'Scutelliphily',
  'Sea glass collecting',
  'Seashell collecting',
  'Shoes',
  'Shogi',
  'Shooting',
  'Shooting sports',
  'Shopping',
  'Shortwave listening',
  'Shuffleboard',
  'Skateboarding',
  'Skiing',
  'Skimboarding',
  'Skydiving',
  'Slacklining',
  'Sled dog racing',
  'Sledding',
  'Slot car',
  'Slot car racing',
  'Snorkeling',
  'Snowboarding',
  'Snowmobiling',
  'Snowshoeing',
  'Soccer',
  'Social studies',
  'Sociology',
  'Softball',
  'Speed skating',
  'Speedcubing',
  'Sport stacking',
  'Sports memorabilia',
  'Sports science',
  'Squash',
  'Stamp collecting',
  'Stone collecting',
  'Stone skipping',
  'Storm chasing',
  'Story writing',
  'Stuffed toy collecting',
  'Sun bathing',
  'Surfing',
  'Survivalism',
  'Swimming',
  'Table football',
  'Table tennis',
  'Table tennis playing',
  'Taekwondo',
  'Tai chi',
  'Tea bag collecting',
  'Teaching',
  'Tennis',
  'Tennis polo',
  'Tether car',
  'Thru-hiking',
  'Ticket collecting',
  'Topiary',
  'Tour skating',
  'Tourism',
  'Trade fair visiting',
  'Trainspotting',
  'Transit map collecting',
  'Trapshooting',
  'Travel',
  'Triathlon',
  'Ultimate frisbee',
  'Unicycling',
  'Urban exploration',
  'VR gaming',
  'Vacation',
  'Vegetable farming',
  'Vehicle restoration',
  'Video game collecting',
  'Video gaming',
  'Videography',
  'Vintage cars',
  'Vintage clothing',
  'Vinyl records',
  'Volleyball',
  'Volunteering',
  'Walking',
  'Water polo',
  'Water sports',
  'Web design',
  'Weightlifting',
  'Whale watching',
  'Wikipedia editing',
  'Wrestling',
  'Zoo visiting',
  'capoeira',
  'engineering',
  'jurisprudential',
  'publishing',
]

export const countryAliases = {
  USA: 'United States',
  US: 'United States',
  UK: 'United Kingdom',
  England: 'United Kingdom',
  France: 'France',
  Germany: 'Germany',
  DE: 'Germany',
  Italy: 'Italy',
  IT: 'Italy',
  Spain: 'Spain',
  ES: 'Spain',
  Netherlands: 'Netherlands',
  NL: 'Netherlands',
  Switzerland: 'Switzerland',
  CH: 'Switzerland',
  Sweden: 'Sweden',
  SE: 'Sweden',
  Norway: 'Norway',
  NO: 'Norway',
  Denmark: 'Denmark',
  DK: 'Denmark',
  Belgium: 'Belgium',
  BE: 'Belgium',
  Austria: 'Austria',
  AT: 'Austria',
  Ireland: 'Ireland',
  IE: 'Ireland',
  Finland: 'Finland',
  FI: 'Finland',
  Poland: 'Poland',
  PL: 'Poland',
  Portugal: 'Portugal',
  PT: 'Portugal',
  Canada: 'Canada',
  CA: 'Canada',
  Australia: 'Australia',
  AU: 'Australia',
  Japan: 'Japan',
  JP: 'Japan',
  China: 'China',
  CN: 'China',
  India: 'India',
  IN: 'India',
  Brazil: 'Brazil',
  BR: 'Brazil',
  Mexico: 'Mexico',
  MX: 'Mexico',
  SouthKorea: 'South Korea',
  Korea: 'South Korea',
  KR: 'South Korea',
  Russia: 'Russia',
  RU: 'Russia',
  SouthAfrica: 'South Africa',
  SA: 'South Africa',
  SaudiArabia: 'Saudi Arabia',
  UAE: 'United Arab Emirates',
  Emirates: 'United Arab Emirates',
  Singapore: 'Singapore',
  SG: 'Singapore',
}
export const FailedCollections = ['failed', 'account_suspended', 'account_unconnected', 'not_found']
export const AllFailedCollections = [
  'failed',
  'account_suspended',
  'account_unconnected',
  'not_found',
  'account_logged_out_or_banned',
  'logged_out',
  'private_group_not_joined',
]
