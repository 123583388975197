import React, { useEffect, useState } from 'react'
import { Layout, Menu, Spin, message } from 'antd'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import {
  ProfileOutlined,
  NotificationOutlined,
  DollarCircleOutlined,
  AppstoreAddOutlined,
  TeamOutlined,
  LoadingOutlined,
  ThunderboltOutlined,
  QrcodeOutlined,
  LoginOutlined,
} from '@ant-design/icons'
import styles from './SettingsPage.module.scss'
import ProfileSettings from './Pages/ProfileSettings'
import SocialSettings from './Pages/SocialSettings'
import Categorizer from './Pages/Categorizer'
import SubscriptionSettings from './Pages/SubscriptionSettings'
import {
  useLazyGetIndividualPersonasQuery,
  useLazyGetSalesforceAccessTokenQuery,
  useLazyGetSalesforceKeysQuery,
  useLazyGetSlackKeysQuery,
  useLazyGetSubscriptionSettingsQuery,
  useLazyGetUserSettingsQuery,
  useLazyGetUsersListQuery,
  useLazyIsRegistrationAdminQuery,
  useLazyRedirectSlackOAuthQuery,
} from './state/api/SettingsApi'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import useHubspot from './Pages/Hooks/useHubspot'
import ICPSettings from './Pages/ICPSettings'
import { useLazyGetPricingPlansQuery } from './Pages/Upgrade/API/UpgradeApi'
import { useLocalStorage } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_REGISTRATION_ADMIN } from './state/slice/settingsSlice'
import GenerateRegistrationCode from './Pages/GenerateRegistrationCode'
import CustomerServiceSettings from './Pages/CustomerServiceSettings'
import { useLazyGetConvrtQuery } from 'common/api/UserApi'
import ABLinkManagement from './Pages/ABLink/ABLinkManagement'
import { useIntercom } from 'react-use-intercom'

const { Sider, Content } = Layout

const Settings: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [redirectSlackOAuth] = useLazyRedirectSlackOAuthQuery()
  const [getSalesforceAccessToken] = useLazyGetSalesforceAccessTokenQuery()
  const [selectedMenu, setSelectedMenu] = useState('profile')
  const [refetchGetSubscriptionSettings, { data: subscriptionData, isFetching }] = useLazyGetSubscriptionSettingsQuery()
  const [getPricingPlans] = useLazyGetPricingPlansQuery()
  const [getUserSettings, { data: businessPersonasData }] = useLazyGetUserSettingsQuery()
  const [getIndividualPersonas, { data: individualPersonasData }] = useLazyGetIndividualPersonasQuery()
  const [refetchUsersList] = useLazyGetUsersListQuery()
  const { userDetails, updateUserDetails } = useAuth()
  const [shouldHideCheckout, setShouldHideCheckout] = useState(userDetails?.flag)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [getSlackKeys] = useLazyGetSlackKeysQuery()
  const [getSalesforceKeys] = useLazyGetSalesforceKeysQuery()
  const [getIsRegistrationAdminQuery] = useLazyIsRegistrationAdminQuery()
  const [slackKey, setSlackKey] = useState('')
  const [salesforceKey, setSalesforceKey] = useState('')
  const { existKey } = useHubspot()
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [user] = useLocalStorage<any>('user', null)
  const [isCustomerService, setIsCustomerService] = useState(false)
  const [userAddons, setUserAddons] = useState(user?.addons || {})
  const { pricing } = userAddons
  const { registrationAdmin } = useAppSelector((state) => state.settings)
  const dispatch = useAppDispatch()
  const [getConvrt] = useLazyGetConvrtQuery()
  const { boot, showNewMessage } = useIntercom()

  const handleMessageIntercom = (platform: string) => {
    boot({
      name: user?.name,
      email: user?.email,
    })
    showNewMessage(`Hi, I'm interested in the ${platform} integration!`)
  }

  const handleMenuClick = async (selectedKey: string) => {
    const pathMap: { [key: string]: string } = {
      profile: '/settings/account/profile',
      integrations: '/settings/account/integrations',
      categorizer: '/settings/convrt-ai/categorizer',
      icp: '/settings/convrt-ai/icp',
      oneTimeLogin: '/settings/customer-service/one-time-login',
      registrationCode: '/settings/registration/code',
      registrationAB: '/settings/registration/ab-link',
      subscription: '/settings/admin/subscription',
      upgrade: '/upgrade',
    }

    const path = pathMap[selectedKey] || '/settings'
    navigate(path)
    setSelectedMenu(selectedKey)
  }

  const redirectSlack = async (code: any, state?: any) => {
    const { data, error } = await redirectSlackOAuth({ code })
    if (data?.connected) {
      setSlackKey(data.access_token)
      message.success(data?.message)
      handleMessageIntercom('Slack')
    } else if (error?.data?.error === 'invalid_code') {
      message.error('Invalid Session')
    } else {
      message.error('Something went wrong connecting with Slack. Please try again.')
    }
  }

  const connectSalesforce = async (code: any) => {
    const codeVerifier = localStorage.getItem('salesforce_code_verifier')
    const { data, error } = await getSalesforceAccessToken({ codeVerifier: codeVerifier, authCode: code })

    if (data?.connected) {
      setSalesforceKey(data.access_token)
      message.success(data?.message)
      handleMessageIntercom('Salesforce')
    } else if (error?.data?.message === 'User already connected to Salesforce') {
    } else {
      message.error('Something went wrong connecting with Salesforce. Please try again.')
    }
  }

  const resetKeysStates = (state: string) => {
    switch (state) {
      case 'slack':
        setSlackKey('')
        break
      case 'salesforce':
        setSalesforceKey('')
        break
    }
  }

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setShouldHideCheckout(data.flag)
    }

    const getSubscriptionSettings = async () => {
      await refetchGetSubscriptionSettings()
    }

    const getUsersList = async () => {
      await refetchUsersList()
    }

    const userSettings = async () => {
      await Promise.all([getUserSettings(), getIndividualPersonas()])
    }
    const getIsRegistrationAdmin = async () => {
      try {
        const { data } = await getIsRegistrationAdminQuery()
        if (data) {
          const { isRegistrationAdmin } = data
          dispatch(SET_REGISTRATION_ADMIN(isRegistrationAdmin))
        }
      } catch (e) {}
    }
    getUserDetails()
    getSubscriptionSettings()
    getUsersList()
    updateUserDetails()
    userSettings()
    getIsRegistrationAdmin()
  }, [])

  useEffect(() => {
    const getKeys = async () => {
      const { data: slackData } = await getSlackKeys()
      if (slackData?.access_token) {
        setSlackKey(slackData?.access_token)
        localStorage.setItem('slack_access_token', slackData.access_token)
      }

      const { data: salesforceData } = await getSalesforceKeys()
      if (salesforceData?.access_token) {
        setSalesforceKey(salesforceData?.access_token)
        localStorage.setItem('salesforce_access_token', salesforceData.access_token)
      }
    }
    getKeys()
  }, [])

  useEffect(() => {
    if (user && user.addons) {
      setUserAddons(user.addons)
    }
  }, [user])

  useEffect(() => {
    const getPlans = async () => {
      await getPricingPlans()
    }
    getPlans()
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')
    const path = window.location.pathname
    const slack = localStorage.getItem('slack_access_token')
    const salesforce = localStorage.getItem('salesforce_access_token')

    if (code && path.includes('slack') && !slack) {
      redirectSlack(code)
    } else if (code && path.includes('salesforce') && !salesforce) {
      connectSalesforce(code)
    }
  }, [location.search])

  useEffect(() => {
    const getIsSuperUser = async () => {
      const { data } = await getConvrt()
      if (data?.is_customer_service) {
        setIsCustomerService(true)
      } else {
        setIsCustomerService(false)
      }
    }

    getIsSuperUser()
  }, [])

  useEffect(() => {
    if (location.state?.selectedMenu) {
      setSelectedMenu(location.state.selectedMenu)
    }
  }, [location.state])

  useEffect(() => {
    const keyMap: { [key: string]: string } = {
      '/settings/account/profile': 'profile',
      '/settings/account/integrations': 'integrations',
      '/settings/convrt-ai/categorizer': 'categorizer',
      '/settings/convrt-ai/icp': 'icp',
      '/settings/customer-service/one-time-login': 'oneTimeLogin',
      '/settings/registration/code': 'registrationCode',
      '/settings/registration/ab-link': 'registrationAB',
      '/settings/admin/subscription': 'subscription',
    }

    const selectedKey = keyMap[location.pathname] || 'profile'
    setSelectedMenu(selectedKey)
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname === '/settings') {
      navigate('/settings/account/profile')
    }
  }, [])

  return (
    <Layout className={styles.settingsLayout}>
      {/* <button onClick={}>click</button> */}
      <Sider width={260} theme='light' className={styles.sidebar}>
        <div className={styles.sidebarHeader}>Settings</div>

        <Menu
          mode='inline'
          style={{ paddingRight: '20px' }}
          selectedKeys={[selectedMenu]}
          onClick={({ key }) => handleMenuClick(key.toString())}>
          {isCustomerService && (
            <Menu.ItemGroup key='customer_service' title='Customer Service' style={{ marginTop: '20px' }}>
              <Menu.Item key='oneTimeLogin' icon={<LoginOutlined />}>
                One-Time Login
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          {registrationAdmin && (
            <Menu.ItemGroup key='registrationCode' title='Registration' style={{ marginTop: '20px' }}>
              <Menu.Item key='registrationCode' icon={<QrcodeOutlined />}>
                Generate Registration Code
              </Menu.Item>
              <Menu.Item key='registrationAB' icon={<QrcodeOutlined />}>
                A/B Link Management
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          <Menu.ItemGroup key='account' title='Account' style={{ marginTop: '20px' }}>
            <Menu.Item key='profile' icon={<ProfileOutlined />}>
              Profile
            </Menu.Item>
            <Menu.Item key='integrations' icon={<NotificationOutlined />}>
              Social & Integrations
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup key='categorizer' title='AI Social Agents' style={{ marginTop: '20px' }}>
            {
              <Menu.Item key='categorizer' icon={<AppstoreAddOutlined />}>
                Categorizer
              </Menu.Item>
            }
            <Menu.Item key='icp' icon={<TeamOutlined />}>
              ICP Persona
            </Menu.Item>
          </Menu.ItemGroup>
          {pricing && (
            <Menu.ItemGroup key='admin' title='Admin' style={{ marginTop: '20px' }}>
              <Menu.Item
                key='subscription'
                icon={loading ? <Spin size='small' indicator={<LoadingOutlined spin />} /> : <DollarCircleOutlined />}>
                Subscriptions & Billing
              </Menu.Item>
              {!shouldHideCheckout && (
                <Menu.Item key='upgrade' icon={<ThunderboltOutlined />}>
                  Upgrade
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          )}
        </Menu>
      </Sider>
      <Layout>
        <Content className={styles.content}>
          <Routes>
            <Route path='account/profile' element={<ProfileSettings shouldHideCheckout={shouldHideCheckout} />} />
            <Route
              path='account/integrations'
              element={
                <SocialSettings
                  shouldHideCheckout={shouldHideCheckout}
                  hubspotKey={existKey}
                  slackKey={slackKey}
                  salesforceKey={salesforceKey}
                  resetKeysStates={resetKeysStates}
                />
              }
            />
            <Route path='convrt-ai/categorizer' element={<Categorizer shouldHideCheckout={shouldHideCheckout} />} />
            <Route
              path='convrt-ai/icp'
              element={
                <ICPSettings businessPersonas={businessPersonasData?.ICP} individualPersonas={individualPersonasData} />
              }
            />
            <Route path='customer-service/one-time-login' element={<CustomerServiceSettings />} />
            <Route path='registration/code' element={<GenerateRegistrationCode />} />
            <Route path='registration/ab-link' element={<ABLinkManagement />} />
            <Route
              path='admin/subscription'
              element={<SubscriptionSettings data={subscriptionData} isFetching={isFetching} />}
            />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  )
}

export default Settings
