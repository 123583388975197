import visible_eye_icon from 'common/assets/svg/visible_eye_icon.svg'
import hidden_eye_icon from 'common/assets/svg/hidden_eye_icon.svg'

import React, { useEffect, useRef, useState } from 'react'
import { Modal, Button, Layout, Divider, Tabs, message, Tooltip } from 'antd'
import styles from './ImportLeadsFromGroupsModal.module.scss'
import {
  useLazyGetExploreLoadingQuery,
  useLazyGetExploreQuery,
  useLazyGetGroupDetailsQuery,
  useLazyGetMyHubQuery,
  useLazyGetSavedFiltersQuery,
} from 'common/components/OutreachAI/state/api/OutreachAI-API'
import GroupsSidebar from './GroupsSidebar'
import GroupsContent from './GroupsContent'
import GroupsFilters from './GroupsFilters'
import GroupsTable from './GroupsTable'
import { useAppDispatch, useAppSelector } from 'state'
import {
  SET_ARE_LEADS_FROM_GROUPS,
  SET_INITIAL_GROUP_DATA,
  SET_SELECTED_LEADS,
} from 'common/components/OutreachAI/state/outreachAICampaignSlice'
import { useLazySaveCampaignLeadsQuery } from 'features/Outreach/state/api/OutreachApi'
import { capitalize } from 'lodash'
import { useLazyGetAllIcpQuery } from 'features/settings/state/api/SettingsApi'

const { Header } = Layout
const { TabPane } = Tabs

const headerStyle: React.CSSProperties = {
  color: 'black',
  fontWeight: 600,
  fontSize: '18px',
  height: 80,
  paddingInline: 24,
  lineHeight: '64px',
  backgroundColor: 'white',
  borderBottom: '1px solid #DFE1E6',
  alignContent: 'center',
}

const layoutStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  backgroundColor: 'white',
  height: 'calc(100vh - 210px)',
}

const sortData = (data: any[], sortKey: string, ascending: boolean = true) => {
  if (!data) return []
  return data?.sort((a, b) => {
    if (a[sortKey] < b[sortKey]) return ascending ? -1 : 1
    if (a[sortKey] > b[sortKey]) return ascending ? 1 : -1
    return 0
  })
}

interface ImportLeadsFromGroupsModalProps {
  open: boolean
  onClose: () => void
  isLoading: boolean
}

const ImportLeadsFromGroupsModal: React.FC<ImportLeadsFromGroupsModalProps> = ({ open, onClose, isLoading }) => {
  const [searchValue, setSearchValue] = useState('')
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [saveCampaignLeads] = useLazySaveCampaignLeadsQuery()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const { initial_group_data: groupsData } = useAppSelector((state) => state.outreachAICampaign)
  const [getGroupDetails, { isFetching: isGroupDataFetching }] = useLazyGetGroupDetailsQuery()
  const [getSavedFilters, { data: savedFilters }] = useLazyGetSavedFiltersQuery()
  const [getExplore] = useLazyGetExploreQuery()
  const [getMyHub] = useLazyGetMyHubQuery()
  const [groupData, setGroupData] = useState<any>()
  const [loadingLeads, setLoadingLeads] = useState(false)
  const [showNewLeads, setShowNewLeads] = useState(false)
  const [filterObject, setFilterObject] = useState<any>({
    a: 'Explore All',
    b: '',
    c: [],
  })
  const [selectedType, setSelectedType] = useState('Result')
  const [selectedGroup, setSelectedGroup] = useState<any>('')
  const [selectedGroupName, setSelectedGroupName] = useState('')
  const [groupType, setGroupType] = useState('')
  const { selected_leads, active_platforms, campaign_id } = useAppSelector((state) => state.outreachAICampaign)
  const [filteredData, setFilteredData] = useState(groupData)
  const [smallerThan, setSmallerThan] = useState<number | undefined>(0)
  const [greaterThan, setGreaterThan] = useState<number | undefined>(1000000)
  const dispatch = useAppDispatch()
  const [initialLoading, setInitialLoading] = useState(true)
  const [activeTabKey, setActiveTabKey] = useState('2')
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [openCustomImport, setOpenCustomImport] = useState(false)
  const [importData, setImportData] = useState<any>({})
  const [getICP, { data: icpData }] = useLazyGetAllIcpQuery() // Get ICP data
  const [myHubLoading, setMyHubLoading] = useState(true)
  const firstTimeRef = useRef(true)
  const [loadIncrement, setLoadIncrement] = useState(9)
  const [getExploreLoading] = useLazyGetExploreLoadingQuery()
  const [exploreLoading, setExploreLoading] = useState(false)
  // For now. Change when implementing multi-channel campaigns
  const truePlatformsArray = Object.entries(active_platforms)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
  const selectedPlatform = truePlatformsArray?.[0]

  const suggestionsData = [
    ...(groupsData?.suggestions?.facebook_groups || []),
    ...(groupsData?.suggestions?.my_facebook_groups || []),
    ...(groupsData?.suggestions?.my_instagram_groups || []),
    ...(groupsData?.suggestions?.linkedin_searches || []),
    ...(groupsData?.suggestions?.instagram_hashtags || []),
  ]
  const myHubGroups = [...(groupsData?.groups?.gfp || []), ...(groupsData?.groups?.linkedin || [])]
  // displayed groups are all of the groups that match the selected category and channel

  const displayedGroups = activeTabKey === '1' ? myHubGroups : suggestionsData

  const goToProgress = () => {
    setActiveTabKey('1')
    setFilterObject({
      ...filterObject,
      a: 'On Importing Progress',
    })
  }
  const onLinkedinCollect = async (search: any) => {
    setImportData({
      platform: 'linkedin',
      source_type: search?.type === 'search_people' ? 'linkedin_search' : 'sales_nav',
      url: search?.search_value,
      searchName: search?.name,
    })
  }
  const onBack = () => {
    setSelectedGroup('')
    setSelectedGroupName('')
    setSelectedTags([])
    setGroupData([])
  }
  const handleAddLeads = async () => {
    setLoadingAdd(true)
    const selectedData = groupData.filter((d: any) => selectedRowKeys.includes(d.key))
    const newLeadsToAdd = selectedData.filter(
      (newLead: any) => !selected_leads.some((existingLead: any) => existingLead.key === newLead.key),
    )
    let source = newLeadsToAdd[0]?.followed_username
    if (!source) {
      source = selectedGroupName
    }
    const leadsForCampaign = newLeadsToAdd?.map((lead: any) => ({
      ...lead,
      platform: selectedPlatform,
      convrt_source: 'social_hub',
      categories: [lead?.category],
      source: source,
    }))
    dispatch(SET_ARE_LEADS_FROM_GROUPS(true))
    dispatch(SET_SELECTED_LEADS(leadsForCampaign))
    await saveCampaignLeads({
      leads: leadsForCampaign,
      campaign_id: campaign_id,
      mode: 'groups',
    })
    setSelectedRowKeys([])
    setLoadingAdd(false)
    onClose()
  }

  const checkFieldsForKeywords = (group: any, keyword: string) => {
    const lowerKeyword = keyword.toLowerCase()

    if (selectedPlatform === 'linkedin') {
      return (
        group?.biography?.toLowerCase().includes(lowerKeyword) ||
        group?.location?.toLowerCase().includes(lowerKeyword) ||
        group?.skills?.toLowerCase().includes(lowerKeyword) ||
        group?.company?.toLowerCase().includes(lowerKeyword) ||
        group?.experience?.toLowerCase().includes(lowerKeyword) ||
        group?.connection_level?.toLowerCase().includes(lowerKeyword) ||
        group?.headline?.toLowerCase().includes(lowerKeyword) ||
        group?.position?.toLowerCase().includes(lowerKeyword)
      )
    }

    return group?.biography?.toLowerCase().includes(lowerKeyword)
  }

  const handleFilterChange = ({ keywords, operator, conditionedKeywords }: any) => {
    let filtered = groupData

    if (keywords.length > 0) {
      filtered = filtered.filter((group: any) =>
        keywords.some((keyword: any) => checkFieldsForKeywords(group, keyword)),
      )
    }

    if (conditionedKeywords.length > 0) {
      if (operator === 'AND') {
        filtered = filtered.filter((group: any) =>
          conditionedKeywords.every((keyword: any) => checkFieldsForKeywords(group, keyword)),
        )
      } else if (operator === 'AND NOT') {
        filtered = filtered.filter((group: any) =>
          conditionedKeywords.every((keyword: any) => !checkFieldsForKeywords(group, keyword)),
        )
      }
    }

    if (
      selectedPlatform === 'instagram' &&
      smallerThan !== undefined &&
      smallerThan !== null &&
      typeof smallerThan === 'number' &&
      smallerThan >= 0
    ) {
      filtered = filtered?.filter((group: any) => group.follower_count && group.follower_count >= smallerThan)
    }

    if (
      selectedPlatform === 'instagram' &&
      greaterThan !== undefined &&
      greaterThan !== null &&
      typeof greaterThan === 'number' &&
      greaterThan >= 0
    ) {
      filtered = filtered?.filter((group: any) => group.follower_count && group.follower_count <= greaterThan)
    }
    if (selectedTags?.length > 0) {
      filtered = filtered.filter((lead: any) => selectedTags.includes(lead.category))
    }
    setFilteredData(filtered)
  }

  const handleOnLeads = async () => {
    if (showNewLeads === false) {
      setShowNewLeads(true)
      setLoadingLeads(true)
      const { data } = await getGroupDetails({ group: selectedGroup, showNewLeads: true })
      setGroupData(data)
      setFilteredData(data)
      setLoadingLeads(false)
    } else {
      setShowNewLeads(false)
      const { data } = await getGroupDetails({ group: selectedGroup })
      setGroupData(data)
      setFilteredData(data)
    }
  }

  const handleSelectGroup = (group: any) => {
    if (group.platform === selectedPlatform) {
      setSelectedGroup(group)
    } else {
      message.error(`You can only select leads from ${capitalize(selectedPlatform)} for this campaign.`)
    }
  }

  const handleCustomImport = () => {
    setActiveTabKey('1')
  }

  useEffect(() => {
    const groupDetails = async () => {
      await getSavedFilters()
      const { data } = await getGroupDetails({ group: selectedGroup, showNewLeads: showNewLeads })
      if (selectedGroup?.type) {
        setGroupType(selectedGroup?.type)
      }
      setGroupData(data)
      setFilteredData(data)
    }

    if (selectedGroup) {
      groupDetails()
    }
  }, [selectedGroup])

  useEffect(() => {
    const getAllExplore = async () => {
      if (firstTimeRef.current) {
        firstTimeRef.current = false
        const { data: loading } = await getExploreLoading()
        setExploreLoading(loading?.loadingStates)
        const { data: myHubData } = await getMyHub()
        setMyHubLoading(false)
        dispatch(SET_INITIAL_GROUP_DATA({ ...myHubData }))

        const { data } = await getExplore()
        dispatch(SET_INITIAL_GROUP_DATA({ ...data, ...myHubData }))
        if (initialLoading) {
          setInitialLoading(false)
        }
      } else {
        const { data: loading } = await getExploreLoading()
        setExploreLoading(loading?.loadingStates)
        const { data } = await getExplore()
        const { data: myHubData } = await getMyHub()
        dispatch(SET_INITIAL_GROUP_DATA({ ...data, ...myHubData }))
        if (initialLoading) {
          setInitialLoading(false)
        }
      }
    }

    getAllExplore()
    getICP()

    const intervalId = setInterval(() => {
      getAllExplore()
    }, 5000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <Modal
      className='groupsModal'
      centered
      closable={false}
      open={open}
      footer={null}
      onCancel={onClose}
      width='87%'
      maskClosable={false}>
      {selectedGroup ? (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>
                Add Leads From {selectedGroupName || 'N/A'} <Divider type='vertical' />{' '}
                <span style={{ fontSize: '14px' }}>
                  {selectedRowKeys?.length || 0} leads <span style={{ fontWeight: 500 }}>were selected</span>
                </span>
              </span>
              <div className={styles.buttons}>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onClose}>
                  Cancel
                </span>
                <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onBack}>
                  Back
                </span>
                <Button
                  loading={loadingAdd}
                  type='primary'
                  onClick={handleAddLeads}
                  disabled={!selectedRowKeys.length || isGroupDataFetching}>
                  Add Leads
                </Button>
              </div>
            </div>
          </Header>
          <div className={styles.title}>
            <span>
              Displaying{' '}
              <span style={{ fontWeight: 600 }}>all of {filteredData?.length?.toLocaleString()} available leads</span>{' '}
              from {selectedGroupName}
            </span>
            <Tooltip title={`${showNewLeads ? 'Show' : 'Hide'} leads from my other campaigns`}>
              <img
                className={styles.hiddenEye}
                onClick={handleOnLeads}
                src={showNewLeads ? hidden_eye_icon : visible_eye_icon}
                alt=''
              />
            </Tooltip>
          </div>

          <Layout
            style={{
              backgroundColor: 'white',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              alignSelf: 'stretch',
            }}>
            <Layout.Content
              style={{
                padding: 0,
                margin: 0,
                backgroundColor: 'white',
              }}>
              <GroupsTable
                selectedGroup={selectedGroupName}
                data={filteredData}
                groupType={groupType}
                isLoading={isGroupDataFetching}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                onLeads={handleOnLeads}
                loadingLeads={loadingLeads}
                showNewLeads={showNewLeads}
              />
            </Layout.Content>
            {/* <RawFilters onFilterChange={() => {}} /> */}
            <GroupsFilters
              onFilterChange={handleFilterChange}
              selectedPlatform={selectedPlatform}
              groupType={groupType}
              smallerThan={smallerThan}
              setSmallerThan={setSmallerThan}
              greaterThan={greaterThan}
              setGreaterThan={setGreaterThan}
              data={groupData}
              tagsSelected={selectedTags}
              setTagsSelected={setSelectedTags}
              savedFilters={savedFilters}
              getSavedFilters={getSavedFilters}
            />
          </Layout>
        </Layout>
      ) : (
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <div className={styles.header}>
              <span>Social Hub</span>
              <Tabs
                activeKey={activeTabKey}
                onChange={(key) => {
                  setActiveTabKey(key)
                  setFilterObject({
                    a: key === '2' ? 'Explore All' : 'All',
                    b: '',
                    c: [],
                  })
                }}>
                <TabPane tab='Explore' key='2' />
                <TabPane tab={<span>My Hub</span>} key='1' />
              </Tabs>
              <span style={{ fontSize: '12px', color: '#7043ff', cursor: 'pointer' }} onClick={onClose}>
                Cancel
              </span>
            </div>
          </Header>
          <Layout>
            <GroupsSidebar
              myGroups={activeTabKey === '1'}
              setFilterObject={setFilterObject}
              filterObject={filterObject}
              setLoadIncrement={setLoadIncrement}
              setSelectedType={setSelectedType}
              setSearchValue={setSearchValue}
            />
            <GroupsContent
              exploreLoading={exploreLoading}
              initialLoading={initialLoading}
              selectedType={selectedType}
              groupsData={sortData(displayedGroups, 'name', true)}
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              setSelectedGroup={handleSelectGroup}
              setSelectedGroupName={setSelectedGroupName}
              myGroupsTab={activeTabKey === '1'}
              handleCustomImport={handleCustomImport}
              onLinkedinCollect={onLinkedinCollect}
              openCustomImport={openCustomImport}
              setOpenCustomImport={setOpenCustomImport}
              importData={importData}
              setImportData={setImportData}
              icpData={icpData}
              myHubLoading={myHubLoading}
              goToProgress={goToProgress}
              loadIncrement={loadIncrement}
              setLoadIncrement={setLoadIncrement}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Layout>
        </Layout>
      )}
    </Modal>
  )
}

export default ImportLeadsFromGroupsModal
