import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactFlow, {
  Background,
  EdgeChange,
  NodeChange,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from 'reactflow'
import CustomControls from '../CreateCampaign/UI/CustomControls'
import CustomNode from '../Canvas/CustomNode'
import InitialNode from './UI/InitialNode'
import PlaceholderNode from './UI/PlaceholderNode'
import { useAppDispatch, useAppSelector } from 'state'
import { v4 as uuidv4 } from 'uuid'
import {
  REMOVE_BUILDER_SETUP_DATA_IDS,
  RESET_BUILDER_SETUP_DATA,
  SET_FLAG_CAMPAIGN_ACTIONS,
  SET_OPEN_DELETE_MODAL,
  SET_SELECTED_BUILDER_NODE,
  SET_UPDATED_BUILDER_DATA,
  SET_UPDATED_BUILDER_EDGES,
  SET_UPDATED_BUILDER_NODES,
} from './Slice/CampaignBuilderSlice'
import { message } from 'antd'
import AreYouSureModal from 'common/components/General/AreYouSureModal'
import { getSenderByPlatform } from 'common/utils/userHelpers'
import { getShouldContainMessages } from 'common/utils/campaignHelpers'
import {
  defaultCustomSelect,
  filterPlaceholderNodesAndEdges,
  getGrandparentNodeId,
  getId,
  getIsCondition,
  getLayoutedElements,
  // isNodeInBranch,
  splitEdges,
} from './Utils/BuilderUtils'
import { FalseEdge, TrueEdge } from '../Canvas/CustomEdge'
import WarningNodeModal from './UI/WarningNodeModal'

const nodeTypes: any = { customNode: CustomNode, initialNode: InitialNode, placeholderNode: PlaceholderNode }
const edgeTypes: any = { trueEdge: TrueEdge, falseEdge: FalseEdge }

const initialNodes = [
  {
    id: '0',
    type: 'initialNode',
    data: { type: 'initial', parentId: '' },
    position: { x: 0, y: 0 },
  },
  {
    id: '1',
    type: 'placeholderNode',
    data: { type: 'placeholder', parentId: '0' },
    position: { x: 0, y: 150 },
  },
]

const initialEdges = [{ id: '0-1', source: '0', target: '1', type: 'smoothstep', style: { stroke: '#7043ff' } }]

const MIN_DISTANCE = 150

const BuilderFlow: React.FC = () => {
  const store = useStoreApi()
  const dispatch = useAppDispatch()
  const { fitView, screenToFlowPosition } = useReactFlow()
  const {
    builder_nodes,
    builder_edges,
    builder_setup_data,
    open_delete_modal,
    selected_builder_node,
    flag_campaign_actions,
  } = useAppSelector((state) => state.CampaignBuilder)
  const {
    prompt,
    selected_platforms_accounts,
    active_platforms,
    is_campaign_running_already,
    flow_data,
    is_review_mode,
  } = useAppSelector((state) => state.outreachAICampaign)
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])
  const { type: promptType } = prompt
  const isCustom = promptType === 'custom' || promptType === 'templates'
  const [nodes, setNodes, onNodesChange] = useNodesState(
    !isCustom && flow_data.nodes ? flow_data.nodes : builder_nodes?.length > 0 ? builder_nodes : initialNodes,
  )
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    !isCustom && flow_data.edges ? flow_data.edges : builder_edges?.length > 0 ? builder_edges : initialEdges,
  )
  const [isClose, setIsClose] = useState(false)
  const [closestNodeId, setClosestNodeId] = useState<string | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const [openWarningModal, setOpenWarningModal] = useState(false)
  const [openCampaignActionsModal, setOpenCampaignActionsModal] = useState(false)
  const onDropEventRef = useRef<any>(null)

  const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), [])

  const getClosestPlaceholderNode = useCallback(
    (position: any) => {
      const { nodeInternals } = store.getState()

      let closestNode: any = null
      let minDistance = Infinity

      nodeInternals.forEach((node) => {
        if (node.type === 'placeholderNode') {
          const dx = node.position.x + 100 - position.x
          const dy = node.position.y + 50 - position.y
          const distance = Math.sqrt(dx * dx + dy * dy)

          if (distance < minDistance && distance < MIN_DISTANCE) {
            minDistance = distance
            // node.style = { backgroundColor: 'red' }
            closestNode = node
            //   } else {
            //     setNodes((ns) =>
            //       ns.map((n) => ({
            //         ...n,
            //         style: { background: 'blue' },
            //         className: '',
            //       })),
            //     )
          }
        }
      })

      return closestNode
    },
    [store],
  )

  const addAutomaticNodes = (type: string, ids: any, prevNode: any) => {
    if (['follow_lead', 'connect_lead', 'connect_lead_with_note', 'send_message', 'ai_responder'].includes(type)) {
      // const shouldSplit = true
      // Inserting two nodes automatically
      let nextNodeType = ''
      switch (type) {
        case 'follow_lead':
          nextNodeType = 'lead_accepted_follow'
          break
        case 'connect_lead':
          nextNodeType = 'lead_accepted_connect'
          break
        case 'connect_lead_with_note':
          nextNodeType = 'lead_accepted_connect'
          break
        case 'send_message':
        case 'ai_responder':
          nextNodeType = 'is_message_replied'
          break
        default:
          break
      }

      const newIds = getId(ids[0], true)

      const { unit, amount } = defaultCustomSelect?.[nextNodeType]

      const nextNode = {
        id: ids[0],
        type: 'customNode',
        position: { x: prevNode.position.x, y: prevNode.position.y + 150 },
        data: {
          type: nextNodeType,
          parentId: prevNode.id,
          message_id: [uuidv4()],
          children: newIds,
          builder: true,
          unit: unit,
          amount: amount,
        },
      }

      const newPlaceholderNode1: any = {
        id: newIds?.[0],
        type: 'placeholderNode',
        data: { parentId: nextNode.id, isCondition: true },
        // position: { x: nextNode.position.x + (shouldSplit ? -150 : 0), y: nextNode.position.y + 150 },
      }

      const newPlaceholderNode2: any = {
        id: newIds?.[1],
        type: 'placeholderNode',
        data: { parentId: nextNode.id, isCondition: true },
        // position: { x: nextNode.position.x + 150, y: nextNode.position.y + 150 },
      }

      const updatedNodes = nodes.map((node) => (node.id === prevNode.id ? prevNode : node))
      const newNodes = [...updatedNodes, nextNode, newPlaceholderNode1, newPlaceholderNode2]?.sort((a: any, b: any) =>
        a.id.localeCompare(b.id),
      )

      const updatedEdges = [
        ...edges,
        {
          id: `${prevNode.id}-${nextNode.id}`,
          source: prevNode.id,
          target: nextNode.id,
          type: 'smoothstep',
          style: { stroke: '#7043ff' },
        },
        {
          id: `${nextNode.id}-${newPlaceholderNode1.id}`,
          source: nextNode.id,
          target: newPlaceholderNode1.id,
          // type: 'smoothstep',
          type: 'falseEdge',
          label: 'False',
          style: { stroke: '#DF667C' },
          className: 'falseEdge',
        },
        {
          id: `${nextNode.id}-${newPlaceholderNode2.id}`,
          source: nextNode.id,
          target: newPlaceholderNode2.id,
          // type: 'smoothstep',
          type: 'trueEdge',
          label: 'True',
          style: { stroke: '#2AA58E' },
          className: 'trueEdge',
        },
      ]

      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(newNodes, updatedEdges)
      setNodes(layoutedNodes)
      setEdges(layoutedEdges)

      setIsClose(false)
      setClosestNodeId(null)
      return true
    } else if (['comment_on_post', 'like_post', 'unfollow_lead', 'disconnect_lead'].includes(type)) {
      // Inserting two nodes automatically
      const nextNodeType = 'is_wait'
      const newIds = getId(ids[0], true)
      const { unit, amount } = defaultCustomSelect?.[nextNodeType]

      const nextNode = {
        id: ids[0],
        type: 'customNode',
        // position: { x: prevNode.position.x, y: prevNode.position.y + 150 },
        data: {
          type: nextNodeType,
          parentId: prevNode.id,
          message_id: [uuidv4()],
          children: newIds,
          builder: true,
          unit: unit,
          amount: amount,
        },
      }

      const newPlaceholderNode1: any = {
        id: newIds?.[0],
        type: 'placeholderNode',
        data: { parentId: nextNode.id, isCondition: true },
        // position: { x: nextNode.position.x, y: nextNode.position.y + 150 },
      }

      const updatedNodes = nodes.map((node) => (node.id === prevNode.id ? prevNode : node))
      const newNodes = [...updatedNodes, nextNode, newPlaceholderNode1]?.sort((a: any, b: any) =>
        a.id.localeCompare(b.id),
      )

      const updatedEdges = [
        ...edges,
        {
          id: `${prevNode.id}-${nextNode.id}`,
          source: prevNode.id,
          target: nextNode.id,
          type: 'smoothstep',
          style: { stroke: '#7043ff' },
        },
        {
          id: `${nextNode.id}-${newPlaceholderNode1.id}`,
          source: nextNode.id,
          target: newPlaceholderNode1.id,
          type: 'smoothstep',
          style: { stroke: '#7043ff' },
        },
      ]

      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(newNodes, updatedEdges)
      setNodes(layoutedNodes)
      setEdges(layoutedEdges)

      setIsClose(false)
      setClosestNodeId(null)
      return true
    } else {
      return false
    }
  }

  const onDrop = useCallback(
    (event: any) => {
      // event.preventDefault()

      const type =
        event.dataTransfer.getData('application/reactflow') ||
        (onDropEventRef.current ? onDropEventRef.current?.nodeType : null)

      if (type === 'comment_on_post' && truePlatforms?.includes('instagram') && !onDropEventRef.current) {
        onDropEventRef.current = {
          ...event,
          nodeType: type,
        }
        return setOpenWarningModal(true)
      }

      const isCondition = getIsCondition(type)
      const shouldSplit = splitEdges(type)
      const shouldContainMessages = getShouldContainMessages(type, truePlatforms)

      if (typeof type === 'undefined' || !type) {
        return
      }

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      })

      const closestNode = getClosestPlaceholderNode(position)

      if (!closestNode) {
        return // No placeholder node within proximity
      }

      if (closestNode?.data?.isFlagged && !onDropEventRef.current) {
        onDropEventRef.current = {
          ...event,
          nodeType: type,
          step_id: closestNode?.id,
        }
        return setOpenCampaignActionsModal(true)
      }

      onDropEventRef.current = null

      const parentNodeId = closestNode.data.parentId
      if (parentNodeId === '0' && isCondition) {
        return message.error('Please insert an Action first.')
      }

      const parentNode = nodes.find((n) => n.id === parentNodeId)
      const parentNodeType = parentNode?.data?.type

      // Find grandparent node
      const grandParentNodeId = parentNode?.data?.parentId
      const grandParentNode = nodes.find((n) => n.id === grandParentNodeId)
      const grandParentNodeType = grandParentNode?.data?.type

      if (isCondition && getIsCondition(parentNodeType)) {
        return message.error('Please insert an Action after a Condition.')
      }

      if (!isCondition && !getIsCondition(parentNodeType) && parentNodeId !== '0') {
        return message.error('Please insert a Condition after an Action.')
      }

      if (type === 'ai_responder' && grandParentNodeType !== 'send_message' && grandParentNodeType !== 'ai_responder') {
        return message.error('AI Responder must be inserted after Send Message Or AI Responder.')
      }

      if (
        type === 'ai_responder' &&
        (grandParentNodeType === 'send_message' || grandParentNodeType === 'ai_responder')
      ) {
        // Find the placeholder node for the True edge
        const trueEdgePlaceholder = edges.find((edge) => edge.source === parentNodeId && edge.label === 'True')
        if (trueEdgePlaceholder?.target !== closestNode.id) {
          return message.error('You must go in the True path to insert the AI Responder')
        }

        // Prevent inserting another AI responder on the same branch
        // const existingAiResponderOnBranch = nodes.some(
        //   (node) => node.data.type === 'ai_responder' && isNodeInBranch(node, closestNode, nodes),
        // )

        // if (existingAiResponderOnBranch) {
        //   return message.error('Only one AI Responder can be inserted per branch.')
        // }
      }

      const ids = getId(closestNode.id, shouldSplit)

      const newNode = {
        id: closestNode.id,
        type: 'customNode',
        position: closestNode.position,
        data: {
          type: type,
          parentId: closestNode.data.parentId,
          message_id: shouldContainMessages ? [uuidv4(), uuidv4(), uuidv4()] : [uuidv4()],
          children: ids,
          builder: true,
          ...(shouldContainMessages ? { sender: [], messages: [] } : {}),
        },
      }

      const auto = addAutomaticNodes(type, ids, newNode)
      if (auto) return
    },
    [screenToFlowPosition, nodes, getClosestPlaceholderNode],
  )

  const onDragOver = useCallback(
    (event: any) => {
      event.preventDefault()
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      })
      const closestNode = getClosestPlaceholderNode(position)
      setIsClose(!!closestNode)
      setClosestNodeId(closestNode ? closestNode.id : null)
    },
    [screenToFlowPosition, getClosestPlaceholderNode],
  )

  const onSelectionChange = useCallback((elements: any) => {
    const nodes = elements?.nodes
    if (nodes && nodes.length > 0 && nodes[0]?.type !== 'placeholderNode' && nodes[0]?.type !== 'initialNode') {
      const { selected, ...rest } = nodes[0]
      dispatch(SET_SELECTED_BUILDER_NODE(rest))
    } else {
      dispatch(SET_SELECTED_BUILDER_NODE({}))
    }
  }, [])

  useEffect(() => {
    // Add shadow to the closest node
    if (closestNodeId) {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === closestNodeId ? { ...node, style: { filter: 'drop-shadow(0px 0px 5px rgb(0,0,0,0.2))' } } : node,
        ),
      )
    } else {
      setNodes((nds) => nds.map((node) => ({ ...node, style: { filter: 'none' } })))
    }
  }, [closestNodeId])

  const saveBuilderData = useCallback(() => {
    const { filteredNodes, filteredEdges } = filterPlaceholderNodesAndEdges(nodes, edges)
    const allSenders = getSenderByPlatform(selected_platforms_accounts)

    // Add data to nodes
    const updatedfilteredNodes = filteredNodes?.map((node) => {
      if (builder_setup_data[node.id]) {
        return {
          ...node,
          data: {
            ...node.data,
            ...builder_setup_data[node.id],
            sender: allSenders,
          },
        }
      } else {
        return {
          ...node,
          data: {
            ...node.data,
            sender: allSenders,
          },
        }
      }
    })

    dispatch(SET_UPDATED_BUILDER_DATA({ nodes: updatedfilteredNodes, edges: filteredEdges }))

    const updatedNodes = nodes.map((node) => {
      if (builder_setup_data[node.id]) {
        return {
          ...node,
          data: {
            ...node.data,
            ...builder_setup_data[node.id],
            sender: allSenders,
          },
        }
      } else {
        return {
          ...node,
          data: {
            ...node.data,
            sender: allSenders,
          },
        }
      }
    })
    dispatch(SET_UPDATED_BUILDER_NODES(updatedNodes))
    dispatch(SET_UPDATED_BUILDER_EDGES(edges))
  }, [nodes, edges, dispatch, builder_setup_data])

  const handleResetFlow = () => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(initialNodes, initialEdges, true)
    setNodes(layoutedNodes)
    setEdges(layoutedEdges)
    setOpenModal(false)
    dispatch(RESET_BUILDER_SETUP_DATA())
    setTimeout(() => fitView(), 100)
  }

  const handleDeleteNodesAndChildren = () => {
    const nodeId = selected_builder_node?.id
    const nodeToDelete = nodes.find((node) => node.id === nodeId)
    if (!nodeToDelete) return

    // Remove from campaign_actions_flag if exists:
    const idToRemove: any = getGrandparentNodeId(nodeId, edges)
    if (flag_campaign_actions?.includes(idToRemove)) {
      dispatch(SET_FLAG_CAMPAIGN_ACTIONS(idToRemove)) // this action will remove it
    }

    const getAllChildren = (id: string) => {
      const children = nodes.filter((node) => node.data.parentId === id)
      let allChildren: any[] = [...children]
      children.forEach((child) => {
        allChildren = [...allChildren, ...getAllChildren(child.id)]
      })
      return allChildren
    }

    const allChildren = getAllChildren(nodeId)

    const nodesToDelete = [nodeToDelete, ...allChildren]
    const nodeIdsToDelete = nodesToDelete.map((node) => node.id)

    const lastEdge = edges.filter((edge: any) => edge.target === nodeId)

    const updatedNodes = nodes
      .filter((node) => !nodeIdsToDelete.includes(node.id))
      ?.sort((a: any, b: any) => a.id.localeCompare(b.id))

    const updatedEdges = edges.filter(
      (edge) => !nodeIdsToDelete.includes(edge.source) && !nodeIdsToDelete.includes(edge.target),
    )

    const newPlaceholderNode: any = {
      id: nodeId,
      type: 'placeholderNode',
      data: { parentId: nodeToDelete.data.parentId, isCondition: false },
      position: { x: nodeToDelete.position.x, y: nodeToDelete.position.y },
    }

    // setNodes((nds) => [...nds, newPlaceholderNode])
    // setEdges((eds) => [...eds, ...lastEdge])

    const nodesToLayout = [...updatedNodes, newPlaceholderNode]?.sort((a: any, b: any) => a.id.localeCompare(b.id))
    const edgesToLayout = [...updatedEdges, ...lastEdge]?.sort((a: any, b: any) => a?.target?.localeCompare(b?.target))

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodesToLayout, edgesToLayout, true)

    setNodes(layoutedNodes)
    setEdges(layoutedEdges)

    dispatch(REMOVE_BUILDER_SETUP_DATA_IDS(nodeIdsToDelete))
    dispatch(SET_SELECTED_BUILDER_NODE({}))
    dispatch(SET_OPEN_DELETE_MODAL(false))
  }

  useEffect(() => {
    if (isCustom) {
      saveBuilderData()
    }
  }, [nodes, edges, builder_setup_data])

  useEffect(() => {
    setTimeout(() => fitView(), 100)
  }, [])

  useEffect(() => {
    if (nodes.length > 0 && edges.length > 0) {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges)
      setNodes(layoutedNodes)
      setEdges(layoutedEdges)
    }
  }, [])

  const handleNodesChange = useCallback(
    (changes: NodeChange[]) => {
      const nextChanges = changes.reduce((acc, change) => {
        // Disable backspace / delete key
        if (change.type === 'remove') {
          return acc
        }
        // All other change types are just put into the next changes arr
        return [...acc, change]
      }, [] as NodeChange[])

      // Apply the changes we kept
      onNodesChange(nextChanges)
    },
    [onNodesChange],
  )

  const handleEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      const nextChanges = changes.reduce((acc, change) => {
        // Disable backspace / delete key
        if (change.type === 'remove') {
          return acc
        }
        // All other change types are just put into the next changes arr
        return [...acc, change]
      }, [] as EdgeChange[])

      // Apply the changes we kept
      onEdgesChange(nextChanges)
    },
    [onEdgesChange],
  )

  return (
    <ReactFlow
      className='flowBuilder'
      style={{ cursor: isClose ? 'copy' : 'default' }}
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodesChange={handleNodesChange}
      onEdgesChange={handleEdgesChange}
      onConnect={onConnect}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onSelectionChange={onSelectionChange}
      onLoad={() => setTimeout(() => fitView(), 0)}
      // defaultViewport={{ x: 50, y: -40, zoom: 0 }}
      proOptions={{ hideAttribution: true }}>
      <CustomControls
        {...(!is_campaign_running_already && isCustom && !is_review_mode ? { onReset: () => setOpenModal(true) } : {})}
      />
      <Background />
      {openModal && (
        <AreYouSureModal
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
          onConfirm={handleResetFlow}
          message='Are you sure you want to reset the sequence? This action is irreversible.'
          title='Reset Sequence'
        />
      )}
      {open_delete_modal && (
        <AreYouSureModal
          open={open_delete_modal}
          onClose={() => {
            dispatch(SET_OPEN_DELETE_MODAL(false))
            dispatch(SET_SELECTED_BUILDER_NODE({}))
          }}
          onConfirm={handleDeleteNodesAndChildren}
          message='This action is irreversible, and will delete all of the children of this action as well.'
          title='Are you sure you want to delete this action?'
        />
      )}
      {openWarningModal && (
        <WarningNodeModal
          open={openWarningModal}
          onCancel={() => {
            onDropEventRef.current = null
            setOpenWarningModal(false)
          }}
          onConfirm={() => {
            onDrop(onDropEventRef.current)
            onDropEventRef.current = null
            setOpenWarningModal(false)
          }}
        />
      )}
      {openCampaignActionsModal && (
        <WarningNodeModal
          open={openCampaignActionsModal}
          onCancel={() => {
            // const id = getGrandparentNodeId(onDropEventRef.current?.step_id, edges)
            // dispatch(SET_FLAG_CAMPAIGN_ACTIONS(id))
            onDrop(onDropEventRef.current)
            onDropEventRef.current = null
            setOpenCampaignActionsModal(false)
          }}
          onConfirm={() => {
            const id = getGrandparentNodeId(onDropEventRef.current?.step_id, edges)
            dispatch(SET_FLAG_CAMPAIGN_ACTIONS(id))
            onDrop(onDropEventRef.current)
            onDropEventRef.current = null
            setOpenCampaignActionsModal(false)
          }}
          type={true}
          title='Resume Sequence for Completed Leads'
          content='Choose to continue the leads in the sequence or mark them as complete, excluding them from any newly added steps.'
          confirmText='Apply'
          cancelText='Skip'
        />
      )}
    </ReactFlow>
  )
}

export default BuilderFlow
