import React, { useEffect, useState } from 'react'
import { Select, Skeleton, Tabs } from 'antd'
import styles from './ChatTabs.module.scss'
import ChatMessage from '../ChatMessage'
import chatData from '../chatData'
import ChatTab from './ChatTab'
import { useAppDispatch, useAppSelector } from 'state'
import { post } from 'common/api/axios'
import { getMessageCountsForTabs, getReadUnreadSentRepliedCounts } from '../ChatHelpers'
import {
  SET_UNREAD,
  SET_ALL_CHAT,
  SET_GROUP,
  SET_THREAD_FROM,
  SET_LIMIT_COUNT,
  SET_LOADING_LIMIT_COUNT,
} from '../state/chatSlice'
import { useLocalStorage } from 'usehooks-ts'
interface ChatTabsProps {
  loading: boolean
}

const ChatTabs: React.FC<ChatTabsProps> = ({ loading }) => {
  const [user] = useLocalStorage<any>('user', null)
  const addons = user?.addons
  const showMaxChats =
    addons?.default_max_chats && typeof addons?.default_max_chats === 'number' && addons?.default_max_chats !== 'all'
  const dispatch = useAppDispatch()
  const [selectedOption] = useState<string>('')
  const { dialogsData, selected_account, selected_chat_platform, thread_from, limit_count } = useAppSelector(
    (state) => state.chat,
  )
  const [activeItemId, setActiveItemId] = useState<number | null>(null)
  const accountsReadUnreadSentReplied = getReadUnreadSentRepliedCounts(
    dialogsData,
    selected_account,
    selected_chat_platform,
  )
  const type =
    selected_chat_platform === 'instagram' || selected_chat_platform === 'facebook' ? 'username' : 'receiver_number'
  const { read, groups, unread } = getMessageCountsForTabs(accountsReadUnreadSentReplied, selected_account, 'all', type)

  const handleChange = (e: any) => {
    switch (e) {
      case '1':
        break
      case '2':
        break
      case '3':
        post('/log', {
          action: 'Unified Inbox: User Selected Groups Chats',
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (read || unread) {
      dispatch(SET_ALL_CHAT(read + unread))
      dispatch(SET_UNREAD(unread))
    }
    if (groups) {
      dispatch(SET_GROUP(groups))
    }
  }, [read, unread, groups, dispatch, thread_from, selected_account])

  return (
    <>
      <div className={styles.ChatTabsSidebar}>
        <div className={styles.ChatTabsSidebarContent}>
          <div className={styles.ChatTabsPlatformHeader}>
            <h2>{selected_chat_platform[0]?.toLocaleUpperCase() + selected_chat_platform?.slice(1)}</h2>
            <div className={styles.flexDiv}>
              {showMaxChats && (
                <div className={`${styles['search_select']} search_select search_selectSelection`}>
                  <span className={styles.prefix_text}>Max Chats</span>
                  <Select
                    defaultValue={limit_count}
                    onChange={(value) => {
                      dispatch(SET_LIMIT_COUNT(value))
                      dispatch(SET_LOADING_LIMIT_COUNT(true))
                    }}
                    style={{
                      width: '80px',
                      padding: '0px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    size='small'
                    rootClassName={`ChatTabsPlatformHeader_select`}>
                    <Select.Option value={200}>200</Select.Option>
                    <Select.Option value={500}>500</Select.Option>
                    <Select.Option value={1000}>1000</Select.Option>
                    <Select.Option value='all'>All</Select.Option>
                  </Select>
                </div>
              )}
              <div className={`${styles['search_select']} search_select search_selectSelection`}>
                <span className={styles.prefix_text}>Chats From</span>
                <Select
                  defaultValue={thread_from}
                  onChange={(v) => {
                    dispatch(SET_THREAD_FROM(v))
                  }}
                  style={{ width: '80px', padding: '0px', display: 'flex', alignItems: 'center' }}
                  size='small'
                  rootClassName={`ChatTabsPlatformHeader_select`}>
                  <Select.Option value='all'>All</Select.Option>
                  <Select.Option value='convrt'>Convrt</Select.Option>
                </Select>
              </div>
            </div>
          </div>
          <h1>All Active Accounts</h1>
          <Tabs defaultActiveKey='1' centered className='ChatTabsSidebarContent_tabs' onChange={handleChange}>
            <Tabs.TabPane
              tab={
                <>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    All Chats
                    {loading ? (
                      <>
                        <Skeleton.Button
                          className='SkeletonButtonChatTabs'
                          active
                          size='small'
                          style={{ height: 15, width: 5, alignSelf: 'center' }}
                        />
                      </>
                    ) : (
                      <span>x{(read + unread || 0)?.toLocaleString()}</span>
                    )}
                  </div>
                </>
              }
              key='1'
              style={{ position: 'relative' }}>
              <ChatTab threadsFrom={thread_from} activeItemId={activeItemId} setActiveItemId={setActiveItemId} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    Unread
                    {loading ? (
                      <>
                        <Skeleton.Button
                          className='SkeletonButtonChatTabs'
                          active
                          size='small'
                          style={{ height: 15, width: 5, alignSelf: 'center' }}
                        />
                      </>
                    ) : (
                      <span>x{(unread || 0)?.toLocaleString()}</span>
                    )}
                  </div>
                </>
              }
              key='2'>
              <ChatTab
                threadsFrom={thread_from}
                filter={'unread'}
                activeItemId={activeItemId}
                setActiveItemId={setActiveItemId}
              />
            </Tabs.TabPane>
            {selected_chat_platform === 'telegram' && (
              <Tabs.TabPane
                tab={
                  <>
                    Groups <span>x{groups?.toLocaleString()}</span>
                  </>
                }
                key='3'>
                <ChatTab
                  threadsFrom={thread_from}
                  filter={'groups'}
                  activeItemId={activeItemId}
                  setActiveItemId={setActiveItemId}
                />
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
      </div>
      <ChatMessage selectedChannelId={selectedOption} selectedChannelMessages={chatData} />
    </>
  )
}

export default ChatTabs
