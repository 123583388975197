import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/api/baseQueryWithValidation'

export const OutreachAIApi: any = createApi({
  reducerPath: 'OutreachAIApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    createCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/createCampaign`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          //   campaign_description: params.campaign_description,
          //   status: params.status,
          messages_steps: params.messages_steps,
          prompt_params: params.prompt_params,
          platforms: params.platforms,
          steps: params.steps,
          summarytSettings: params.summarytSettings,
          generateMessages: params.generateMessages,
          custom_flow_id: params.custom_flow_id,
          //   settings: params.settings,
        },
      }),
    }),
    updateCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/updateCampaign`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          campaign_description: params.campaign_description,
          status: params.status,
          steps: params.steps,
          settings: params.settings,
          edit_mode: params.edit_mode,
        },
      }),
    }),
    updateCampaignSenders: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/updateCampaignSenders`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          senders: params.senders,
          platform: params.platform,
        },
      }),
    }),
    updateCampaignAi: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/updateCampaignAI`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          messages_steps: params.messages_steps,
          campaign_name: params.campaign_name,
          steps: params.steps,
          custom_flow_id: params.custom_flow_id,
        },
      }),
    }),
    createCampaignActions: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/createCampaignActions`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_settings: params.campaign_settings,
          platforms: params.platforms,
          stepsToCheck: params.stepsToCheck,
        },
      }),
    }),
    runCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/runCampaign`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
        },
      }),
    }),
    updateCampaignData: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/updateCampaignData`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          currentSettings: params.currentSettings,
          custom_flow_id: params.custom_flow_id,
        },
      }),
    }),
    getCampaignStats: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getCampaignStats?campaign_id=${encodeURIComponent(
          params.campaign_id,
        )}`,
        method: 'GET',
      }),
    }),

    editCampaign: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/editCampaign`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          campaign_description: params.campaign_description,
          status: params.status,
          steps: params.steps,
          settings: params.settings,
          edit_mode: params.edit_mode,
        },
      }),
    }),

    removeTempLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/removeTempLeads`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          indexes: params.indexes,
        },
      }),
    }),

    getTempLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getTempLeads?campaign_id=${encodeURIComponent(
          params.campaign_id,
        )}`,
        method: 'GET',
      }),
    }),

    /* ---------- AI ------- */
    generateWithAI: builder.query({
      query: (params: any) => ({
        url: `https://connectplus-api.convrt.io/generate`,
        method: 'POST',
        body: {
          audience: params.audience,
          pain_points: params.pain_points,
          solution: params.solution,
          hierarchy: params.hierarchy,
        },
      }),
    }),
    assistWithAI: builder.query({
      query: (params: any) => ({
        url: `https://connectplus-api.convrt.io/assist`,
        method: 'POST',
        body: {
          message: params.message,
          task: params.task,
        },
      }),
    }),
    sentimentWithAI: builder.query({
      query: (params: any) => ({
        url: `https://connectplus-api.convrt.io/sentiment`,
        method: 'POST',
        body: {
          text: params.text,
          initial_message: params.initial_message,
        },
      }),
    }),
    /* ---------- AI ------- */
    updateRecipientDetails: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/putRecipientsByCampaign`,
        method: 'PUT',
        body: { userID: params.userId, name: params.name, campaign_id: params.campaignId, field: params.field },
      }),
    }),
    getAllLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getAllLeads`,
        method: 'POST',
        body: params,
      }),
    }),
    getFilteredLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getFilteredLeads`,
        method: 'POST',
        body: params,
      }),
    }),
    refineList: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/refineList`,
        method: 'POST',
        body: params,
      }),
    }),
    getMessageSuggestionsAI: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getMessageSuggestionsAI`,
        method: 'POST',
        body: params,
      }),
    }),
    createCampaignRecipients: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/createCampaignRecipients`,
        method: 'POST',
        body: params,
      }),
    }),
    updateAiCampaignName: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/updateCampaignName`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
        },
      }),
    }),
    createCampaignRecipientsUseRemainingPoints: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/createCampaignRecipientsUseRemainingPoints`,
        method: 'POST',
        body: params,
      }),
    }),
    getGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getGroups`,
        method: 'POST',
        body: {
          refinement: params.refinement,
        },
      }),
    }),
    getUserSpecificGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getUserSpecificGroups`,
        method: 'POST',
        body: params,
      }),
    }),
    setSelectedGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/setSelectedGroups`,
        method: 'POST',
        body: params,
      }),
    }),
    getGroupDetails: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getGroupDetails`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          group: params.group,
          showNewLeads: params.showNewLeads,
        },
      }),
    }),
    importGroup: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/importGroup`,
        method: 'POST',
        body: {
          platform: params.platform,
          sourceType: params.sourceType,
          url: params.url,
          numberOfLeads: params.numberOfLeads,
          searchName: params.searchName,
          account: params.account,
        },
      }),
    }),
    removeGroup: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/removeGroup`,
        method: 'POST',
        body: {
          platform: params.platform,
          group_id: params.group_id,
        },
      }),
    }),
    refetchGroup: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/refetchGroup`,
        method: 'POST',
        body: {
          platform: params.platform,
          group_id: params.group_id,
        },
      }),
    }),
    setGroupTrackMode: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/setGroupTrackMode`,
        method: 'POST',
        body: {
          platform: params.platform,
          group_id: params.group_id,
          trackMode: params.trackMode,
        },
      }),
    }),
    addLeadsFromGroups: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/addLeadsFromGroups`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          leads: params.leads,
          sent_from: params.sent_from,
          ignoreLeadsFromOtherCampaigns: params.ignoreLeadsFromOtherCampaigns,
          ignoreOutreachedLeads: params.ignoreOutreachedLeads,
          useRemainingPoints: params.useRemainingPoints,
        },
      }),
    }),
    addCampaignAILeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/addCampaignAILeads`,
        method: 'POST',
        body: {
          campaign_id: params.campaign_id,
          campaign_name: params.campaign_name,
          companyLeads: params.companyLeads,
          AILeads: params.AILeads,
          CSVLeads: params.CSVLeads,
          sent_from: params.sent_from,
          ignoreLeadsFromOtherCampaigns: params.ignoreLeadsFromOtherCampaigns,
          ignoreOutreachedLeads: params.ignoreOutreachedLeads,
          useRemainingPoints: params.useRemainingPoints,
        },
      }),
    }),
    getMyTemplates: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getMyTemplates`,
        method: 'GET',
      }),
    }),
    saveCustomTemplate: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/saveCustomTemplate`,
        method: 'POST',
        body: {
          id: params.id,
          name: params.name,
          description: params.description,
          template: params.template,
          platforms: params.platforms,
          is_visible: params.is_visible,
        },
      }),
    }),
    saveTemplateToMarket: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/saveTemplateToMarket`,
        method: 'POST',
        body: {
          id: params.id,
          name: params.name,
          description: params.description,
          template: params.template,
          platforms: params.platforms,
          tags: params.tags,
        },
      }),
    }),
    collectLeads: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/collectLeads`,
        method: 'POST',
        body: params,
      }),
    }),
    getSavedFilters: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/getSavedFilters`,
        method: 'GET',
      }),
    }),
    saveFilter: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/saveFilter`,
        method: 'POST',
        body: {
          name: params.name,
          filter: params.filter,
        },
      }),
    }),
    deleteFilter: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign/deleteFilter`,
        method: 'POST',
        body: {
          id: params.id,
        },
      }),
    }),
    fillSuggestions: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/fillSuggestions`,
        method: 'POST',
      }),
    }),
    getExplore: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getExplore`,
        method: 'GET',
      }),
    }),
    getMyHub: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getMyHub`,
        method: 'GET',
      }),
    }),
    getExploreLoading: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/getExploreLoading`,
        method: 'GET',
      }),
    }),
    fillSuggestionsOnboarding: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/campaign-ai/fillSuggestionsOnboarding`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useLazyFillSuggestionsOnboardingQuery,
  useLazyGetExploreLoadingQuery,
  useLazyGetMyHubQuery,
  useLazyGetExploreQuery,
  useLazyFillSuggestionsQuery,
  useLazyDeleteFilterQuery,
  useLazySaveFilterQuery,
  useLazyCreateCampaignQuery,
  useLazyUpdateCampaignQuery,
  useLazyUpdateCampaignSendersQuery,
  useLazyUpdateCampaignAiQuery,
  useLazyCreateCampaignActionsQuery,
  useLazyRunCampaignQuery,
  useLazyUpdateCampaignDataQuery,
  useLazyGetCampaignStatsQuery,
  useLazyEditCampaignQuery,
  useLazyRemoveTempLeadsQuery,
  useLazyGetTempLeadsQuery,
  useLazyGenerateWithAIQuery,
  useLazyAssistWithAIQuery,
  useLazySentimentWithAIQuery,
  useLazyUpdateRecipientDetailsQuery,
  useLazyDownloadCSVQuery,
  useLazyGetAllLeadsQuery,
  useLazyGetFilteredLeadsQuery,
  useLazyRefineListQuery,
  useLazyGetMessageSuggestionsAIQuery,
  useLazyCreateCampaignRecipientsQuery,
  useLazyUpdateAiCampaignNameQuery,
  useLazyCreateCampaignRecipientsUseRemainingPointsQuery,
  useLazyGetGroupsQuery,
  useLazyGetUserSpecificGroupsQuery,
  useLazySetSelectedGroupsQuery,
  useLazyGetGroupDetailsQuery,
  useLazyImportGroupQuery,
  useLazyRemoveGroupQuery,
  useLazyRefetchGroupQuery,
  useLazySetGroupTrackModeQuery,
  useLazyAddLeadsFromGroupsQuery,
  useLazyAddCampaignAILeadsQuery,
  useLazyGetMyTemplatesQuery,
  useLazySaveCustomTemplateQuery,
  useLazySaveTemplateToMarketQuery,
  useLazyCollectLeadsQuery,
  useLazyGetSavedFiltersQuery,
} = OutreachAIApi
