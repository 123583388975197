import React, { useState, useEffect } from 'react'
import { Button, Select, InputNumber, message, Typography, Input, Table, Popover, Card } from 'antd'
import { useLazyGenerateABLinkQuery, useLazyGetAllRegistrationLinksQuery } from '../../state/api/SettingsApi'
import _ from 'lodash'
import { CopyOutlined } from '@ant-design/icons'
import styles from './GenerateABLink.module.scss'

const { Title } = Typography
const { Option } = Select

const GenerateABLink: React.FC = () => {
  const [allABLinks, setAllABLinks] = useState<any[]>([])
  const [name, setName] = useState<string>('')
  const [settings, setSettings] = useState<any[]>([])
  const [settingA, setSettingA] = useState<string | undefined>(undefined)
  const [settingB, setSettingB] = useState<string | undefined>(undefined)
  const [probability, setProbability] = useState<any>(50)
  const [generatedLink, setGeneratedLink] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [getSettings] = useLazyGetAllRegistrationLinksQuery()
  const [generateABLink] = useLazyGenerateABLinkQuery()

  // Fetch settings from the backend on component mount
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const { data } = await getSettings()
        if (data) {
          setSettings(data.allLinks || [])
          setAllABLinks(data.allABLinks || [])
        }
      } catch (error) {
        message.error('Error fetching settings')
        console.error('Error fetching settings', error)
      }
    }

    fetchSettings()
  }, [getSettings])

  const handleGenerateLink = async () => {
    if (!settingA || !settingB) {
      message.error('Please select two settings.')
      return
    }
    setLoading(true)
    const { data } = await generateABLink({
      link_a: settingA,
      link_b: settingB,
      probability_a_percentage: probability,
      name: name,
    })
    setLoading(false)
    if (data) {
      setGeneratedLink(data.url)
      const { data: settingsData } = await getSettings()
      if (settingsData) {
        setSettings(settingsData.allLinks || [])
        setAllABLinks(settingsData.allABLinks || [])
      }

      message.success('Link generated successfully!')
    } else {
      message.error('Something went wrong.')
    }
  }

  const copyLink = (link: string) => {
    navigator.clipboard.writeText(link)
    message.success('Link copied to clipboard!')
  }

  const formatOnboardingScreens = (screens: any[]) => {
    if (!screens || screens.length === 0) {
      return 'No onboarding screens available'
    }
    return screens
      .map(
        (screen) =>
          `Screen ${screen.order}: ${_.startCase(screen.screen)} (Allow Skip: ${screen.allowSkip ? 'Yes' : 'No'})`,
      )
      .join('\n')
  }

  const formatPricingSettings = (pricing: any[]) => {
    if (!pricing || pricing.length === 0) {
      return 'No pricing plans available'
    }
    return pricing.map((plan) => `Plan: ${plan.plan_name} (Default Tier: ${plan.tier_order.tier_1})`).join(', ')
  }

  // Create a helper component for the settings popover
  const LinkSettingsPopover = ({ settings }: any) => {
    const { skip_email_verification, onboarding_screens, pricing_settings, default_billing_cycle } = settings || {}

    const content = (
      <div>
        <Typography.Text>
          <strong>Skip Email Verification:</strong> {skip_email_verification ? 'Yes' : 'No'}
        </Typography.Text>
        {/* <br />
        <Typography.Text>
          <strong>Skip Outside Onboarding:</strong> {skip_outside_onboarding ? 'Yes' : 'No'}
        </Typography.Text> */}
        <br />
        <Typography.Text>
          <strong>Onboarding Screens:</strong> {formatOnboardingScreens(onboarding_screens)}
        </Typography.Text>
        <br />
        <Typography.Text>
          <strong>Pricing Settings:</strong> {formatPricingSettings(pricing_settings)}
        </Typography.Text>
        <br />
        <Typography.Text>
          <strong>Default Billing Cycle:</strong> {default_billing_cycle}
        </Typography.Text>
        <br />
      </div>
    )

    return (
      <Popover content={content} title='Link Settings' trigger='click'>
        <Button>Show Settings</Button>
      </Popover>
    )
  }

  const columns = [
    {
      title: 'A/B Link',
      key: 'actions',
      render: (_: any, record: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
            <span>{record.name}</span>
            <span style={{ cursor: 'pointer' }} onClick={() => copyLink(record.ab_url)}>
              <CopyOutlined style={{ color: '#7043ff' }} />
            </span>
          </div>
        )
      },
    },
    {
      title: 'Link A Name',
      dataIndex: 'link_a_name',
      key: 'link_a_name',
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: 'Link A Settings',
      key: 'link_a_settings',
      render: (_: any, record: any) => {
        const settings = {
          onboarding_screens: record.link_a_onboarding_screens,
          skip_email_verification: record.link_a_skip_email_verification,
          //   skip_outside_onboarding: record.link_a_skip_outside_onboarding,
          pricing_settings: record.link_a_pricing_settings,
          default_billing_cycle: record.link_a_default_billing_cycle,
        }
        return <LinkSettingsPopover settings={settings} />
      },
    },
    {
      title: 'Link B Name',
      dataIndex: 'link_b_name',
      key: 'link_b_name',
      render: (text: string) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: 'Link B Settings',
      key: 'link_b_settings',
      render: (_: any, record: any) => {
        const settings = {
          onboarding_screens: record.link_b_onboarding_screens,
          skip_email_verification: record.link_b_skip_email_verification,
          //   skip_outside_onboarding: record.link_b_skip_outside_onboarding,
          pricing_settings: record.link_b_pricing_settings,
          default_billing_cycle: record.link_b_default_billing_cycle,
        }
        return <LinkSettingsPopover settings={settings} />
      },
    },
  ]

  return (
    <div>
      <Card>
        <div className={styles.formContainer}>
          <Title level={3} className={styles.formTitle}>
            Generate New A/B Link
          </Title>

          <div className={styles.rowDiv}>
            <Title level={5} className={styles.label}>
              Name:
            </Title>
            <Input
              className={styles.input}
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Enter name for A/B test'
            />
          </div>

          <div className={styles.rowDiv}>
            <Title level={5} className={styles.label}>
              Setting A:
            </Title>
            <Select
              className={styles.input}
              value={settingA}
              onChange={(value) => setSettingA(value)}
              placeholder='Select Setting A'>
              {settings.map((setting, index) => (
                <Option key={index} value={setting.id}>
                  {setting.name} ({setting.id})
                </Option>
              ))}
            </Select>
          </div>

          <div className={styles.rowDiv}>
            <Title level={5} className={styles.label}>
              Setting B:
            </Title>
            <Select
              className={styles.input}
              value={settingB}
              onChange={(value) => setSettingB(value)}
              placeholder='Select Setting B'>
              {settings.map((setting, index) => (
                <Option key={index} value={setting.id}>
                  {setting.name} ({setting.id})
                </Option>
              ))}
            </Select>
          </div>

          <div className={styles.rowDiv}>
            <Title level={5} className={styles.label}>
              Probability for A (%):
            </Title>
            <InputNumber
              className={styles.input}
              value={probability}
              onChange={(value) => setProbability(value)}
              min={0}
              max={100}
              placeholder='Enter probability'
            />
          </div>

          <div className={styles.bottomSection}>
            <Button type='primary' onClick={handleGenerateLink} loading={loading} className={styles.generateButton}>
              Generate Link
            </Button>

            {generatedLink && (
              <div className={styles.generatedLinkContainer}>
                <Title level={5} className={styles.generatedLinkLabel} style={{ margin: 0 }}>
                  Generated Link:
                </Title>
                <Input.TextArea value={generatedLink} readOnly autoSize={{ minRows: 1, maxRows: 3 }} />
              </div>
            )}
          </div>
        </div>
      </Card>

      <Card>
        <Title level={3} style={{ margin: 0 }}>
          All A/B Links
        </Title>
        <Table
          scroll={{ y: 200 }}
          dataSource={allABLinks}
          columns={columns}
          rowKey='id'
          style={{ marginBottom: '20px' }}
        />
      </Card>
    </div>
  )
}

export default GenerateABLink
