import React, { useEffect, useState } from 'react'
import { Button, message } from 'antd'
import AIResponderDrawer from './AIResponderDrawer'
import styles from './AIResponderSetup.module.scss'
import { useLazyGetRespondersQuery, useLazySaveResponderQuery } from 'features/settings/state/api/SettingsApi'
import AIResponders from './AIResponders'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_BUILDER_SETUP_DATA } from '../Slice/CampaignBuilderSlice'

interface CategorizerProps {
  categorizer: any
  responders: any
}

const AIResponderSetup: React.FC<CategorizerProps> = ({ categorizer, responders }) => {
  const dispatch = useAppDispatch()
  const { selected_builder_node, builder_setup_data } = useAppSelector((state) => state.CampaignBuilder)
  const [showResponders, setShowResponders] = useState(selected_builder_node?.data?.responders ? true : false)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [responderToEdit, setResponderToEdit] = useState(null)
  const [loading, setLoading] = useState(false)
  const [saveResponder] = useLazySaveResponderQuery()
  const [getResponders] = useLazyGetRespondersQuery()
  const { active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])

  const showDrawer = () => {
    setIsDrawerVisible(true)
  }

  const onClose = () => {
    setIsDrawerVisible(false)
  }

  const handleSave = async (data: any, edit?: boolean) => {
    setLoading(true)
    const result = await saveResponder({
      categorizer_index: data.categorizer_index,
      type: data.type,
      text: data.text,
      platforms: truePlatforms,
      edit: edit,
      responder_id: data?.responder_id,
    })
    if (result.isSuccess) {
      await getResponders()
      message.success(`The responder for ${data.category_name} has been ${edit ? 'updated' : 'created'} successfully`)
      onClose()
      setLoading(false)
      return result.data
    } else {
      message.error('Something went wrong while creating this responder.')
    }
    setLoading(false)
  }

  // useEffect(() => {
  //   if (selected_builder_node?.data?.responders) {
  //     const { selected, ...updatedNode } = selected_builder_node
  //     dispatch(SET_SELECTED_BUILDER_NODE(updatedNode))
  //     dispatch(
  //       SET_BUILDER_SETUP_DATA({
  //         id: selected_builder_node?.id,
  //         data: {
  //           responders: selected_builder_node?.data?.responders,
  //           configuration: selected_builder_node?.data?.configuration,
  //         },
  //       }),
  //     )
  //   }
  // }, [responders])

  useEffect(() => {
    const id = selected_builder_node?.id

    if ((selected_builder_node?.data?.responders || builder_setup_data[id]) && responders?.length > 0) {
      setShowResponders(true)
      if (!builder_setup_data[id]) {
        dispatch(
          SET_BUILDER_SETUP_DATA({
            id: selected_builder_node?.id,
            data: {
              responders: selected_builder_node?.data?.responders,
              configuration: selected_builder_node?.data?.configuration,
            },
          }),
        )
      }
    } else {
      setShowResponders(false)
    }
  }, [selected_builder_node, builder_setup_data])

  return (
    <div className={styles.container}>
      {showResponders ? (
        <AIResponders
          responders={responders}
          onOpenDrawer={() => {
            setIsDrawerVisible(true)
          }}
          onOpenEditDrawer={(message: any) => {
            setIsDrawerVisible(true)
            setResponderToEdit(message)
          }}
        />
      ) : (
        <div className={styles.setupView}>
          <p className={styles.centeredText}>Add New Response</p>
          <span className={styles.subText}>
            No responses has been created yet. Click on "New Response" to create your first one.
          </span>
          <Button type='primary' onClick={showDrawer} className={styles.addButton}>
            New Response
          </Button>
        </div>
      )}
      {isDrawerVisible && (
        <AIResponderDrawer
          open={isDrawerVisible}
          onClose={onClose}
          onSave={handleSave}
          onSaveEdit={handleSave}
          categorizer={categorizer}
          loadingSave={loading}
          responderToEdit={responderToEdit}
          setResponderToEdit={setResponderToEdit}
        />
      )}
    </div>
  )
}

export default AIResponderSetup
