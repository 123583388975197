import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Collapse, Empty, Input, Select, Spin, Typography } from 'antd'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import styles from './ChatTab.module.scss'
import ChatDialog from './ChatDialog'
import { useLazyGetDialogsQuery, useLazyMarkDialogReadQuery, useLazySearchTagQuery } from '../state/api/Chat-API'
import { useAppDispatch, useAppSelector } from 'state'
import { AutoSizer, List } from 'react-virtualized'
import pin_large_icon from 'common/assets/svg/pin_large_icon.svg'
import { isArray, lowerCase } from 'lodash'
import { useDebounce } from '../Tag/Hook/useDebounce'
import TagItem from '../Tag/TagItem'
import { getAggregatedData, getMessageCounts } from '../ChatHelpers'
import { trackEvent } from 'eventConfig'
import { SET_DIALOGS_DATA } from 'common/components/Chat/state/chatSlice'
import group_worker_script from 'WebWorkers/groupWorker'
import sort_worker_script from 'WebWorkers/sortWorker'
import { groupDialogs, sortDialogs } from 'common/components/Chat/ChatUtils'

let group_worker: Worker | null = null
let sort_worker: Worker | null = null

const { Panel } = Collapse

const ROW_HEIGHT_WITH_HEADER = 210
const ROW_HEIGHT = 180
const MemoizedChatDialog = React.memo(ChatDialog)

interface ChatTabProps {
  filter?: any
  activeItemId: any
  setActiveItemId: any
  threadsFrom?: string
}

const ChatTab: React.FC<ChatTabProps> = ({ filter, activeItemId, setActiveItemId, threadsFrom }) => {
  if (window.Worker) {
    group_worker = new Worker(group_worker_script)
    sort_worker = new Worker(sort_worker_script)
  }

  const dispatch = useAppDispatch()
  const [getDialogs] = useLazyGetDialogsQuery()
  const [markDialogAsRead] = useLazyMarkDialogReadQuery()

  // Refs
  const searchResultRef = useRef(null)
  const listRef = useRef<any>(null)
  const listRefGroup = useRef<any>(null)

  const {
    dialogsData,
    selected_account,
    is_unify_finished,
    selected_chat_platform,
    all_tags_dialog_data,
    dialogs_metadata,
    thread_from,
    limit_count,
  } = useAppSelector((state) => state.chat)

  const [searchQuery, setSearchQuery] = useState<any>('')
  const debounedSearchTag = useDebounce(searchQuery, 300)
  const [searchTag] = useLazySearchTagQuery()

  const [listKey, setListKey] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [scrollPositionGroup, setScrollPositionGroup] = useState(0)
  const [loading, setLoading] = useState(false)
  const [groupBy, setGroupBy] = useState<string>('none')
  const placeholderString =
    groupBy === 'campaign_name' ? 'Campaign or Name' : groupBy === 'company' ? 'Company or Name' : 'Name'

  const [tagSearch, setTagSearch] = useState<any>([])
  const [tagSearchResult, setTagSearchResult] = useState<any>()
  const [filteredDialogs, setFilteredDialogs] = useState<any>([])
  const [searchedDialogs, setSearchedDialogs] = useState(filteredDialogs)
  const [unpinnedDialogs, setUnpinnedDialogs] = useState<any>([])
  const [allDialogs, setAllDialogs] = useState<any>([])
  const [groupedDialogs, setGroupedDialogs] = useState<any>([])
  const [isPushpinClicked, setIsPushpinClicked] = useState(false)

  const type = useMemo(
    () => (['instagram', 'facebook'].includes(selected_chat_platform) ? 'username' : 'receiver_number'),
    [selected_chat_platform],
  )

  const accountsReadUnreadSentReplied = useMemo(
    () => getAggregatedData(dialogsData, selected_account, selected_chat_platform),
    [dialogsData, selected_account, selected_chat_platform],
  )

  const { sentMessagesCount, repliedMessagesCount } = useMemo(
    () => getMessageCounts(accountsReadUnreadSentReplied, selected_account, filter, type),
    [accountsReadUnreadSentReplied, selected_account, filter, type],
  )

  const Options = useMemo(
    () => [
      { id: 1, name: 'Sent', label: sentMessagesCount || 0 },
      { id: 2, name: 'Replied', label: repliedMessagesCount || 0 },
    ],
    [sentMessagesCount, repliedMessagesCount],
  )

  const deepEqual = useCallback((obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }, [])

  const handleScroll = useCallback(({ scrollTop }: any) => {
    setScrollPosition(scrollTop)
  }, [])

  const handleScrollGroup = useCallback(({ scrollTop }: any) => {
    setScrollPositionGroup(scrollTop)
  }, [])

  const handleSearch = (value: string) => {
    setSearchQuery(value)
    const filtered = filteredDialogs?.filter((dialog: any) => {
      return dialog.title?.toLowerCase().includes(value?.toLowerCase())
    })
    setSearchedDialogs(filtered)
  }

  const handleGroupByChange = useCallback(
    (value: string) => {
      setGroupBy(value)
      setListKey((prev) => prev + 1)
      if (value !== 'none') {
        switch (value) {
          case 'tags':
            trackEvent('UNIFIED_INBOX_GROUP_BY_TAGS')
            break
          case 'meeting_book':
            trackEvent('UNIFIED_INBOX_GROUP_BY_MEETING_BOOK')
            break
          case 'categories':
            trackEvent('UNIFIED_INBOX_GROUP_BY_CATEGORIES')
            break
          case 'campaign_name':
            trackEvent('UNIFIED_INBOX_GROUP_BY_CAMPAIGN_NAME')
            break
          case 'priority':
            trackEvent('UNIFIED_INBOX_GROUP_BY_PRIORITY')
            break
          default:
            break
        }
      }
    },
    [limit_count],
  )

  const handleOptionClick = useCallback((optionName: string, id: number) => {
    if (activeItemId === id) {
      setActiveItemId(null)
    } else {
      setActiveItemId(id)
      if (id === 1) {
        // Sent
        const filtered = filteredDialogs?.filter((dialog: any) => dialog?.sent === true)
        setFilteredDialogs(filtered)
        trackEvent('UNIFIED_INBOX_FILTER_BY_SENT')
      } else {
        // Replied
        const filtered = filteredDialogs?.filter((dialog: any) => dialog?.replied === true)
        setFilteredDialogs(filtered)
        trackEvent('UNIFIED_INBOX_FILTER_BY_REPLIED')
      }
    }
  }, [])

  // eslint-disable-next-line
  const handlePushpinClick = () => {
    setIsPushpinClicked(!isPushpinClicked)
  }

  const sortDialogsWithWorker = (dialogs: any[], sortKey: string): Promise<any[]> => {
    return new Promise((resolve, reject) => {
      if (!sort_worker) {
        // Fallback if sort_worker isn't available
        console.warn('Web Worker not available, sorting in main thread')
        const sorted = sortDialogs(dialogs, sortKey)
        resolve(sorted)
        return
      }

      sort_worker.onmessage = (event) => {
        if (event.data.error) {
          reject(new Error(event.data.error))
        } else {
          resolve(event.data.data)
        }
      }

      sort_worker.onerror = (error) => {
        reject(error)
      }

      sort_worker.postMessage({ dialogs, sortKey })
    })
  }

  // const groupDialogs = (dialogs: any) => {
  //   if (!groupBy || groupBy === 'none') return { _: dialogs }

  //   const unpinned = dialogs?.filter((dialog: any) => !dialogs_metadata?.[dialog?.id]?.pin) || []
  //   const pinned = dialogs?.filter((dialog: any) => dialogs_metadata?.[dialog?.id]?.pin) || []

  //   // Nhóm theo tags
  //   if (groupBy === 'tags') {
  //     const enrichedDialogs = dialogs.map((dialog: any) => {
  //       const allTagOfDialog = all_tags_dialog_data?.find((tagData: any) => tagData.dialog_id === dialog.id)?.tags

  //       const customTags = allTagOfDialog ? allTagOfDialog?.map((tag: any) => tag.tag_name) : []
  //       return {
  //         ...dialog,
  //         customTags,
  //       }
  //     })

  //     let groupedByTags: any = {}
  //     groupedByTags = enrichedDialogs?.reduce((acc: any, dialog: any) => {
  //       dialog?.customTags?.forEach((tag: any) => {
  //         if (!acc[tag]) {
  //           acc[tag] = []
  //         }
  //         acc[tag].push(dialog)
  //       })
  //       return acc
  //     }, {})

  //     if (pinned?.length > 0) {
  //       groupedByTags.pinnedArr = pinned
  //     }
  //     return groupedByTags
  //   }

  //   if (groupBy === 'categories') {
  //     let groupedByCategories: any = {}
  //     let otherGroup: any = []

  //     dialogs.forEach((dialog: any) => {
  //       const uniqueCategories = Array.from(new Set(dialog.categories?.map((category: any) => category.category_name))) // Remove duplicate category names

  //       if (uniqueCategories.length > 0) {
  //         uniqueCategories.forEach((categoryName: any) => {
  //           if (!groupedByCategories[categoryName]) {
  //             groupedByCategories[categoryName] = []
  //           }
  //           // Prevent duplicates when adding to the category array
  //           if (!groupedByCategories[categoryName].some((existing: any) => existing.id === dialog.id)) {
  //             groupedByCategories[categoryName].push(dialog)
  //           }
  //         })
  //       } else {
  //         otherGroup.push(dialog)
  //       }
  //     })

  //     if (otherGroup.length > 0) {
  //       groupedByCategories['Other'] = otherGroup
  //     }

  //     if (pinned?.length > 0) {
  //       groupedByCategories.pinnedArr = pinned
  //     }
  //     return groupedByCategories
  //   }

  //   const grouped = unpinned.reduce((acc: any, dialog: any) => {
  //     let key = dialog[groupBy] || 'Other'
  //     if (groupBy === 'meeting_book') key = _.startCase(key)

  //     switch (key) {
  //       case 'high':
  //         key = 'High'
  //         break
  //       case 'medium':
  //         key = 'Medium'
  //         break
  //       case 'low':
  //         key = 'Low'
  //         break
  //       default:
  //         break
  //     }

  //     acc[key] = acc[key] || []
  //     acc[key].push(dialog)
  //     return acc
  //   }, {})

  //   if (pinned?.length > 0) {
  //     grouped.pinnedArr = pinned
  //   }

  //   return grouped
  // }

  const groupDialogsWithWorker = (
    dialogs: any[],
    groupBy: string,
    dialogs_metadata: any,
    all_tags_dialog_data: any,
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (!group_worker) {
        // Fallback if group_worker isn't available
        console.warn('Web Worker not available, grouping in main thread')
        const grouped = groupDialogs(dialogs, groupBy, dialogs_metadata, all_tags_dialog_data)
        resolve(grouped)
        return
      }

      group_worker.onmessage = (event) => {
        if (event.data.error) {
          reject(new Error(event.data.error))
        } else {
          resolve(event.data.data)
        }
      }

      group_worker.onerror = (error) => {
        reject(error)
      }

      group_worker.postMessage({ dialogs, groupBy, dialogs_metadata, all_tags_dialog_data })
    })
  }

  const filterDialogsByAccount = async (data: any, selected: any) => {
    if (!data || !selected || !selected.type) {
      return []
    }

    let updatedData: any = []
    if (selected.account_id === 'view_all_channels') {
      // Loop over all channels in dialogsData
      for (const channel in data) {
        const readData = data?.[channel]?.readData || []
        const unreadData = data?.[channel]?.unreadData || []
        const groupsData = data?.[channel]?.groupsData || []
        const dialogData = data?.[channel]?.dialogData || []

        let channelData =
          filter === 'read'
            ? readData
            : filter === 'unread'
            ? unreadData
            : filter === 'groups'
            ? groupsData
            : dialogData

        if (filter !== 'groups' && activeItemId === 1) {
          channelData = channelData?.filter((dialog: any) => dialog?.sent === true)
        } else if (filter !== 'groups' && activeItemId === 2) {
          channelData = channelData?.filter((dialog: any) => dialog?.replied === true)
        }
        updatedData = [...updatedData, ...channelData]
      }
    } else {
      const readData = data?.[selected.type]?.readData || []
      const unreadData = data?.[selected.type]?.unreadData || []
      const groupsData = data?.[selected.type]?.groupsData || []
      const dialogData = data?.[selected.type]?.dialogData || []
      // const dialogData = [...unreadData, ...readData]

      updatedData =
        filter === 'read' ? readData : filter === 'unread' ? unreadData : filter === 'groups' ? groupsData : dialogData
    }

    if (filter !== 'groups' && activeItemId === 1) {
      updatedData = updatedData?.filter((dialog: any) => dialog?.sent === true)
    } else if (filter !== 'groups' && activeItemId === 2) {
      updatedData = updatedData?.filter((dialog: any) => dialog?.replied === true)
    }

    switch (groupBy) {
      case 'company':
        updatedData = await sortDialogsWithWorker(updatedData, 'company')
        setListKey((prev) => prev + 1)
        break
      case 'priority':
        updatedData = await sortDialogsWithWorker(updatedData, 'priority')
        setListKey((prev) => prev + 1)
        break
      case 'campaign_name':
        updatedData = await sortDialogsWithWorker(updatedData, 'campaign_name')
        setListKey((prev) => prev + 1)
        break
      case 'meeting_book':
        updatedData = await sortDialogsWithWorker(updatedData, 'meeting_book')
        setListKey((prev) => prev + 1)
        break
      case 'tags':
        updatedData = await sortDialogsWithWorker(updatedData, 'tags')
        setListKey((prev) => prev + 1)
        break
      case 'categories':
        updatedData = await sortDialogsWithWorker(updatedData, 'categories')
        setListKey((prev) => prev + 1)
        break
      default:
        setListKey((prev) => prev + 1)
        break
    }

    if (selected.account_id === 'all_data' || selected.account_id === 'view_all_channels') {
      return updatedData
    }

    if (Array.isArray(updatedData)) {
      if (selected_chat_platform === 'telegram') {
        return updatedData?.filter((dialog: any) => dialog.receiver_number === selected.receiver_number)
      } else if (['instagram', 'facebook']?.includes(selected_chat_platform)) {
        return updatedData?.filter((dialog: any) => dialog.sender_username === selected.username)
      }
    } else {
      return []
    }
  }

  const handleOnClick = async (id: any, senderData: any, platform: string) => {
    await markDialogAsRead({
      dialog_id: id,
      senderData: senderData,
      platform: platform,
    })
    const { data } = await getDialogs({ thread_from, limit_count })
    dispatch(SET_DIALOGS_DATA(data))
  }

  const includeHeader = (index: number, list: any) => {
    const isGroupBy = list[index]?.[groupBy] !== list[index - 1]?.[groupBy]
    const isPinned = dialogs_metadata[list?.[index]?.id]?.pin !== dialogs_metadata[list?.[index - 1]?.id]?.pin
    return index === 0 || isGroupBy || isPinned
  }

  const headerSelector = (index: number, list: any[]) => {
    const currentItem = list[index]
    const prevItem = list[index - 1]

    const formatTitle = (str: string | undefined, defaultValue: string) => {
      return str ? str[0].toUpperCase() + str.slice(1) : defaultValue
    }

    // Check for pin header
    if (index === 0 && dialogs_metadata?.[currentItem?.id]?.pin) {
      return (
        <div className={styles.pinedChats}>
          <h3 className={styles.chat_box_title}>Pin</h3>
          <img src={pin_large_icon} alt='' />
        </div>
      )
    }

    // Check for group header changes
    if (!dialogs_metadata?.[currentItem?.id]?.pin && currentItem[groupBy] !== prevItem?.[groupBy]) {
      let title = ''
      switch (groupBy) {
        case 'priority':
          title = formatTitle(currentItem.priority, 'New')
          break
        case 'meeting_book':
          title = formatTitle(currentItem.meeting_book, 'Meeting Book')
          break
        case 'company':
          title = formatTitle(currentItem.company, 'Company Name')
          break
        case 'campaign_name':
          if (
            currentItem.campaign_name === prevItem?.campaign_name &&
            dialogs_metadata?.[currentItem?.id]?.pin !== dialogs_metadata?.[prevItem?.id]?.pin
          ) {
            break
          }
          title = formatTitle(currentItem.campaign_name, 'Campaign Name')
          break
        default:
          break
      }
      return title ? (
        <div className={styles.pinedChats}>
          <h3 className={styles.chat_box_title}>{title}</h3>
        </div>
      ) : null
    }

    // Check if it's the first unpinned item
    if (currentItem === unpinnedDialogs[0]) {
      if (!dialogs_metadata[currentItem.id]?.pin && currentItem[groupBy] === prevItem?.[groupBy]) {
        let title = ''
        switch (groupBy) {
          case 'priority':
            title = formatTitle(currentItem.priority, 'New')
            break
          case 'meeting_book':
            title = formatTitle(currentItem.meeting_book, 'Meeting Book')
            break
          case 'company':
            title = formatTitle(currentItem.company, 'Company Name')
            break
          case 'campaign_name':
            if (
              currentItem.campaign_name === prevItem?.campaign_name &&
              dialogs_metadata?.[currentItem.id]?.pin !== dialogs_metadata?.[prevItem?.id]?.pin
            ) {
              break
            }
            title = formatTitle(currentItem.campaign_name, 'Campaign Name')
            break
          default:
            break
        }
        return title ? (
          <div className={styles.pinedChats}>
            <h3 className={styles.chat_box_title}>{title}</h3>
          </div>
        ) : (
          <div className={styles.pinedChats}>
            <h3 className={styles.chat_box_title}>New</h3>
          </div>
        )
      } else {
        return (
          <div className={styles.pinedChats}>
            <h3 className={styles.chat_box_title}>New</h3>
          </div>
        )
      }
    }

    if (index === 0) {
      return (
        <div className={styles.pinedChats}>
          <h3 className={styles.chat_box_title}>New</h3>
        </div>
      )
    }

    return null
  }

  const checkDialogContainTag = (dialogId: any, searchQuery: string[]) => {
    if (!dialogId) return false

    const allTagOfDialog = all_tags_dialog_data?.find((dialog: any) => dialog.dialog_id === dialogId)?.['tags']
    const allTagNameOfDialog = allTagOfDialog?.map((tagDL: any) => tagDL.tag_name)
    let result = false
    searchQuery.forEach((item) => {
      result = allTagNameOfDialog?.some((tagName: any) => lowerCase(tagName) === lowerCase(item))
    })
    return result
  }

  const fetSearchResult = async () => {
    setLoading(true)
    try {
      const res = await searchTag(debounedSearchTag)
      if (res) {
        setTagSearchResult(res.data.response)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleRemoveTagSearch = (tag: string) => {
    const currentIndexOfTag = tagSearch.findIndex((t: string) => tag === t)

    setTagSearch((pre: any) => {
      return [...pre.slice(0, currentIndexOfTag), ...pre.slice(currentIndexOfTag + 1)]
    })
  }

  useEffect(() => {
    const getChatdata = async () => {
      const { data } = await getDialogs({ thread_from, limit_count })
      dispatch(SET_DIALOGS_DATA(data))
    }
    if (is_unify_finished) {
      getChatdata()
    }
  }, [is_unify_finished])

  useEffect(() => {
    const updateDialogs = async () => {
      if (dialogsData && selected_account) {
        const dialogs = await filterDialogsByAccount(dialogsData, selected_account)
        let filtered
        switch (groupBy) {
          case 'company':
            // filtered = dialogs?.filter((dialog: any) =>
            //   dialog.company?.toLowerCase()?.includes(searchQuery?.toLowerCase()),
            // )
            filtered = dialogs?.filter((dialog: any) => {
              const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
              return tagSearch?.length > 0
                ? isDialogContainTag
                : dialog.company?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
                    dialog.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            })
            break
          case 'priority':
            // filtered = dialogs?.filter((dialog: any) => dialog.title?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
            filtered = dialogs?.filter((dialog: any) => {
              const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
              return tagSearch?.length > 0
                ? isDialogContainTag
                : dialog.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            })
            break
          case 'meeting_book':
            filtered = dialogs?.filter((dialog: any) => {
              const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
              return tagSearch?.length > 0
                ? isDialogContainTag
                : dialog.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            })
            break
          case 'campaign_name':
            // filtered = dialogs?.filter((dialog: any) =>
            //   dialog.campaign_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()),
            // )
            filtered = dialogs?.filter((dialog: any) => {
              const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
              return tagSearch.length > 0
                ? isDialogContainTag
                : dialog.campaign_name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
                    dialog.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            })
            break
          case 'tags':
            filtered = dialogs?.filter((dialog: any) => {
              const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
              return tagSearch.length > 0
                ? isDialogContainTag
                : dialog.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            })
            break
          case 'categories':
            filtered = dialogs?.filter((dialog: any) => {
              const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
              const isCategoryMatch = dialog.categories?.some((category: any) =>
                category?.category_name?.toLowerCase().includes(searchQuery?.toLowerCase()),
              )
              return tagSearch.length > 0
                ? isDialogContainTag
                : isCategoryMatch || dialog.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            })
            break
          default:
            // filtered = dialogs?.filter((dialog: any) => dialog.title?.toLowerCase().includes(searchQuery?.toLowerCase()))
            filtered = dialogs?.filter((dialog: any) => {
              const isDialogContainTag = checkDialogContainTag(dialog?.id, tagSearch)
              return tagSearch.length > 0
                ? isDialogContainTag
                : lowerCase(dialog?.title).includes(lowerCase(searchQuery))
            })
            break
        }
        if (threadsFrom === 'convrt') {
          setSearchedDialogs(
            [...filtered].filter(
              (dialog: any) => dialog.campaign_name !== null && dialog.campaign_name !== '' && dialog.campaign_name,
            ),
          )
          setFilteredDialogs(
            [...dialogs].filter(
              (dialog: any) => dialog.campaign_name !== null && dialog.campaign_name !== '' && dialog.campaign_name,
            ),
          )
        } else {
          setSearchedDialogs(filtered)
          setFilteredDialogs(dialogs)
        }
      }
    }
    updateDialogs()
  }, [dialogsData, selected_account, activeItemId, searchQuery, groupBy, tagSearch, dialogs_metadata, threadsFrom])

  useEffect(() => {
    const getGrouped = async () => {
      if (!groupBy || groupBy === 'none') {
        setGroupedDialogs(null)
      } else {
        if (activeItemId === 1) {
          const filtered = searchedDialogs?.filter((dialog: any) => dialog?.sent === true)
          const grouped = await groupDialogsWithWorker(filtered, groupBy, dialogs_metadata, all_tags_dialog_data)
          setGroupedDialogs(grouped)
        } else if (activeItemId === 2) {
          const filtered = searchedDialogs?.filter((dialog: any) => dialog?.replied === true)
          const grouped = await groupDialogsWithWorker(filtered, groupBy, dialogs_metadata, all_tags_dialog_data)
          setGroupedDialogs(grouped)
        } else {
          const grouped = await groupDialogsWithWorker(searchedDialogs, groupBy, dialogs_metadata, all_tags_dialog_data)
          setGroupedDialogs(grouped)
        }
      }
    }
    getGrouped()
  }, [groupBy, searchedDialogs, searchQuery, activeItemId])

  useEffect(() => {
    const pinned = searchedDialogs?.filter((dialog: any) => dialogs_metadata?.[dialog?.id]?.pin) || []
    const unpinned = searchedDialogs?.filter((dialog: any) => !dialogs_metadata?.[dialog?.id]?.pin) || []
    setUnpinnedDialogs(unpinned)
    const merged = [...pinned, ...unpinned]

    if (listRef.current) {
      listRef.current.scrollToPosition(scrollPosition)
    }

    if (!deepEqual(merged, allDialogs)) {
      if (threadsFrom === 'convrt') {
        setAllDialogs(
          [...merged].filter(
            (dialog: any) => dialog.campaign_name !== null && dialog.campaign_name !== '' && dialog.campaign_name,
          ),
        )
      } else {
        setAllDialogs([...merged])
      }
      setListKey((prev) => prev + 1)
    }
  }, [searchedDialogs, dialogs_metadata])

  useEffect(() => {
    if (debounedSearchTag) {
      fetSearchResult()
    }
  }, [debounedSearchTag])

  useEffect(() => {
    if (!searchQuery) setTagSearchResult([])
  }, [searchQuery])

  // Initialize worker in useEffect to avoid SSR issues
  useEffect(() => {
    if (window.Worker) {
      group_worker = new Worker(group_worker_script)
      sort_worker = new Worker(sort_worker_script)
    }

    // Cleanup worker on component unmount
    return () => {
      if (group_worker) {
        group_worker.terminate()
        group_worker = null
      }
      if (sort_worker) {
        sort_worker.terminate()
        sort_worker = null
      }
    }
  }, [])

  return (
    <>
      <div className={styles.search}>
        <div className={styles['search__input']}>
          <Input
            size='large'
            placeholder={`Search by ${placeholderString}`}
            prefix={<SearchOutlined />}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div className={styles['search__input__result__list']}>
            {tagSearch.map((tag: string, index: number) => (
              <TagItem
                title={tag}
                key={`${tag} ${index}`}
                onClose={() => {
                  handleRemoveTagSearch(tag)
                }}
              />
            ))}
          </div>

          {searchQuery && (
            <div ref={searchResultRef}>
              {loading && (
                <Spin
                  style={{ position: 'absolute', top: '12px', right: '12px', zIndex: 10000 }}
                  indicator={<LoadingOutlined style={{ fontSize: 16, marginBottom: '16px' }} spin />}
                />
              )}
              {isArray(tagSearchResult) && tagSearchResult.length > 0 && (
                <div className={styles['search__input__result']}>
                  <Typography className={styles['search__input__result__title']}>Tags</Typography>
                  <div className={styles['search__input__result__list']}>
                    {tagSearchResult.map((tag) => (
                      <div
                        key={tag.id}
                        onClick={() => {
                          const isExistTagSearch = tagSearch.some((t: string) => tag.tag_name === t)
                          if (isExistTagSearch) return
                          setTagSearch((pre: any) => [...pre, tag.tag_name])
                          setSearchQuery('')
                        }}>
                        <TagItem title={tag?.tag_name} showClose={false} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`${styles['search_select']} search_select`}>
          <span className={styles.prefix_text}>Group By</span>
          <Select defaultValue='none' onChange={handleGroupByChange} style={{ width: '105px', padding: '3px 0px' }}>
            <Select.Option value='none'>None</Select.Option>
            <Select.Option value='categories'>Categories</Select.Option>
            {filter !== 'groups' && <Select.Option value='campaign_name'>Campaign</Select.Option>}
            {filter !== 'groups' && <Select.Option value='company'>Company</Select.Option>}
            <Select.Option value='priority'>Priority</Select.Option>
            <Select.Option value='meeting_book'>Status</Select.Option>
            <Select.Option value='tags'>Tags</Select.Option>
          </Select>
        </div>
      </div>

      <div className={`${styles['optionsTiles']} optionsTiles`}>
        {filter !== 'groups' &&
          Options.map((item) => (
            <div
              key={item.id}
              className={
                activeItemId === item.id
                  ? `${styles['optionsTiles__Content']} optionsTiles__Content activeOptionsTiles`
                  : `${styles['optionsTiles__Content']} optionsTiles__Content`
              }
              onClick={() => handleOptionClick(item.name, item.id)}>
              <p>{item.name}</p>
              <h1>{item.label}</h1>
            </div>
          ))}
      </div>
      {allDialogs.length === 0 ? (
        <div className={styles.emptyState}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={`No active chats ${selected_account?.username ? `for ${selected_account.username}` : ''}`}
          />
        </div>
      ) : (
        <div style={{ flex: '1 1 auto', minHeight: `calc(100vh - 330px + ${filter === 'groups' ? 70 : 0}px)` }}>
          <AutoSizer>
            {({ width, height }) => {
              const count = groupedDialogs ? 1 : allDialogs.length
              if (groupedDialogs) {
                const sortedGroups = Object.entries(groupedDialogs).sort((a: any, b: any) => {
                  if (a[0] === 'pinnedArr') return -1
                  if (b[0] === 'pinnedArr') return 1

                  if (a[0] === 'Other') return 1
                  if (b[0] === 'Other') return -1

                  if (groupBy === 'tags') {
                    return b[1]?.length - a[1]?.length
                  } else {
                    return a[0].localeCompare(b[0])
                  }
                })

                return (
                  <Collapse
                    bordered={false}
                    style={{ width: width + 15, overflowY: 'scroll', height: height + 35 }}
                    accordion
                    className='groupedDialogs'
                    expandIconPosition='right'
                    defaultActiveKey={['0']}>
                    {sortedGroups.map(([group, dialogs]: any, index) => {
                      const sortedDialogs = dialogs.sort((a: any, b: any) => {
                        const dateA = new Date(a?.sent_at)
                        const dateB = new Date(b?.sent_at)
                        return +dateB - +dateA
                      })

                      const header =
                        group === 'pinnedArr' ? (
                          <div className={styles.pinedChats} style={{ margin: 0 }}>
                            <span className={styles.chat_box_title}>Pin</span>
                            <img src={pin_large_icon} alt='' />
                          </div>
                        ) : groupBy === 'tags' ? (
                          <div className={styles.tagHeader}>
                            <TagItem title={group} />
                            <span style={{ fontWeight: 600 }}>{dialogs?.length}</span>
                          </div>
                        ) : (
                          <div className={styles.tagHeader}>
                            <span style={{ fontWeight: 600, fontSize: '16px' }}>{group}</span>
                            <span style={{ fontWeight: 600 }}>{dialogs?.length}</span>
                          </div>
                        )

                      return (
                        <Panel header={header} key={index} style={{ minWidth: '490px' }}>
                          <List
                            ref={listRefGroup}
                            onScroll={handleScrollGroup}
                            scrollTop={scrollPositionGroup}
                            key={listKey}
                            className={styles.reactVirtualizer}
                            width={width + 7}
                            height={Math.min(540, ROW_HEIGHT * dialogs?.length)}
                            rowCount={dialogs?.length}
                            // scrollTop={scrollPosition}
                            // scrollToIndex={20}
                            rowHeight={ROW_HEIGHT}
                            rowRenderer={({ index, key, style, parent }) => {
                              const item = groupBy === 'tags' ? sortedDialogs[index] : dialogs[index]
                              let identifierKey = ''
                              switch (item.platform) {
                                case 'telegram':
                                  identifierKey = 'receiver_number'
                                  break
                                case 'instagram':
                                  identifierKey = 'sender_username'
                                  break
                                case 'facebook':
                                  identifierKey = 'sender_username'
                                  break
                                default:
                                  identifierKey = 'receiver_number'
                              }
                              const senderData = item[identifierKey]
                              const tags = JSON.parse(item.tags || '[]')

                              const pin = dialogs_metadata?.[item?.id]?.pin
                              const priority = dialogs_metadata?.[item?.id]?.priority

                              return (
                                <div key={key} style={style}>
                                  {/* {headerSelector(index, allDialogs)} */}
                                  <MemoizedChatDialog
                                    item={item}
                                    recipientId={item.id}
                                    recipientName={item.title || item.recipient_username}
                                    senderData={senderData}
                                    platform={item.platform}
                                    timeAgo={item.sent_at}
                                    additionalTags={tags}
                                    latestMessage={item.latest_message}
                                    photo={item.photo}
                                    unread_count={item.unread_count}
                                    pin={pin || item.pin}
                                    priority={priority || item.priority}
                                    onClick={handleOnClick}
                                  />
                                </div>
                              )
                            }}
                          />
                        </Panel>
                      )
                    })}
                  </Collapse>
                )
              } else {
                return (
                  <>
                    <List
                      ref={listRef}
                      onScroll={handleScroll}
                      scrollTop={scrollPosition}
                      key={listKey}
                      className={styles.reactVirtualizer}
                      width={width + 7}
                      height={height}
                      rowCount={count}
                      // rowHeight={}
                      rowHeight={({ index }) => {
                        if (groupedDialogs) {
                          return 1000
                        } else {
                          return includeHeader(index, allDialogs) ? ROW_HEIGHT_WITH_HEADER : ROW_HEIGHT
                        }
                      }}
                      rowRenderer={({ index, key, style, parent }) => {
                        const item = allDialogs[index]
                        let identifierKey = ''
                        switch (item.platform) {
                          case 'telegram':
                            identifierKey = 'receiver_number'
                            break
                          case 'instagram':
                            identifierKey = 'sender_username' // Change later
                            break
                          case 'facebook':
                            identifierKey = 'sender_username' // Change later
                            break
                          default:
                            identifierKey = 'receiver_number'
                        }
                        const senderData = item[identifierKey]
                        const tags = JSON.parse(item.tags || '[]')

                        const pin = dialogs_metadata?.[item?.id]?.pin
                        const priority = dialogs_metadata?.[item?.id]?.priority

                        return (
                          <div key={key} style={style}>
                            {headerSelector(index, allDialogs)}
                            <MemoizedChatDialog
                              item={item}
                              recipientId={item.id}
                              recipientName={item.title || item.recipient_username}
                              senderData={senderData}
                              platform={item.platform}
                              timeAgo={item.sent_at}
                              additionalTags={tags}
                              latestMessage={item.latest_message}
                              photo={item.photo}
                              unread_count={item.unread_count}
                              pin={pin || item.pin}
                              priority={priority || item.priority}
                              onClick={handleOnClick}
                            />
                          </div>
                        )
                      }}
                    />
                  </>
                )
              }
            }}
          </AutoSizer>
        </div>
      )}
    </>
  )
}

export default ChatTab
