import _ from 'lodash'

const priorityOrder: any = { high: 1, medium: 2, low: 3, '': 4, null: 4 }

export const sortDialogs = (dialogs: any[], sortKey: string) => {
  const dialogsCopy = [...dialogs]

  dialogsCopy.sort((a: any, b: any) => {
    if (sortKey === 'priority') {
      const valueA = priorityOrder[a[sortKey]] || priorityOrder['']
      const valueB = priorityOrder[b[sortKey]] || priorityOrder['']
      return valueA - valueB
    } else if (sortKey === 'categories') {
      const categoryA = a.categories?.[0]?.category_name || ''
      const categoryB = b.categories?.[0]?.category_name || ''
      return categoryA.localeCompare(categoryB)
    } else {
      const valueA = a[sortKey] === null ? '\uFFFF' : a[sortKey] || ''
      const valueB = b[sortKey] === null ? '\uFFFF' : b[sortKey] || ''
      return valueA.localeCompare(valueB)
    }
  })

  return dialogsCopy
}

export const groupDialogs = (dialogs: any[], groupBy: string, dialogs_metadata: any, all_tags_dialog_data: any) => {
  if (!groupBy || groupBy === 'none') return { _: dialogs }

  const unpinned = dialogs?.filter((dialog: any) => !dialogs_metadata?.[dialog?.id]?.pin) || []
  const pinned = dialogs?.filter((dialog: any) => dialogs_metadata?.[dialog?.id]?.pin) || []

  // Nhóm theo tags
  if (groupBy === 'tags') {
    const enrichedDialogs = dialogs.map((dialog: any) => {
      const allTagOfDialog = all_tags_dialog_data?.find((tagData: any) => tagData.dialog_id === dialog.id)?.tags

      const customTags = allTagOfDialog ? allTagOfDialog?.map((tag: any) => tag.tag_name) : []
      return {
        ...dialog,
        customTags,
      }
    })

    let groupedByTags: any = {}
    groupedByTags = enrichedDialogs?.reduce((acc: any, dialog: any) => {
      dialog?.customTags?.forEach((tag: any) => {
        if (!acc[tag]) {
          acc[tag] = []
        }
        acc[tag].push(dialog)
      })
      return acc
    }, {})

    if (pinned?.length > 0) {
      groupedByTags.pinnedArr = pinned
    }
    return groupedByTags
  }

  if (groupBy === 'categories') {
    let groupedByCategories: any = {}
    let otherGroup: any = []

    dialogs.forEach((dialog: any) => {
      const uniqueCategories = Array.from(new Set(dialog.categories?.map((category: any) => category.category_name))) // Remove duplicate category names

      if (uniqueCategories.length > 0) {
        uniqueCategories.forEach((categoryName: any) => {
          if (!groupedByCategories[categoryName]) {
            groupedByCategories[categoryName] = []
          }
          // Prevent duplicates when adding to the category array
          if (!groupedByCategories[categoryName].some((existing: any) => existing.id === dialog.id)) {
            groupedByCategories[categoryName].push(dialog)
          }
        })
      } else {
        otherGroup.push(dialog)
      }
    })

    if (otherGroup.length > 0) {
      groupedByCategories['Other'] = otherGroup
    }

    if (pinned?.length > 0) {
      groupedByCategories.pinnedArr = pinned
    }
    return groupedByCategories
  }

  const grouped = unpinned.reduce((acc: any, dialog: any) => {
    let key = dialog[groupBy] || 'Other'
    if (groupBy === 'meeting_book') key = _.startCase(key)

    switch (key) {
      case 'high':
        key = 'High'
        break
      case 'medium':
        key = 'Medium'
        break
      case 'low':
        key = 'Low'
        break
      default:
        break
    }

    acc[key] = acc[key] || []
    acc[key].push(dialog)
    return acc
  }, {})

  if (pinned?.length > 0) {
    grouped.pinnedArr = pinned
  }

  return grouped
}
