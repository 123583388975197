import React, { useState, useMemo, useEffect } from 'react'
import styles from './CustomAvatar.module.scss'

type AvatarSize = 'small' | 'medium' | 'large' | 'xl'

interface CustomAvatarProps {
  src: string
  name: string
  size?: AvatarSize
  style?: React.CSSProperties
  triggerRerender?: any // This prop triggers re-rendering
}

const sizes = {
  small: 24,
  medium: 48,
  large: 64,
  xl: 80,
}

const getColorClass = (initials: string) => {
  const colors: any = {
    '#f56a00': styles.colorF56a00,
    '#7265e6': styles.color7265e6,
    '#ffbf00': styles.colorFfbf00,
    '#00a2ae': styles.color00a2ae,
    '#f44336': styles.colorF44336,
    '#009688': styles.color009688,
  }
  const colorKeys = Object.keys(colors)
  const index = initials.charCodeAt(0) % colorKeys.length
  const idx: any = colorKeys[index]
  return colors[idx]
}

const CustomAvatar: React.FC<CustomAvatarProps> = ({ src, name, size = 'medium', style, triggerRerender }) => {
  const [hasError, setHasError] = useState(false)
  const [key, setKey] = useState(0) // Key to force re-render

  const initials = useMemo(
    () =>
      name
        .split(' ')
        .map((n) => n[0])
        .join(''),
    [name],
  )

  const colorClass = useMemo(() => getColorClass(initials), [initials])

  const sizeInPx = sizes[size]

  useEffect(() => {
    if (triggerRerender) {
      setKey((prevKey) => prevKey + 1)
      setHasError(false)
    }
  }, [triggerRerender])

  return (
    <div
      key={key} // Use key to force re-render
      className={`${styles.avatar} ${colorClass}`}
      style={{ width: sizeInPx, height: sizeInPx, fontSize: sizeInPx / 2, ...style }}>
      <span className={styles.initials}>{initials}</span>
      {!hasError && <img src={src} alt='' onError={() => setHasError(true)} className={styles.image} />}
    </div>
  )
}

export default React.memo(CustomAvatar)
