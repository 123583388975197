import {
  Button,
  Divider,
  Input,
  Layout,
  message,
  Modal,
  Popover,
  Select,
  Slider,
  SliderSingleProps,
  Tooltip,
} from 'antd'
import {
  useLazyGetExploreQuery,
  useLazyGetMyHubQuery,
  useLazyImportGroupQuery,
} from 'common/components/OutreachAI/state/api/OutreachAI-API'
import { SET_INITIAL_GROUP_DATA } from 'common/components/OutreachAI/state/outreachAICampaignSlice'
import { trackEvent } from 'eventConfig'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { useLocalStorage } from 'usehooks-ts'
import styles from './ImportLeadsOptionsModal.module.scss'
import points_icon from 'common/assets/svg/points_icon.svg'
import validator from 'validator'
import instagram_popover from 'common/assets/png/InstagramPopover.png'
import facebook_popover from 'common/assets/png/FacebookPopover.png'

import crown from 'common/assets/svg/crown_icon.svg'
import instagram from 'common/assets/svg/insta_icon.svg'
import linkedin from 'common/assets/svg/linked_icon.svg'
import facebook from 'common/assets/svg/facebook_icon.svg'

import linkedin_sales_nav from 'common/assets/png/linkedin_sales_nav.png'
import linkedin_search from 'common/assets/png/linkedin_search.png'
import { InfoCircleOutlined } from '@ant-design/icons'
const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => `${value?.toLocaleString()}`

const { Header, Content } = Layout
interface ImportLeadsOptionsModalProps {
  open: boolean
  onClose: () => void
  pointsStore: any
  importData: any
  goToProgress: () => void
}

const { Option } = Select
export const ImportLeadsOptionsModal: React.FC<ImportLeadsOptionsModalProps> = ({
  open,
  onClose,
  pointsStore,
  importData,
  goToProgress,
}) => {
  const dispatch = useAppDispatch()
  const [user] = useLocalStorage<any>('user', null)
  const { addons, linkedin: linkedinAccounts, facebook: facebookAccounts, instagram: instagramAccounts } = user
  const { importLinkedin, importSalesNav } = addons || {}
  const linkedinOptions = linkedinAccounts?.map((account: any) => account.email)
  const facebookOptions = facebookAccounts?.map((account: any) => account.username)
  const instagramOptions = instagramAccounts?.map((account: any) => account.username)
  const { active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const [importGroup] = useLazyImportGroupQuery()
  const [getExplore] = useLazyGetExploreQuery()
  const [getMyHub] = useLazyGetMyHubQuery()
  const [loading, setLoading] = useState(false)
  const [sourceType, setSourceType] = useState<any>(importData?.sourceType || null)
  const truePlatformsArray = Object.entries(active_platforms)
    ?.filter(([key, value]) => value)
    ?.map(([key, value]) => key)
  const [platform, setPlatform] = useState<any>(importData?.plaform || truePlatformsArray?.[0])
  const [account, setAccount] = useState<any>(
    platform === 'linkedin'
      ? linkedinOptions?.[0] || null
      : platform === 'facebook'
      ? facebookOptions?.[0] || null
      : null,
  )
  const [url, setUrl] = useState(importData?.url || '')
  const [hashtag, setHashtag] = useState('')
  const [searchName, setSearchName] = useState('')
  const [numberOfLeads, setNumberOfLeads] = useState(0)
  const instagramMaxImport =
    sourceType === 'hashtag'
      ? addons?.max_import_hashtag_instagram_leads
      : addons?.max_import_group_instagram_leads || 1000
  const max_import_group_leads =
    platform === 'instagram'
      ? instagramMaxImport
      : platform === 'facebook'
      ? addons?.max_import_group_facebook_leads || 1000
      : platform === 'linkedin'
      ? addons?.max_import_group_linkedin_leads || 200
      : addons?.max_import_group_leads || 1000

  const cost = pointsStore?.pointsStore['collect'] || 1
  const totalCost = Math.trunc(numberOfLeads * cost)?.toLocaleString()

  useEffect(() => {
    if (importData) {
      setPlatform(importData?.platform)
      setSourceType(importData?.source_type)
      setUrl(importData?.url)
    }
  }, [importData])

  const handleSelectChange = (value: any) => {
    setPlatform(value)
    setSourceType(null)
    setAccount(null)
    setUrl('')
    setSearchName('')
    setNumberOfLeads(0)
  }

  const handleSelectSourceTypeChange = (value: any) => {
    setSourceType(value.key)
    setNumberOfLeads(0)
  }

  const handleSelectAccountChange = (value: any) => {
    setAccount(value.key)
  }

  const handleInputChange = (e: any) => {
    const value = e.target.value
    setUrl(value)
  }

  const handleInputNameChange = (e: any) => {
    const value = e.target.value
    setSearchName(value)
  }

  const handleSliderChange = (e: any) => {
    setNumberOfLeads(e)
  }

  const validateUrl = (value: string) => {
    if (validator.isURL(value) && value?.includes(platform)) {
      return true
    } else {
      return false
    }
  }

  const getGroupNameFromUrl = (url: string) => {
    try {
      const parsedUrl = new URL(url)
      const pathname = parsedUrl.pathname
      const segments = pathname.split('/').filter(Boolean)
      return segments[segments.length - 1]
    } catch (error) {
      console.error('Invalid URL:', error)
      return null
    }
  }

  const handleImportGroup = async () => {
    try {
      setLoading(true)

      if (!sourceType) {
        message.error('Please select a source type to proceed.')
        return setLoading(false)
      }
      if (sourceType === 'hashtag' && (!hashtag || hashtag.replace(' ', '').length === 0)) {
        message.error('Please insert a valid hashtag.')
        setLoading(false)
        return
      }

      let parsedUrl
      if (sourceType !== 'hashtag') {
        const isValidUrl = validateUrl(url)
        if (!isValidUrl && platform !== 'instagram') {
          message.error('Please insert a valid url that matches the platform you selected.')
          return setLoading(false)
        }
        parsedUrl = platform === 'instagram' || platform === 'linkedin' ? url : getGroupNameFromUrl(url)
      } else {
        parsedUrl = hashtag
      }
      const { data, error } = await importGroup({
        platform: platform,
        sourceType: sourceType,
        url: parsedUrl,
        numberOfLeads: numberOfLeads,
        ...(platform === 'linkedin' && { searchName: searchName }),
        ...((platform === 'linkedin' || platform === 'facebook') && { account: account }),
      })

      trackEvent('IMPORT_CUSTOM_LEADS', {
        platform: platform,
        sourceType: sourceType,
        url: parsedUrl,
        numberOfLeads: numberOfLeads,
        ...(platform === 'linkedin' && { searchName: searchName }),
        ...(platform === 'linkedin' && { account: account }),
        status: data?.status,
      })
      if (data?.status && !error) {
        message.success(`${sourceType === 'hashtag' ? 'Hashtag' : 'Suggestion'} imported successfully!`)
        // setPlatform(null)
        setUrl('')
        // getGroups
        const { data } = await getExplore({})
        const { data: myHubData } = await getMyHub({})
        dispatch(
          SET_INITIAL_GROUP_DATA({
            ...data,
            ...myHubData,
          }),
        )
        goToProgress()
        onClose()
      } else if (error?.data?.status === false) {
        message.error(error?.data?.message)
      } else if (error?.data?.status === 'points') {
        message.error(error?.data?.message)
      } else {
        message.error('Something went wrong. Please try again.')
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const validate = () => {
    switch (platform) {
      case 'instagram':
        return !(sourceType && (url?.length > 0 || hashtag?.replace(' ', '').trim().length > 0) && numberOfLeads > 0)
      case 'facebook':
        return !(sourceType && url?.length > 0 && numberOfLeads > 0 && account)
      case 'linkedin':
        return !(searchName?.trim()?.length > 0 && sourceType && url?.length > 0 && numberOfLeads > 0 && account)
      default:
        return true
    }
  }

  const sourceTypeOption =
    platform === 'linkedin'
      ? [
          { key: 'sales_nav', value: 'Sales Navigator Search URL', premium: importSalesNav === true ? false : true },
          { key: 'linkedin_search', value: 'LinkedIn Search URL', premium: importLinkedin === true ? false : true },
        ]
      : platform === 'instagram'
      ? [
          { key: 'followers', value: 'Page Followers', premium: platform === 'facebook' ? true : false },
          { key: 'hashtag', value: 'Hashtag', premium: false },
          { key: 'post_interactions', value: 'Post Likes & Comments', premium: true },
          { key: 'search_results', value: 'Search', premium: true },
        ]
      : [
          { key: 'members', value: 'Group Members', premium: platform === 'instagram' ? true : false },
          { key: 'followers', value: 'Page Followers', premium: platform === 'facebook' ? true : false },
          { key: 'hashtag', value: 'Hashtag', premium: true },
          { key: 'post_interactions', value: 'Post Likes & Comments', premium: true },
          { key: 'search_results', value: 'Search', premium: true },
        ]

  const linkedinSearchContent = (
    <div className={styles.popoverContentLinkedin}>
      <img src={linkedin_search} alt='' />
      <p>Insert LinkedIn URL</p>
      <span style={{ marginTop: '0px' }}>
        To find the correct LinkedIn URL:
        <br />
        1. Perform a search on LinkedIn based on what you’re looking for (e.g., job titles, companies, industries).
        <br />
        2. Once the search results appear, select the <span style={{ fontWeight: 600 }}>People</span> tab to filter the
        results to people only.
        <br />
        3. Copy the URL from the browser's address bar and paste it here.
      </span>
    </div>
  )

  const linkedinSalesNavContent = (
    <div className={styles.popoverContentLinkedin}>
      <img src={linkedin_sales_nav} alt='' />
      <p>Insert Sales Navigator URL</p>
      <span style={{ marginTop: '0px' }}>
        To find the correct Sales Navigator URL:
        <br />
        1. Perform a search in Sales Navigator and apply filters based on your criteria (e.g., job titles, companies,
        geographies).
        <br />
        2. Once the results are refined, copy the URL from Sales Navigator and paste it here.
      </span>
    </div>
  )

  const defaultLinkedinContent = (
    <div className={styles.popoverContentLinkedin}>
      <p>Insert LinkedIn URL</p>
      <span style={{ marginTop: '0px' }}>
        To find the correct LinkedIn URL, follow these steps:
        <br />
        1. Perform a search on LinkedIn based on what you’re looking for (e.g., job titles, companies, industries).
        <br />
        2. Once the search results appear, select the <span style={{ fontWeight: 600 }}>People</span> tab to filter the
        results to people only.
        <br />
        3. Copy the URL from the browser's address bar and paste it here.
        <br />
        <br />
        If you’re using <span style={{ fontWeight: 600 }}>LinkedIn Sales Navigator</span>:
        <br />
        1. Perform a search and apply filters based on your criteria.
        <br />
        2. Once the results are refined, copy the URL from Sales Navigator and paste it here.
      </span>
    </div>
  )
  const instagramPopoverContent = (
    <div className={styles.popoverContentLinkedin}>
      <img src={instagram_popover} alt='' />
      <p>Enter Instagram username or page URL</p>
      <span style={{ marginTop: '0px' }}>
        To find the right page to collect leads from:
        <br />
        1. Locate a page related to your niche that your prospects might follow, and ensure the followers are visible to
        more than just the page admin (e.g., “only CNN can view all followers”).
        <br />
        2. Copy the URL or username into the field below.
      </span>
    </div>
  )
  const facebookPopoverContent = (
    <div className={styles.popoverContentLinkedin}>
      <img src={facebook_popover} alt='' />
      <p>Enter Facebook group URL</p>
      <span style={{ marginTop: '0px' }}>
        To find the right group to collect leads from:
        <br />
        1. Locate a group related to your niche that your prospects might join. Ensure it’s a public group, or if it’s
        private, verify that the user you're collecting from is a member and approved by the admins.
        <br />
        2. Copy the URL or username into the field below.
      </span>
    </div>
  )
  const getLinkedinPopoverContent = (sourceType: string) => {
    switch (sourceType) {
      case 'sales_nav':
        return linkedinSalesNavContent
      case 'linkedin_search':
        return linkedinSearchContent
      default:
        return defaultLinkedinContent
    }
  }
  const getInstagramPopoverContent = () => {
    return instagramPopoverContent
  }
  const getFacebookPopoverContent = () => {
    return facebookPopoverContent
  }

  const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: 'black',
    height: 64,
    paddingInline: 20,
    lineHeight: '64px',
    backgroundColor: 'white',
    borderBottom: '1px solid #EBECF0',
  }

  const contentStyle: React.CSSProperties = {
    minHeight: 320,
    height: '100%',
    color: 'black',
    backgroundColor: 'white',
  }

  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
  }

  return (
    <Modal
      className='CollectLeadsModal'
      open={open}
      onCancel={onClose}
      centered
      closable={false}
      footer={null}
      maskClosable={false}>
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <div className={styles.modalHeader}>
            <p>Select How Many Leads to Collect</p>
            <span onClick={onClose}>Cancel</span>
          </div>
        </Header>
        <Content style={contentStyle}>
          <div className={styles.modalContent}>
            <div className={styles.progressContainer}>
              <img src={points_icon} alt='' />
              <p>{numberOfLeads?.toLocaleString()}</p>
              <Slider
                min={0}
                max={max_import_group_leads}
                value={numberOfLeads}
                defaultValue={numberOfLeads}
                step={sourceType === 'hashtag' ? 20 : 100}
                onChange={handleSliderChange}
                tooltip={{ formatter }}
                style={{ width: 300 }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, width: '100%' }}>
              <span style={{ color: '#5E6C84' }}>
                Collecting {numberOfLeads?.toLocaleString()} leads from group will cost you {totalCost} points
              </span>
              <Divider style={{ padding: 5, margin: 5 }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 15,
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '400px',
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Channel</label>
                    <Select
                      onChange={handleSelectChange}
                      value={platform}
                      defaultValue={platform}
                      placeholder='Select Channel'
                      // disabled
                      style={{ width: '185px', marginTop: '5px' }}>
                      <Option value='instagram'>
                        <div className={styles.option}>
                          <img src={instagram} alt='Instagram' className={styles.icon} />
                          <span>Instagram</span>
                        </div>
                      </Option>
                      <Option value='facebook'>
                        <div className={styles.option}>
                          <img src={facebook} alt='Facebook' className={styles.icon} />
                          <span>Facebook</span>
                        </div>
                      </Option>
                      <Option value='linkedin'>
                        <div className={styles.option}>
                          <img src={linkedin} alt='LinkedIn' className={styles.icon} />
                          <span>LinkedIn</span>
                        </div>
                      </Option>
                    </Select>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label>Source Type</label>
                    <Select
                      onChange={handleSelectSourceTypeChange}
                      value={sourceType}
                      labelInValue
                      placeholder='Select Source'
                      style={{ width: '185px', marginTop: '5px' }}>
                      {sourceTypeOption.map((type: any) => (
                        <Option key={type.key} value={type.key} disabled={type.premium}>
                          <div className={styles.option}>
                            <span style={type.premium ? { color: 'gray' } : {}}>{type.value}</span>
                            {type.premium && (
                              <img style={{ marginLeft: '5px', width: '15px', height: '15px' }} src={crown} alt='' />
                            )}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '400px',
                  }}>
                  <div className={styles.container}>
                    <>
                      {sourceType === 'hashtag' ? (
                        <>
                          <label style={{ display: 'flex', alignItems: 'center' }}>Enter Hashtag</label>
                        </>
                      ) : (
                        <label style={{ display: 'flex', alignItems: 'center' }}>
                          Insert URL
                          {platform === 'facebook' && (
                            <>
                              <Popover trigger='hover' content={getFacebookPopoverContent()}>
                                <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
                              </Popover>
                            </>
                          )}
                          {platform === 'linkedin' && (
                            <Popover trigger='hover' content={getLinkedinPopoverContent(sourceType)}>
                              <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
                            </Popover>
                          )}
                          {platform === 'instagram' && (
                            <Popover trigger='hover' content={getInstagramPopoverContent()}>
                              <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
                            </Popover>
                          )}
                        </label>
                      )}
                    </>
                    {sourceType === 'hashtag' ? (
                      <>
                        <Input
                          value={hashtag}
                          defaultValue={hashtag}
                          addonBefore='#'
                          onChange={(e) => setHashtag(e.target.value?.replace('#', ''))}
                          style={{ marginTop: '5px', width: '400px' }}
                          placeholder={'Insert Hashtag'}
                          required
                        />
                      </>
                    ) : (
                      <>
                        <Input
                          value={url}
                          defaultValue={url}
                          onChange={handleInputChange}
                          style={{ marginTop: '5px', width: '400px' }}
                          placeholder={platform === 'instagram' ? 'Username or URL' : 'Insert URL'}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div style={{ width: '400px', display: 'flex', justifyContent: 'space-between' }}>
                  {(platform === 'linkedin' || platform === 'facebook' || platform === 'instagram') && (
                    <div className={styles.container}>
                      <label style={{ display: 'flex', alignItems: 'center' }}>
                        Account
                        {platform === 'facebook' && (
                          <Tooltip
                            color='white'
                            style={{ color: 'black' }}
                            title={
                              <div style={{ color: 'black' }}>
                                If it’s a private group, please select an account that can view it, meaning the account
                                is either approved by the admins and already a member.
                              </div>
                            }>
                            <InfoCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
                          </Tooltip>
                        )}
                      </label>

                      <Select
                        onChange={handleSelectAccountChange}
                        value={account}
                        defaultValue={account}
                        labelInValue
                        placeholder='Select Account'
                        style={{ width: '185px', marginTop: '5px' }}>
                        {(platform === 'linkedin'
                          ? linkedinOptions
                          : platform === 'instagram'
                          ? instagramOptions
                          : facebookOptions
                        ).map((acc: any) => (
                          <Option key={acc} value={acc}>
                            <div className={styles.option}>
                              <span>{acc}</span>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  {platform === 'linkedin' && (
                    <div className={styles.container}>
                      <label style={{ display: 'flex', alignItems: 'center' }}>Search Name</label>
                      <Input
                        value={searchName}
                        defaultValue={searchName}
                        onChange={handleInputNameChange}
                        style={{ marginTop: '5px' }}
                        placeholder='Please include your custom search name'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <Button
                type='primary'
                loading={loading}
                disabled={!numberOfLeads || validate()}
                onClick={handleImportGroup}>
                Start Collecting
              </Button>
              {/* <span className={styles.disclaimer} onClick={handleDisclaimer}>
                  {disclaimerText}
                </span> */}
            </div>
          </div>
        </Content>
      </Layout>
    </Modal>
  )
}
