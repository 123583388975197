const workerFunction = function () {
  const priorityOrder: any = { high: 1, medium: 2, low: 3, '': 4, null: 4 }

  const sortDialogs = (dialogs: any[], sortKey: string) => {
    const dialogsCopy = [...dialogs]

    dialogsCopy.sort((a: any, b: any) => {
      if (sortKey === 'priority') {
        const valueA = priorityOrder[a[sortKey]] || priorityOrder['']
        const valueB = priorityOrder[b[sortKey]] || priorityOrder['']
        return valueA - valueB
      } else if (sortKey === 'categories') {
        const categoryA = a.categories?.[0]?.category_name || ''
        const categoryB = b.categories?.[0]?.category_name || ''
        return categoryA.localeCompare(categoryB)
      } else {
        const valueA = a[sortKey] === null ? '\uFFFF' : a[sortKey] || ''
        const valueB = b[sortKey] === null ? '\uFFFF' : b[sortKey] || ''
        return valueA.localeCompare(valueB)
      }
    })

    return dialogsCopy
  }

  onmessage = (event: MessageEvent) => {
    const { dialogs, sortKey } = event.data

    if (!dialogs || !sortKey) {
      postMessage({ error: 'Invalid input data' })
      return
    }

    try {
      const sortedDialogs = sortDialogs(dialogs, sortKey)
      console.log('Worker sorted dialogs')
      postMessage({ data: sortedDialogs })
    } catch (error) {
      postMessage({ error: 'Error sorting dialogs' })
    }
  }
}

let codeToString = workerFunction.toString()
let mainCode = codeToString.substring(codeToString.indexOf('{') + 1, codeToString.lastIndexOf('}'))
let blob = new Blob([mainCode], { type: 'application/javascript' })
let sort_worker_script = URL.createObjectURL(blob)

export default sort_worker_script
