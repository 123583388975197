import React, { useState, useEffect } from 'react'
import { Button, Modal, Radio, Typography, message } from 'antd'
import PricingCard from './PricingCard'
import styles from './Upgrade.module.scss'
import talk_to_us from 'common/assets/svg/talk_to_us.svg'
import hooray_conffeti from 'common/assets/lottie-animations/hooray_confetti.json'
import { useLazyGetPricingPlansQuery, useLazyGetSessionStatusQuery } from './API/UpgradeApi'
import ROICalculator from './ROICalculator'
import ExtraFeaturesModal from './ExtraFeaturesModal'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { useLocalStorage } from 'usehooks-ts'
import Lottie from 'lottie-react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'state'
import { useIntercom } from 'react-use-intercom'
import SmallLogout from 'GeneralComponents/SmallLogout'
import VideoDemoModal from 'features/settings/Pages/Upgrade/VideoDemoModal'
import { trackEvent } from 'eventConfig'
import WelcomeScreen from 'features/settings/Pages/Upgrade/WelcomeScreen'
import { useLazyUpdateUserProgressQuery } from 'common/api/UserApi'

const Upgrade = () => {
  const navigate = useNavigate()
  const [plans, setPlans] = useState([])
  const [formulas, setFormulas] = useState([])
  const [subscription, setSubscription] = useState()
  const [isCustomer, setIsCustomer] = useState(false)
  const [trialData, setTrialData] = useState([])
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('yearly')
  const [getPricingPlans, { data: plansData, isFetching }] = useLazyGetPricingPlansQuery()
  const [getSessionStatus] = useLazyGetSessionStatusQuery()
  const [updateUser] = useLazyUpdateUserProgressQuery()
  const [getUserDetails] = useLazyGetUserDetailsQuery()
  const [user, setUser] = useLocalStorage<any>('user', null)
  const [selectedCard, setSelectedCard] = useState('')
  const [selectedPrice, setSelectedPrice] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<any>({})
  const [opennotificationModal, setOpenNotificationModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [loadingGetSession, setLoadingGetSession] = useState(user?.progress?.payment_status?.status ? false : true)
  const [paymentStatus, setPaymentStatus] = useState('')
  const { isMobile } = useAppSelector((state) => state.General)
  const isNew = user?.addons?.show_trial_option
  const [isVideoDemoOpen, setIsVideoDemoOpen] = useState(false)
  const whereType = user?.subscription_type === 'REGISTERED' ? 'Pricing outside' : 'Pricing inside'
  const { boot, showNewMessage } = useIntercom()

  const handleWatchDemo = () => {
    setIsVideoDemoOpen(true)
    trackEvent('WATCH_A_DEMO', {
      where: whereType,
      user_attributes: {
        subscription_type: user?.subscription_type,
        force_pricing: user?.force_pricing,
        plan_type: user?.plan_type,
        plan: user?.plan,
        settings_id: user?.settings_id,
      },
    })
  }

  // const openNotification = () => () => {
  //   api.open({
  //     message: 'Notification Title',
  //     description:
  //       'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
  //     duration: 15,
  //   })
  // }

  const handleSelectCard = (plan: any, price: any) => {
    setSelectedCard(plan)
    setSelectedPrice(price)
  }

  const onChange = (e: any) => {
    setBillingCycle(e.target.value)
  }

  // const handleCancelSubscription = async () => {
  //   const { data } = await generateStripeLink({ cancelSubscription: true })
  //   if (data?.cancel_at_period_end) {
  //     return message.error('Your subscription is already set to be canceled at period end.')
  //   } else if (data?.no_customer) {
  //     return message.error('You do not have an active subscription yet.')
  //   } else if (data?.url) {
  //     window.open(data.url, '_self')
  //   } else {
  //     message.error('Something went wrong. Please contact support.')
  //   }
  // }

  const handleOptionChange = (planId: string, selectedOption: string) => {
    setSelectedOptions((prev: any) => ({
      ...prev,
      [planId]: selectedOption,
    }))
    const plan: any = plans?.find((plan: any) => plan.id === planId)
    const options = plan?.pricingOptions?.[billingCycle]?.[selectedOption]
    const price = options?.price
    if (price) {
      setSelectedPrice(price)
    }
  }

  // Helper function to update user progress
  const updateProgress = async (updates: any) => {
    try {
      const { status, welcome_screen_selection, scheduledEvent } = updates

      const updatedProgress = {
        ...user?.progress,
        payment_status: {
          ...user?.progress?.payment_status,
          ...(status && { status: status }),
          ...(welcome_screen_selection && { welcome_screen_selection: welcome_screen_selection }),
          ...(scheduledEvent && { scheduledEvent: scheduledEvent }),
        },
      }

      // Save progress to the backend
      const response = await updateUser({ feature: 'payment_status', updates: updatedProgress.payment_status })

      if (!response?.data?.success) {
        return console.error('Failed to update progress.')
      }

      // Update local user object
      setUser((prevUser: any) => ({
        ...prevUser,
        progress: updatedProgress,
      }))
    } catch (error) {
      console.error('Error updating progress:', error)
      message.error('Failed to update progress. Please try again.')
    }
  }

  const handleStartExploring = async () => {
    await updateProgress({ welcome_screen_selection: 'I Know Myself' })
    trackEvent('I_KNOW_MYSELF', {
      where: 'Upgrade',
      user_attributes: {
        subscription_type: user?.subscription_type,
        force_pricing: user?.force_pricing,
        plan_type: user?.plan_type,
        plan: user?.plan,
        settings_id: user?.settings_id,
      },
    })

    const addons = user?.addons
    if (addons.requires_onboarding && addons.onboarding_status === false) {
      navigate('/onboarding')
    } else {
      navigate('/outreach')
    }
  }

  const bootIntercomWithMessage = () => {
    boot({
      name: user?.name,
      email: user?.email,
    })
    showNewMessage('I need help with pricing!')
    setOpenNotificationModal(false)
  }

  useEffect(() => {
    const getPlans = async () => {
      await getPricingPlans()
    }

    getPlans()
  }, [])

  useEffect(() => {
    if (plansData?.default_billing_cycle) {
      setBillingCycle(plansData?.default_billing_cycle)
    }

    if (plansData?.plans?.length > 0) {
      const initialSelectedOptions: any = {}
      plansData?.plans?.forEach((plan: any) => {
        // initialSelectedOptions[plan.id] = plan.pricingOptions[billingCycle][0]
        // initialSelectedOptions[plan.id] = '1'
        initialSelectedOptions[plan.id] = String(plan?.tierOrder?.tier_1) || '1'
      })

      setSelectedOptions(initialSelectedOptions)
      setPlans(plansData?.plans)
      const initialPlan = plansData?.plans?.find((plan: any) => plan.isMostPopular)
      if (initialPlan) {
        setSelectedCard(initialPlan)
        const tier = initialPlan?.tierOrder?.tier_1
        const initialPrice = initialPlan?.pricingOptions[plansData?.default_billing_cycle]?.[tier]?.price
        setSelectedPrice(initialPrice)
      } else {
        const plan = plansData?.plans?.[0]
        setSelectedCard(plan)
        const tier = plan?.tierOrder?.tier_1
        const initialPrice = plan?.pricingOptions[plansData?.default_billing_cycle]?.[tier]?.price
        setSelectedPrice(initialPrice)
      }
    }
    if (plansData?.formulas?.length > 0) {
      setFormulas(plansData?.formulas)
    }
    if (plansData?.subscription) {
      setSubscription(plansData?.subscription)
    }

    if (plansData?.isCustomer) {
      setIsCustomer(plansData?.isCustomer)
    } else {
      setIsCustomer(false)
    }

    if (plansData?.trialData) {
      setTrialData(plansData?.trialData)
    }
  }, [plansData])

  const getUser = async () => {
    const { data } = await getUserDetails()
    if (data.id) {
      setUser(data)
    }
  }

  const pushToDataLayer = (data: any) => {
    ;(window as any).dataLayer = (window as any).dataLayer || []
    if ((window as any).dataLayer) {
      ;(window as any).dataLayer.push(data)
      console.log('Pushed to Data Layer:', data)
    } else {
      console.warn('Data Layer is not initialized.')
    }
  }

  useEffect(() => {
    if (window.location.pathname === '/upgrade/canceled') {
      message.warning(`You have not been charged. Click "${isNew ? 'Start Trial' : 'Update Plan'}" to start again.`)
      // Clear the URL by replacing the current state
      window.history.replaceState({}, document.title, '/upgrade')
    }

    if (window.location.pathname === '/upgrade/success') {
      getUser()
      setIsSuccess(true)
      // window.history.replaceState({}, document.title, '/upgrade')
    }

    if (window.location.pathname === '/upgrade/subscription-canceled') {
      getUser()
      message.warning('Your subscription has been canceled. If this was a mistake, please contact support.')
      window.history.replaceState({}, document.title, '/upgrade')
    }
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const session_id = urlParams.get('session_id')

    if (session_id) {
      if (session_id === 'completed') {
        setLoadingGetSession(false)
        setPaymentStatus('paid')
        updateProgress({ status: 'paid' })
        getUser()
        message.success('Your payment was successful! Thank you for updating.')
      } else {
        getSessionStatus({ session_id })
          .then(({ data }: any) => {
            if (data?.payment_status === 'unpaid') {
              setLoadingGetSession(false)
              setPaymentStatus('unpaid')
              message.error('Payment failed or incomplete. Please contact support.')
            } else if (data?.payment_status === 'paid') {
              setLoadingGetSession(false)
              setPaymentStatus('paid')
              updateProgress({ status: 'paid' })
              getUser()
              message.success('Your payment was successful! Thank you for updating.')

              // add p-conversion=true to the url
              const url = new URL(window.location.href)
              url.searchParams.set('p-conversion', 'true')
              pushToDataLayer({
                event: 'PaidConversion',
                pagePath: window.location.pathname,
                pageSearch: window.location.search,
              })
              window.history.replaceState({}, '', url)
            }
          })
          .catch(() => {
            message.error('Failed to update session status.')
          })
      }
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isVideoDemoOpen) {
        setOpenNotificationModal(true)
      }
    }, 30000)

    return () => clearTimeout(timer)
  }, [isVideoDemoOpen])

  useEffect(() => {
    let interval: any = null

    // Only set the interval if paymentStatus is 'paid' and user.plan is 'TRIAL'
    if (
      paymentStatus === 'paid' &&
      (user?.plan === 'trial' || user?.subscription_type === 'TRIAL' || user?.subscription_type === 'REGISTERED')
    ) {
      interval = setInterval(() => {
        getUser()
      }, 1000)
    }

    // Cleanup function to clear the interval
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [paymentStatus, user.plan, getUser])

  const notificationModal = (
    <Modal
      open={opennotificationModal}
      onCancel={() => setOpenNotificationModal(false)}
      footer={false}
      centered
      // closable={false}
      width={450}>
      <div className={styles.notificationDiv} style={{ height: '410px' }}>
        <p>Not sure which plan suits your needs?</p>
        {/* <div className={styles.flexDiv}> */}
        <img src={talk_to_us} alt='' />
        <span style={{ color: '#97a0af', fontSize: '16px' }}>
          Speak with one of our sales experts <br />
          to help you choose the best plan for you.
        </span>
        {/* </div> */}
        <Button onClick={bootIntercomWithMessage} type='primary'>
          Get Expert Advice
        </Button>
      </div>
    </Modal>
  )

  // useEffect(() => {
  //   if (plans?.length > 0) {
  //     const initialSelectedOptions: any = {}
  //     plans.forEach((plan: any) => {
  //       // initialSelectedOptions[plan.id] = plan.pricingOptions[billingCycle][0]
  //       initialSelectedOptions[plan.id] = plan.pricingOptions[billingCycle]['1'] // This should be changed
  //     })
  //     setSelectedOptions(initialSelectedOptions)
  //   }
  // }, [billingCycle, plans])

  if (isSuccess) {
    if (loadingGetSession) {
      return (
        <div className={styles.container} style={{ height: '100vh', justifyContent: 'center' }}>
          <p className={styles.loadingMessage}>Please wait, verifying your payment status...</p>
        </div>
      )
    }

    if (paymentStatus === 'paid' || user?.progress?.payment_status?.status === 'paid') {
      return (
        <>
          <div className={styles.lottieContainer}>
            <Lottie loop={false} animationData={hooray_conffeti} />
          </div>
          <WelcomeScreen updateProgress={updateProgress} onSkipOnboarding={handleStartExploring} />
        </>
        // <div className={styles.container} style={{ height: '100vh', justifyContent: 'center' }}>
        //   <div className={styles.lottieContainer}>
        //     <Lottie loop={true} animationData={hooray_conffeti} />
        //   </div>
        //   <p className={styles.thankYouTitle}>Thank You!</p>
        //   <p className={styles.thankYouMessage}>
        //     Your subscription has been successfully updated. We're excited to have you on board!
        //   </p>
        //   <Button onClick={handleStartExploring} type='primary'>
        //     Start Exploring Now!
        //   </Button>
        // </div>
      )
    }

    return (
      <div className={styles.container} style={{ height: '100vh', justifyContent: 'center' }}>
        <p className={styles.errorMessage}>
          It seems like your payment was not successful or incomplete. Please try again or{' '}
          <span style={{ color: '#7043ff', cursor: 'pointer' }} onClick={bootIntercomWithMessage}>
            contact support
          </span>{' '}
          for assistance.
        </p>
      </div>
    )
  }

  return (
    <>
      {isMobile ? (
        <>
          {notificationModal}
          <div className={styles.container}>
            <div className={styles.selectorMobile}>
              <Radio.Group
                key={billingCycle}
                onChange={onChange}
                className='radioUpgrade'
                value={billingCycle}
                buttonStyle='solid'>
                <Radio.Button value='yearly'>Yearly (33% Off)</Radio.Button>
                <Radio.Button value='monthly'>Monthly</Radio.Button>
              </Radio.Group>
            </div>

            <Typography.Title level={3} style={{ textAlign: 'center', marginTop: '0px', marginBottom: '0px' }}>
              Start <span style={{ color: 'var(--color-blue-03' }}> automating </span> your sales on socials and boost
              <span style={{ color: 'var(--color-blue-03' }}> revenue </span>
            </Typography.Title>

            {user?.subscription_type === 'REGISTERED' && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type='primary'
                  size='large'
                  onClick={handleWatchDemo}
                  style={{
                    height: '56px',
                    fontSize: '24px',
                    padding: '0 32px',
                    borderRadius: '8px',
                    fontWeight: 500,
                  }}>
                  Watch a Demo
                </Button>
              </div>
            )}

            {plans.map((plan: any) => (
              <PricingCard
                plan={plan}
                plans={plans}
                billingCycle={billingCycle}
                selectedCard={selectedCard}
                handleSelectCard={handleSelectCard}
                setSelectedPrice={setSelectedPrice}
                selectedOption={selectedOptions[plan.id]}
                onOptionChange={handleOptionChange}
                subscription={subscription}
                isCustomer={isCustomer}
                trialData={trialData}
              />
            ))}
            <div className={styles.roi}>
              <div className={styles.compare}>
                <span onClick={() => setOpenModal(true)}>Compare All Plans</span>
              </div>
            </div>
            {isMobile && (
              <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                <SmallLogout />
              </div>
            )}

            {openModal && (
              <ExtraFeaturesModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                plans={plans}
                selectedOptions={selectedOptions}
                onOptionChange={handleOptionChange}
                billingCycle={billingCycle}
                subscription={subscription}
              />
            )}
          </div>
        </>
      ) : (
        <div className={styles.container}>
          {notificationModal}
          {/* <button onClick={openNotification()}>click</button> */}
          {!isFetching && (
            <div className={styles.selector}>
              <Radio.Group onChange={onChange} className='radioUpgrade' value={billingCycle} buttonStyle='solid'>
                <Radio.Button value='yearly'>Yearly (33% Off)</Radio.Button>
                <Radio.Button value='monthly'>Monthly</Radio.Button>
              </Radio.Group>
            </div>
          )}
          <Typography.Title
            level={2}
            style={{ textAlign: 'center', marginTop: '0px', marginBottom: 0, height: '35px' }}>
            Start{' '}
            <span style={{ color: 'var(--color-blue-03' }} className={styles.roboto}>
              {' '}
              automating{' '}
            </span>
            your sales on socials and boost <span style={{ color: 'var(--color-blue-03' }}> revenue </span>
          </Typography.Title>

          {user?.subscription_type === 'REGISTERED' && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type='primary'
                size='large'
                onClick={handleWatchDemo}
                style={{
                  height: '56px',
                  fontSize: '24px',
                  padding: '0 32px',
                  borderRadius: '8px',
                  fontWeight: 500,
                }}>
                Watch a Demo
              </Button>
            </div>
          )}
          <div className={styles.cardsContainer}>
            {plans.map((plan: any) => (
              <PricingCard
                plan={plan}
                plans={plans}
                billingCycle={billingCycle}
                selectedCard={selectedCard}
                handleSelectCard={handleSelectCard}
                setSelectedPrice={setSelectedPrice}
                selectedOption={selectedOptions[plan.id]}
                onOptionChange={handleOptionChange}
                subscription={subscription}
                isCustomer={isCustomer}
                trialData={trialData}
              />
            ))}
          </div>
          <div className={styles.roi}>
            <div className={styles.compare}>
              <span onClick={() => setOpenModal(true)}>Compare All Plans</span>
            </div>
            <ROICalculator
              selectedPlan={selectedCard}
              selectedPrice={selectedPrice}
              formulas={formulas}
              selectedOptions={selectedOptions}
              billingCycle={billingCycle}
            />
          </div>
          {/* <div className={styles.cancel}>
        <p onClick={handleCancelSubscription}>Cancel Subscription</p>
      </div> */}
          {openModal && (
            <ExtraFeaturesModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              plans={plans}
              selectedOptions={selectedOptions}
              onOptionChange={handleOptionChange}
              billingCycle={billingCycle}
              subscription={subscription}
            />
          )}
        </div>
      )}
      {isVideoDemoOpen && (
        <VideoDemoModal open={isVideoDemoOpen} onClose={() => setIsVideoDemoOpen(false)} isMobile={isMobile} />
      )}
    </>
  )
}

export default Upgrade
