import React, { useState } from 'react'
import { AutoSizer, Grid } from 'react-virtualized'
import GroupCard from './GroupCard'
import styles from './MainExplore.module.scss'
import arrowRight from 'common/assets/svg/right_purple_arrow.svg'
import { useLocalStorage } from 'usehooks-ts'
import NotSeeing from './Components/NotSeeing'
interface MainExploreProps {
  groupsData: any
  setSelectedGroup: any
  setSelectedGroupName: any
  myGroupsTab: any
  searchValue: any
  pointsStore: any
  onLinkedinCollect: any
  importData: any
  icpData: any
  handleFacebookShouldJoin: any
  setImportData: any
  goToProgress: any
  setFilterObject: any
  onCollectLeads: () => void
  isSmallScreen: boolean
}

const filterTopThreeGroups = (groups: any[], isFacebook: boolean, isInstagram: boolean, isLinkedin: boolean): any[] => {
  // Filter groups based on user platforms
  const userPlatforms: string[] = []
  if (isFacebook) userPlatforms.push('facebook')
  if (isInstagram) userPlatforms.push('instagram')
  if (isLinkedin) userPlatforms.push('linkedin')

  const filteredGroups = groups.filter((group) => userPlatforms.length <= 3 || userPlatforms.includes(group.platform))

  // Group by platform and sort by score
  const groupedByPlatform = userPlatforms.map((platform) => {
    const groupsForPlatform = filteredGroups.filter((group) => group.platform === platform)
    return groupsForPlatform.sort((a, b) => b.score - a.score)
  })

  // Combine to ensure at least one group per platform
  const selectedGroups: any[] = []
  groupedByPlatform.forEach((groups) => {
    if (groups.length) {
      selectedGroups.push(groups[0])
    }
  })

  // Fill remaining slots with highest score groups
  const remainingGroups = filteredGroups
    .filter((group) => !selectedGroups.includes(group))
    .sort((a, b) => b.score - a.score)

  while (selectedGroups.length < 3 && remainingGroups.length) {
    selectedGroups.push(remainingGroups.shift())
  }

  return selectedGroups.slice(0, 3)
}

export default function MainExplore({
  groupsData,
  setSelectedGroup,
  setSelectedGroupName,
  myGroupsTab,

  searchValue,
  pointsStore,
  onLinkedinCollect,
  importData,
  icpData,
  handleFacebookShouldJoin,
  setImportData,
  goToProgress,
  setFilterObject,
  onCollectLeads,
  isSmallScreen,
}: MainExploreProps) {
  const [user] = useLocalStorage<any>('user', {})
  const isFacebook = user?.facebook?.length > 0
  const isInstagram = user?.instagram?.length > 0
  const isLinkedin = user?.linkedin?.length > 0

  const [suggestedByConvrtShowMore] = useState(false)
  const [fromMyAccountsShowMore] = useState(false)
  const suggestedByConvrt = groupsData?.filter((group: any) => group?.suggested === true)
  // .filter((group: any) => matchesSearch(group, searchValue))
  const fromMyAccounts = groupsData?.filter((group: any) => group?.mine === true)
  // .filter((group: any) => matchesSearch(group, searchValue))

  const columnCount = 3
  const suggestedHeight = (suggestedByConvrtShowMore ? Math.ceil(suggestedByConvrt.length / columnCount) : 1) * 270

  const accountsHeight = (fromMyAccountsShowMore ? Math.ceil(fromMyAccounts.length / columnCount) : 1) * 270
  const threeSuggested = filterTopThreeGroups(suggestedByConvrt, isFacebook, isInstagram, isLinkedin)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '80%',
        overflowY: 'auto', // Single scrolling for the entire container
      }}>
      {/* Suggested By Convrt Section */}
      {suggestedByConvrt.length > 0 && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            marginBottom: '20px', // Add margin to separate sections
          }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 50,
              padding: 15,
            }}>
            <span className={styles.mainExploreTitle}>Suggested By Convrt</span>
            <span
              className={styles.mainExploreSeeMore}
              onClick={() =>
                setFilterObject((prev: any) => ({
                  ...prev,
                  a: 'Suggested',
                }))
              }>
              <span>See More</span>
              <img src={arrowRight} alt='Arrow Right' />
            </span>
          </div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <Grid
                columnCount={columnCount}
                columnWidth={width / columnCount}
                rowCount={suggestedByConvrtShowMore ? Math.ceil(threeSuggested.length / columnCount) : 1}
                rowHeight={270}
                height={suggestedHeight} // Dynamic height based on content
                width={width}
                cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                  const index = rowIndex * columnCount + columnIndex
                  if (index >= threeSuggested.length || (!suggestedByConvrtShowMore && index >= 3)) return null

                  const group = threeSuggested[index]
                  return (
                    <div key={key} style={style}>
                      <div style={{ padding: '2px 10px 0px 10px' }}>
                        <GroupCard
                          key={group?.name || group?.username || group?.title}
                          group={group}
                          onSelect={setSelectedGroup}
                          setSelectedGroupName={setSelectedGroupName}
                          myGroupsTab={myGroupsTab}
                          refinement={searchValue}
                          pointsStore={pointsStore}
                          onLinkedinCollect={onLinkedinCollect}
                          importData={importData}
                          icpData={icpData}
                          handleFacebookShouldJoin={handleFacebookShouldJoin}
                          setImportData={setImportData}
                          goToProgress={goToProgress}
                        />
                      </div>
                    </div>
                  )
                }}
              />
            )}
          </AutoSizer>
        </div>
      )}
      {/* From My Accounts Section */}
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '15px',
          }}>
          <span className={styles.mainExploreTitle}>From My Accounts</span>
          <span
            className={styles.mainExploreSeeMore}
            onClick={() =>
              setFilterObject((prev: any) => ({
                ...prev,
                a: 'From My Accounts',
              }))
            }>
            {'See More'}
            <img src={arrowRight} alt='Arrow Right' />
          </span>
        </div>
        <AutoSizer disableHeight>
          {({ width }) => (
            <Grid
              columnCount={columnCount}
              columnWidth={width / columnCount}
              rowCount={fromMyAccountsShowMore ? Math.ceil(fromMyAccounts.length / columnCount) : 1}
              rowHeight={270}
              height={accountsHeight} // Dynamic height based on content
              width={width}
              cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                const index = rowIndex * columnCount + columnIndex
                if (index >= fromMyAccounts.length || (!fromMyAccountsShowMore && index >= 3)) {
                  return null
                }

                const group = fromMyAccounts[index]
                return (
                  <div key={key} style={style}>
                    <div style={{ padding: '2px 10px 0px 10px' }}>
                      <GroupCard
                        key={group?.name || group?.username || group?.title}
                        group={group}
                        onSelect={setSelectedGroup}
                        setSelectedGroupName={setSelectedGroupName}
                        myGroupsTab={myGroupsTab}
                        refinement={searchValue}
                        pointsStore={pointsStore}
                        onLinkedinCollect={onLinkedinCollect}
                        importData={importData}
                        icpData={icpData}
                        handleFacebookShouldJoin={handleFacebookShouldJoin}
                        setImportData={setImportData}
                        goToProgress={goToProgress}
                      />
                    </div>
                  </div>
                )
              }}
            />
          )}
        </AutoSizer>
      </div>

      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <NotSeeing
            key='notseeing'
            onClick={onCollectLeads}
            style={{
              top: -10,
              position: 'relative',
              width: '100%',
              marginBottom: isSmallScreen ? 110 : 0,
              marginTop: 50,
            }}
            isSmallScreen={isSmallScreen}
          />
        </div>
      </div>
    </div>
  )
}
