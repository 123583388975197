import { Button, Divider, Layout, message, Modal, Select, Slider, SliderSingleProps } from 'antd'
import {
  useLazyCollectLeadsQuery,
  useLazyGetExploreQuery,
  useLazyGetMyHubQuery,
  useLazyImportGroupQuery,
} from 'common/components/OutreachAI/state/api/OutreachAI-API'
import { SET_INITIAL_GROUP_DATA } from 'common/components/OutreachAI/state/outreachAICampaignSlice'
import { trackEvent } from 'eventConfig'
import { useState } from 'react'
import { useAppDispatch } from 'state'
import { useLocalStorage } from 'usehooks-ts'
import styles from './CollectLeadsOptionsModal.module.scss'
import points_icon from 'common/assets/svg/points_icon.svg'
import { getFacebookAccounts, getInstagramAccounts, getLinkedinAccounts } from 'common/utils/userHelpers'
const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => `${value?.toLocaleString()}`

const { Header, Content } = Layout
interface CollectLeadsModalProps {
  open: boolean
  onClose: () => void
  group: any
  pointsStore: any
  importData: any
  goToProgress: any
}
const pickAccount = (group: any, accounts: any) => {
  if (!accounts || !group) return ''
  const isFound = accounts?.find((acc: any) => acc === group?.outreach_username)
  if (isFound) return isFound
  return accounts[0]
}
export const CollectLeadsOptionsModal: React.FC<CollectLeadsModalProps> = ({
  open,
  onClose,
  group,
  pointsStore,
  importData,
  goToProgress,
}) => {
  const dispatch = useAppDispatch()
  const [getExplore] = useLazyGetExploreQuery()
  const [getMyHub] = useLazyGetMyHubQuery()
  const [collectLeads] = useLazyCollectLeadsQuery()
  const [loading, setLoading] = useState(false)
  const [numberOfLeads, setNumberOfLeads] = useState(0)
  const [user] = useLocalStorage<any>('user', null)
  const [instagramAccounts] = useState(getInstagramAccounts(user))
  const [linkedinAccounts] = useState(getLinkedinAccounts(user))
  const [facebookAccounts] = useState(getFacebookAccounts(user))
  const platforms: any = {
    instagram: instagramAccounts,
    facebook: facebookAccounts,
    linkedin: linkedinAccounts,
    telegram: [],
  }
  const [importGroup] = useLazyImportGroupQuery()

  const [account, SetAccount] = useState(importData?.account || pickAccount(group, platforms[group.platform]))
  // const [disclaimerText, setDisclaimerText] = useState('Disclaimer')
  const { addons } = user
  const platform = group?.platform
  const instagramMaxImport =
    group.source_type === 'hashtag'
      ? addons?.max_import_hashtag_instagram_leads
      : addons?.max_import_group_instagram_leads || 1000
  const max_import_group_leads =
    platform === 'instagram'
      ? instagramMaxImport
      : platform === 'facebook'
      ? addons?.max_import_group_facebook_leads || 1000
      : platform === 'linkedin'
      ? addons?.max_import_group_linkedin_leads || 200
      : addons?.max_import_group_leads || 1000

  const parseLeads = (leads: any) => {
    if (typeof leads === 'string') {
      if (leads.endsWith('k') || leads.endsWith('K')) {
        return parseFloat(leads) * 1000
      } else if (leads.endsWith('m') || leads.endsWith('M')) {
        return parseFloat(leads) * 1000000
      } else {
        return parseFloat(leads)
      }
    }
    return leads
  }
  const remainingLeads = parseLeads(group?.leads) - +group.number_of_requested_leads || 5000
  const max_leads =
    group.source_type === 'hashtag' ? max_import_group_leads : Math.min(remainingLeads, max_import_group_leads)

  const cost = pointsStore?.pointsStore['collect'] || 1
  const totalCost = Math.trunc(numberOfLeads * cost)?.toLocaleString()

  const handleSliderChange = (e: any) => {
    setNumberOfLeads(e)
  }

  const handleLinkedinCollect = async () => {
    const { data, error } = await importGroup({
      platform: platform,
      sourceType: 'linkedin_search',
      url: importData?.url,
      numberOfLeads: numberOfLeads,
      ...(platform === 'linkedin' && { searchName: importData?.searchName }),
      ...((platform === 'linkedin' || platform === 'facebook') && { account: account }),
    })

    trackEvent('IMPORT_CUSTOM_LEADS', {
      platform: platform,
      sourceType: 'linkedin_search',
      url: importData?.url,
      numberOfLeads: numberOfLeads,
      ...(platform === 'linkedin' && { searchName: importData?.searchName }),
      ...(platform === 'linkedin' && { account: account }),
      status: data?.status,
    })
    if (data?.status && !error) {
      message.success(`Search imported successfully!`)
      const { data } = await getExplore({})
      const { data: myHubData } = await getMyHub({})
      const merged = {
        ...data,
        ...myHubData,
      }
      dispatch(SET_INITIAL_GROUP_DATA(merged))
    } else if (error?.data?.status === false) {
      message.error(error?.data?.message)
    } else if (error?.data?.status === 'points') {
      message.error(error?.data?.message)
    } else {
      message.error('Something went wrong. Please try again.')
    }
  }
  const handleCollect = async () => {
    if (group.platform === 'linkedin') {
      await handleLinkedinCollect()
      goToProgress()
      onClose()
      return
    }
    setLoading(true)
    const { data: collectionData, error } = await collectLeads({
      amount: numberOfLeads,
      group: group || '',
      collector: account || '',
    })
    trackEvent('COLLECT_LEADS', { platform: group.platform, group: group.username, amount: numberOfLeads })

    if (collectionData?.message) {
      const { data } = await getExplore({})
      const { data: myHubData } = await getMyHub({})
      dispatch(
        SET_INITIAL_GROUP_DATA({
          ...data,
          ...myHubData,
        }),
      )
      message.success(collectionData?.message)

      onClose()
      goToProgress()
      onClose()
    } else if (error?.data?.status === 'points') {
      const convrt_points = error?.data?.convrt_points
      message.error(`You have ${convrt_points} points left. This action costs ${totalCost} points.`)
    } else if (error?.data?.status === 'not_found') {
      message.error(`This group is not found - you were not charged.`)
      const { data } = await getExplore({})
      const { data: myHubData } = await getMyHub({})
      dispatch(
        SET_INITIAL_GROUP_DATA({
          ...data,
          ...myHubData,
        }),
      )
    } else {
      message.error('Something went wrong collecting leads. Please try again or contact support.')
    }
    setLoading(false)
  }

  const generateSelectOptions: any = (platform: string) => {
    const platformAccounts: any = {
      facebook: facebookAccounts.length,
      instagram: instagramAccounts.length,
      telegram: 0,
      linkedin: linkedinAccounts?.length,
      discord: 0,
      twitter: 0,
    }
    let options = []
    const platforms: any = {
      instagram: instagramAccounts,
      facebook: facebookAccounts,
      linkedin: linkedinAccounts,
      telegram: [],
    }
    for (let i = 0; i < platformAccounts[platform]; i++) {
      options.push(platforms[platform][i])
    }
    return options
  }
  const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: 'black',
    height: 64,
    paddingInline: 20,
    lineHeight: '64px',
    backgroundColor: 'white',
    borderBottom: '1px solid #EBECF0',
  }

  const contentStyle: React.CSSProperties = {
    minHeight: 320,
    height: '100%',
    color: 'black',
    backgroundColor: 'white',
  }

  const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden',
  }

  return (
    <Modal
      className='CollectLeadsModal'
      open={open}
      onCancel={onClose}
      centered
      closable={false}
      footer={null}
      maskClosable={false}>
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <div className={styles.modalHeader}>
            <p>Select How Many Leads to Collect</p>
            <span onClick={onClose}>Cancel</span>
          </div>
        </Header>
        <Content style={contentStyle}>
          <div className={styles.modalContent}>
            <div className={styles.progressContainer}>
              <img src={points_icon} alt='' />
              <p>{numberOfLeads?.toLocaleString()}</p>
              <Slider
                min={0}
                max={max_leads}
                style={{ width: 300 }}
                defaultValue={numberOfLeads}
                step={group.source_type === 'hashtag' ? 20 : 100}
                onChange={handleSliderChange}
                tooltip={{ formatter }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, width: '100%' }}>
              <span style={{ color: '#5E6C84' }}>
                Collecting {numberOfLeads?.toLocaleString()} leads from group will cost you {totalCost} points
              </span>
              <Divider style={{ padding: 5, margin: 5 }} />

              <div style={{ width: '60%' }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 5,
                    alignItems: 'center',
                    color: 'grey',
                  }}>
                  <span>Account</span>
                  <Select
                    onChange={(value) => SetAccount(value.value)}
                    value={account}
                    defaultValue={account}
                    labelInValue
                    placeholder='Select Account'
                    style={{ width: '100%' }}>
                    {generateSelectOptions(platform).map((acc: any) => (
                      <Select.Option key={acc} value={acc}>
                        <div className={styles.option}>
                          <span>{acc}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <Button type='primary' loading={loading} disabled={!numberOfLeads || !account} onClick={handleCollect}>
                Start Collecting
              </Button>
              {/* <span className={styles.disclaimer} onClick={handleDisclaimer}>
                  {disclaimerText}
                </span> */}
            </div>
          </div>
        </Content>
      </Layout>
    </Modal>
  )
}
